import RouteEnum from '../../constants/RouteEnum'
import cookie from 'react-cookies'

export default class HappyCheckEffect {

    static async requestHappyCheckOpen() {
        return await fetch(RouteEnum.API + '/api/happy-check/open', {
            method: 'GET',
            headers: {
                'Authorization': 'Bearer ' + cookie.load('token'),
                'Accept': 'application/json',
            },
        }).then((response) => {
            return response.json()
        }).then((response) => {
            if (response.status === 401) {
                cookie.remove('token', {path: '/'})
            }

            return {
                happyChecks: response.parameters.happyChecks,
                subjects: response.parameters.subjects
            }

        })
    }

    static async requestHappyCheckRegister(values) {
        return await fetch(RouteEnum.API + '/api/happy-check/register', {
            method: 'POST',
            body: JSON.stringify(values),
            headers: {
                'Authorization': 'Bearer ' + cookie.load('token'),
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
        }).then((response) => {
            return response.json()
        }).then((response) => {
            if (response.status === 401) {
                cookie.remove('token', {path: '/'})
            }
        })
    }

    static async requestHappyCheckInfo() {
        return await fetch(RouteEnum.API + '/api/happy-check/more-info', {
            method: 'GET',
            headers: {
                'Authorization': 'Bearer ' + cookie.load('token'),
                'Accept': 'application/json',
            },
        }).then((response) => {
            return response.json()
        }).then((response) => {
            if (response.status === 401) {
                cookie.remove('token', {path: '/'})
            }

            return {
                info: response.parameters.notification,
            }

        })
    }

}
