import ActionUtility from '../../utilities/ActionUtility'
import HappyCheckEffect from './HappyCheckEffect'

export default class HappyCheckAction {
    static REQUEST_OPEN = 'HappyCheckAction.REQUEST_OPEN'
    static REQUEST_REGISTER = 'HappyCheckAction.REQUEST_REGISTER'
    static REQUEST_INFO = 'HappyCheckAction.REQUEST_INFO'

    static requestHappyCheckOpen() {

        return async (dispatch) => {
            await ActionUtility.createThunkEffect(dispatch, HappyCheckAction.REQUEST_OPEN, HappyCheckEffect.requestHappyCheckOpen);
        };

    }

    static requestHappyCheckRegister(values) {

        return async (dispatch) => {
            await ActionUtility.createThunkEffect(dispatch, HappyCheckAction.REQUEST_REGISTER, HappyCheckEffect.requestHappyCheckRegister, values);
        };

    }

    static requestHappyCheckInfo() {

        return async (dispatch) => {
            await ActionUtility.createThunkEffect(dispatch, HappyCheckAction.REQUEST_INFO, HappyCheckEffect.requestHappyCheckInfo);
        };

    }

}
