import React, {useEffect} from 'react'
import '../HappyCheck.scss'

class Subject extends React.Component {

    constructor(props) {
        super(props)

        this.state = {
            active: false,
            id: this.props.id,
            message: false
        }

        this.handleChange = this.handleChange.bind(this)
    }

    handleClick() {
        this.setState({
            ...this.state,
            active: !this.state.active
        })
        this.props.handleRemarks(this.state)
    }

    handleChange(event) {
        this.setState({
            ...this.state,
            [event.target.name]: event.target.value
        });
        this.props.handleRemarks(this.state)
    }

    componentDidUpdate() {
        this.props.handleRemarks(this.state)
    }

    render() {

        let subjectClass = 'c-happy-check-content__rating__note__single'
        if(this.state.active) subjectClass += ' noteActive'

        return(

            <div className={subjectClass} >
                <button className={'checkbox'} onClick={() => this.handleClick()}>
                    <figure>
                        <img src={'/images/check.svg'}/>
                    </figure>
                    <span>{this.props.title}</span>
                </button>
                <div className={'c-time-form-row'}>
                    <div className={'c-time-form-group'}>
                        <label>Opmerking over {this.props.title}</label>
                        <textarea name="message" cols="20" rows="5" onChange={this.handleChange}/>
                    </div>
                </div>
            </div>

        );
    }
}

export default Subject;
