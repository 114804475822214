import React from 'react'
import LogoTagline from "../../static/svg/LogoTagline";
import {Link} from "react-router-dom";
import {connect} from "react-redux";
import {Formik, Field, FieldArray} from 'formik'
import AuthenticationAction from "../../stores/authentication/AuthenticationAction";
import Loader from 'react-loader-spinner'

const mapStateToProps = (state, ownProps) => ({
    ...state.authentication
});

class ForgotPassword extends React.Component {

    render() {

        const props = this.props


        return (
            <div className={'c-auth'}>
                <LogoTagline/>

                {!props.forgotError &&
                <div className="c-auth-text">
                    <p>
                        Gelieve uw gebruikersnaam in te vullen. U krijgt
                        van ons een e-mail met instructies om uw
                        wachtwoord te veranderen.
                    </p>
                </div>
                }

                <Formik
                    initialValues={
                        {
                            username: '',
                        }
                    }
                    onSubmit={(values, actions) => {
                        this.props.dispatch(AuthenticationAction.resetPassword(values.username));
                    }}
                >
                    {({values, setFieldValue, handleSubmit, touched}) => {


                        const usernameFilled = (values.username !== '');

                        return (

                            <form className={'c-auth-form'} onSubmit={handleSubmit}>

                                {!props.forgotSubmit &&
                                <>
                                    <div className={'c-auth-form-row filled-' + usernameFilled}>
                                        <div className={'c-auth-form-group'}>
                                            <Field type="text" name={'username'} required={true} id={'userName'}/>
                                            <label htmlFor={'userName'}>Gebruikersnaam</label>
                                        </div>
                                    </div>

                                    {this.props.authApiPending &&
                                    <div className={'load-spinner'}>
                                        <Loader
                                            type="Bars"
                                            color="#50b0aa"
                                            height={30}
                                            width={30}
                                            timeout={10000}

                                        />
                                    </div>
                                    }
                                    {!this.props.authApiPending &&
                                    <div className={'c-auth-form-row c-auth-form-row--btn'}>
                                        <div className={'c-auth-form-group'}>
                                            <button className={'c-auth-btn'} type={'submit'}>
                                                <span>Wachtwoord herstellen</span>
                                            </button>
                                        </div>
                                    </div>
                                    }
                                </>

                                }

                                {props.forgotError &&
                                <div className="c-auth-text">
                                    <p>{props.forgotError}</p>
                                </div>
                                }

                            </form>

                        )
                    }}
                </Formik>

                <Link className={'btn btn--pink'} to="/">
                    <span>Inloggen</span>
                </Link>
            </div>
        )
    }
}

export default connect(mapStateToProps)(ForgotPassword);
