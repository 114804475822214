import ActionUtility from '../../utilities/ActionUtility'
import DayOffEffect from './DayOffEffect'

export default class DayOffAction {
    static REQUEST_TOTALS = 'DayOffAction.REQUEST_TOTALS'

    static REQUEST_CREDIT_DATA = 'DayOffAction.REQUEST_CREDIT_DATA'

    static REQUEST_DAY_OFF_OVERVIEW = 'DayOffAction.REQUEST_DAY_OFF'

    static REQUEST_DAY_OFF = 'DayOffAction.REQUEST_DAY_OFF'

    static REGISTER_DAY_OFF = 'DayOffAction.REGISTER_DAY_OFF'

    static REMOVE_DAY_OFF = 'DayOffAction.REMOVE_DAY_OFF'


    static requestDayOffOverview() {

        return async (dispatch) => {
            await ActionUtility.createThunkEffect(dispatch, DayOffAction.REQUEST_DAY_OFF_OVERVIEW, DayOffEffect.requestDayOffOverview);
        };

    }

    static requestCreditData() {

        return async (dispatch) => {
            await ActionUtility.createThunkEffect(dispatch, DayOffAction.REQUEST_CREDIT_DATA, DayOffEffect.requestCreditData);
        };

    }


    static requestDayOff(id) {

        return async (dispatch) => {
            await ActionUtility.createThunkEffect(dispatch, DayOffAction.REQUEST_DAY_OFF, DayOffEffect.requestDayOff, id);
        };

    }

    static registerDayOff(values) {

        return async (dispatch) => {
            await ActionUtility.createThunkEffect(dispatch, DayOffAction.REGISTER_DAY_OFF, DayOffEffect.registerDayOff, values);
        };

    }

    static removeDayOff(id) {

        return async (dispatch, getState) => {
            await ActionUtility.createThunkEffect(dispatch, DayOffAction.REMOVE_DAY_OFF, DayOffEffect.removeDayOff, id);
        };

    }

    static requestTotals() {

        return async (dispatch, getState) => {
            await ActionUtility.createThunkEffect(dispatch, DayOffAction.REQUEST_TOTALS, DayOffEffect.requestTotals);
        };

    }

}
