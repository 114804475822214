import React from 'react'
import './ClientRulesDetail.scss'
import ClientRulesAction from "../../../stores/clientRules/ClientRulesAction";
import {connect} from "react-redux";
import Loader from "react-loader-spinner";
import RouteEnum from "../../../constants/RouteEnum";
import {Link} from "react-router-dom";

const mapStateToProps = (state, ownProps) => ({
    ...state.clientRules
});

class ClientRulesDetailOld extends React.Component {

    constructor(props) {
        super(props)
    }

    componentDidMount() {
        this.props.dispatch(ClientRulesAction.requestClientRulesDetail(this.props.match.params.id))
    }


    render() {
        if (this.props.clientRules.detail) {
            const detail = this.props.clientRules.detail[0]
            const detailOld = this.props.clientRules.detail[0].agreement_history.filter((detail) => detail.id === parseInt(this.props.match.params.idOld))[0]
            const detailsOlder = this.props.clientRules.detail[0].agreement_history.filter((detail) => detail.id !== parseInt(this.props.match.params.idOld))

            return (
                <div className={'c-time c-timetracking-overview c-time--dark'}>
                    {!!detailOld &&
                    <>
                        <header className={'c-time-header c-client-rules__header'}>
                            {!!detail.title &&
                            <h1>{detail.title}</h1>
                            }
                            {!!detailOld.version &&
                            <small>Versie {detailOld.version}</small>
                            }
                            <div className={'c-label c-label--red'}>Oude versie</div>
                        </header>
                        <div className={'o-container o-container--primary'}>
                            <div className={'c-time-form c-client-rules-detail'}>
                                <div className={'c-client-rules-detail__single c-client-rules-detail__list'}>
                                    <header>
                                        <h2>Informatie</h2>
                                    </header>
                                    <div className={'c-client-rules-detail__single__content'}>
                                        {!!detailOld.caseload &&
                                        <div className={'c-client-rules-detail__list__single'}>
                                            <span>Caseload</span>
                                            <span>{detailOld.caseload}</span>
                                        </div>
                                        }
                                        {!!detailOld.case_weight &&
                                        <div className={'c-client-rules-detail__list__single'}>
                                            <span>Zorgzwaarte</span>
                                            <span>{detailOld.case_weight} maanden</span>
                                        </div>
                                        }
                                    </div>
                                </div>
                                {!!detailOld.package &&
                                <div className={'c-client-rules-detail__single c-client-rules-detail__text'}>
                                    <header>
                                        <h2>Pakket</h2>
                                    </header>
                                    <div className={'c-client-rules-detail__single__content'}>
                                        <p>{detailOld.package}</p>
                                    </div>
                                </div>
                                }
                                {!!detailOld.registration &&
                                <div className={'c-client-rules-detail__single c-client-rules-detail__text'}>
                                    <header>
                                        <h2>Registratie</h2>
                                    </header>
                                    <div className={'c-client-rules-detail__single__content'}>
                                        <p>{detailOld.registration}</p>
                                    </div>
                                </div>
                                }
                                {!!detailsOlder.length > 0 &&
                                <div
                                    className={'c-client-rules-detail__single c-client-rules-detail__list c-client-rules-detail__list--single'}>
                                    <header>
                                        <h2>Oudere versies</h2>
                                    </header>
                                    <div className={'c-client-rules-detail__single__content'}>
                                        {detailsOlder.map((detailOlder) => (
                                            <div className={'c-client-rules-detail__list__single'}>
                                                <Link to={RouteEnum.ClientRulesDetail + '/' + detail.agreement.id + '/' + RouteEnum.ClientRulesDetailOld + '/' + detailOlder.id}>Versie {detailOlder.version}</Link>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                                }
                                {!!detail.projects &&
                                <div className={'c-client-rules-detail__single c-client-rules-detail__buttons'}>
                                    <header>
                                        <h2>Gekoppelde projecten</h2>
                                    </header>
                                    <div className={'c-client-rules-detail__single__content'}>
                                        {detail.projects.map((projects) => (
                                            <div className={'c-label'}>{projects.title}</div>
                                        ))}
                                    </div>
                                </div>
                                }
                            </div>
                        </div>
                    </>
                    }
                </div>
            );
        } else {
            return (
                <div className={'c-timetracking-loader'}>
                    <Loader
                        type="Watch"
                        color="#51B0AA"
                        height={100}
                        width={100}
                        timeout={10000} //3 secs
                    />
                </div>
            )
        }
    }
}

export default connect(mapStateToProps)(ClientRulesDetailOld);
