import React from 'react'
import DayOffDay from '../../_components/DayOffDay/DayOffDay'
import './DaysOffOverview.scss'
import {Link} from "react-router-dom";
import RouteEnum from "../../../constants/RouteEnum";
import DayOffAction from "../../../stores/dayOff/DayOffAction";
import {connect} from "react-redux";
import moment from 'moment'
import Loader from 'react-loader-spinner'
import cookie from "react-cookies";
import AuthenticationAction from "../../../stores/authentication/AuthenticationAction";


const mapStateToProps = (state, ownProps) => ({
    ...state.dayOff,
    ...state.client
});

class DaysOffOverview extends React.Component {

    constructor(props) {
        super(props)
    }

    componentDidMount() {
        if (!cookie.load('token')){
            this.props.dispatch(AuthenticationAction.logout());
        }

        if (!this.props.weeklyTimeTracking) {
            this.props.dispatch(DayOffAction.requestDayOffOverview());
            this.props.dispatch(DayOffAction.requestCreditData());
        }

    }

    render() {


        const now = new moment()
        const currentDate = now.format('DD-MM-YYYY')
        const year = now.format('YYYY')
        const lastYear = now.clone().subtract(1, 'year').format('YYYY')

        return (
            <div className="c-time c-timetracking-overview">
                <header className="c-time-header">
                    <h1>
                        <time>Verlofdagen overzicht</time>
                    </h1>
                </header>

                {this.props.dayOffsLoading &&

                <div className={'c-timetracking-loader'}>
                    <Loader
                        type="Watch"
                        color="#51B0AA"
                        height={100}
                        width={100}
                        timeout={10000} //3 secs
                    />
                </div>

                }

                {!this.props.dayOffsLoading &&
                <>

                    <form>

                        <div className="o-container o-container--primary">
                            <div className="c-time-form">

                                {this.props.creditDataCurrentYear &&
                                <>

                                    {!!this.props.creditDataCurrentYear.min_hours > 0 &&
                                    <div className={"error-message"}>
                                        <p>U heeft een minstand opgebouwd. Bij volgende verlofaanvragen worden uren van
                                            het
                                            volgende boekjaar afgeschreven.</p>
                                    </div>
                                    }

                                    <div className="c-credits-overview">
                                        {this.props.creditDataCurrentYear.year &&
                                        <div className="c-credits-overview__intro">
                                            <p>Verlof saldo {this.props.creditDataCurrentYear.year}</p>
                                        </div>
                                        }
                                        <div className="c-credits-overview__content">
                                            {currentDate &&
                                            <p className={'c-credits-overview__content__text'}>Saldo per {currentDate}</p>
                                            }
                                            {this.props.creditDataCurrentYear.actual_legal_hours &&
                                            <div>
                                                <div className="c-credits-overview__content__single">
                                                    <small>Wettelijke uren</small>
                                                    <span>{this.props.creditDataCurrentYear.actual_legal_hours}</span>
                                                </div>
                                            </div>
                                            }
                                        </div>
                                    </div>

                                    <hr className="divider"/>

                                    <div className="c-credits-overview">
                                        {this.props.creditDataCurrentYear.year &&
                                        <div className="c-credits-overview__intro">
                                            <p>Verlof stand {this.props.creditDataCurrentYear.year}</p>
                                        </div>
                                        }
                                        <div className="c-credits-overview__content">
                                            <div>
                                                {this.props.creditDataCurrentYear.leave_legal &&
                                                <div className="c-credits-overview__content__single">
                                                    <small>Wettelijke uren</small>
                                                    <span>{parseFloat(this.props.creditDataCurrentYear.leave_legal_credit)}</span>
                                                </div>
                                                }
                                                {this.props.creditDataCurrentYear.leave_legal_extra &&
                                                <div className="c-credits-overview__content__single">
                                                    <small>Bovenwettelijke uren</small>
                                                    <span>{parseFloat(this.props.creditDataCurrentYear.leave_legal_extra_credit)}</span>
                                                </div>
                                                }
                                                {this.props.creditDataCurrentYear.leave_special &&
                                                <div className="c-credits-overview__content__single">
                                                    <small>Extra uren</small>
                                                    <span>{parseFloat(this.props.creditDataCurrentYear.leave_special)}</span>
                                                </div>
                                                }
                                                <div className="c-credits-overview__content__single">
                                                    <small>Min uren</small>
                                                    {this.props.creditDataCurrentYear.min_hours > 0 ? (
                                                        <span>-{parseFloat(this.props.creditDataCurrentYear.min_hours)}</span>
                                                    ) : (
                                                        <span>{parseFloat(this.props.creditDataCurrentYear.min_hours)}</span>
                                                    )}
                                                </div>

                                            </div>
                                        </div>
                                    </div>

                                </>
                                }

                                <hr className="divider"/>

                                {this.props.creditData &&

                                <div className="c-credits">
                                    <div className="panel-body">
                                        <p>De getallen vermeld tussen haakjes tonen de nog beschikbare uren van
                                            dat type voor dat jaar.</p>
                                        <table className="table table-striped">
                                            <thead>
                                            <tr>
                                                <th>Jaar</th>
                                                <th>Wettelijk</th>
                                                <th>Bovenwettelijk</th>
                                                <th>Bijzonder</th>
                                                <th>Totaal</th>
                                                <th>Verzuimuren</th>
                                            </tr>
                                            </thead>
                                            <tbody>
                                            {this.props.creditData.map((item) => {

                                                const legalAvailable = parseFloat(item.leave_legal_credit) > 0
                                                const legalExtraAvailable = parseFloat(item.leave_legal_extra_credit) > 0
                                                const specialAvailable = parseFloat(item.leave_special_credit) > 0
                                                const totalAvailable = parseFloat(item.total_credit) > 0

                                                return (
                                                    <tr>
                                                        <td>{item.year}</td>
                                                        <td>{parseFloat(item.leave_legal)} <span
                                                            className={'available-' + legalAvailable}>({parseFloat(item.leave_legal_credit)})</span>
                                                        </td>
                                                        <td>{parseFloat(item.leave_legal_extra)} <span
                                                            className={'available-' + legalExtraAvailable}>({parseFloat(item.leave_legal_extra_credit)})</span>
                                                        </td>
                                                        <td>{parseFloat(item.leave_special)} <span
                                                            className={'available-' + specialAvailable}>({parseFloat(item.leave_special_credit)})</span>
                                                        </td>
                                                        <td>{parseFloat(item.total)} <span
                                                            className={'available-' + totalAvailable}>({parseFloat(item.total_credit)})</span>
                                                        </td>
                                                        <td>{parseFloat(item.total_absence)}</td>

                                                    </tr>
                                                )
                                            })}
                                            </tbody>

                                        </table>

                                    </div>

                                </div>

                                }

                                <hr className="divider"/>

                                {Object.keys(this.props.daysOff).length === 0 &&
                                <p className={'c-dayOff-notice'}>Er zijn geen verlofdagen aangevraagd</p>
                                }

                                {this.props.daysOff && Object.keys(this.props.daysOff).map((year) => {
                                        const items = this.props.daysOff[year]
                                        return (
                                            <>
                                                <div className="c-time-form-row c-time-form-row--white">
                                                    <div className="c-days">
                                                        <p><strong>Verlof aanvragen {year}</strong></p>
                                                        {items &&
                                                        items.map((item) => {
                                                            return (
                                                                <DayOffDay data={item}/>
                                                            )
                                                        })
                                                        }
                                                    </div>
                                                </div>

                                            </>
                                        )
                                    }
                                )}


                            </div>
                        </div>
                    </form>
                    <Link to={RouteEnum.DaysOffAdd} className="c-floating-btn">
                        <div className="o-container o-container--secondary">
                            <button>Nieuw verlof aanvragen</button>
                        </div>
                    </Link>
                </>
                }
            </div>
        )

    }

}

export default connect(mapStateToProps)(DaysOffOverview);
