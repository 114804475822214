import React from 'react'
import {Link} from "react-router-dom";
import RouteEnum from "../../../constants/RouteEnum";
import TimeTrackingAction from "../../../stores/timetracking/TimetrackingAction";
import {connect} from "react-redux";
import TimeTrackingDate from '../../_components/TimeTrackingDate'
import ClientById from "../../../utilities/ClientById";
import {withRouter} from "react-router";
import moment from 'moment'
import WorkTypeByValue from '../../../utilities/WorkTypeByValue'
import CalculateHourDifference from "../../../utilities/CalculateHourDifference";
import Loader from 'react-loader-spinner'
import MapCallTimes from '../../../utilities/MapCallTimes'


const mapStateToProps = (state, ownProps) => ({
    ...state.timeTracking,
    ...state.client
});

class TimeTrackingView extends React.Component {

    componentDidMount() {
        const id = this.props.match.params.id;
        this.props.dispatch(TimeTrackingAction.requestTimeTracking(id));
    }

    render() {
        const {currentTimeTracking, clients} = this.props
        if (currentTimeTracking && clients && !this.props.timeTrackFetching) {

            let callTimesMapped = {}

            if (currentTimeTracking.calls) {
                callTimesMapped = MapCallTimes(this.props.currentTimeTracking.calls)
            }

            const date = <TimeTrackingDate useParams={false} data={currentTimeTracking}/>;
            const client = ClientById(clients, currentTimeTracking.customer_id)
            const id = currentTimeTracking.id

            return (
                <main className={'c-time c-time-view'}>
                    <header className={'c-time-header'}>
                        <h1>{date}</h1>
                    </header>
                    {currentTimeTracking.status === 'accepted' &&
                    <div className="c-time-info c-time-info--green">
                        <i className={'icon-checkmark'}></i>
                        <span>Tijdsregistratie goedgekeurd</span>
                    </div>
                    }

                    {currentTimeTracking.status === 'declined' &&
                    <div className="c-time-info c-time-info--red">
                        <i className={'icon-cross'}></i>
                        <span>Tijdsregistratie afgekeurd</span>
                    </div>
                    }


                    <div className={'o-container o-container--secondary'}>
                        <div className={'c-time-view-row'}>
                            <div className={'c-time-view-group'}>
                                <span><strong>Klant</strong></span>
                                {client &&
                                <span>{client.name}</span>
                                }
                            </div>
                        </div>
                        <div className={'c-time-view-row'}>
                            <div className={'c-time-view-group'}>
                                <span><strong>Type werkzaamheden</strong></span>
                                <span>{WorkTypeByValue(currentTimeTracking.type)}</span>
                            </div>
                        </div>
                        <div className={'c-time-view-row c-time-view-row--multiple'}>

                            {currentTimeTracking.type !== 'availability' &&
                            <>
                                <div className={'c-time-view-group'}>
                                    <span><strong>Start werkdag</strong></span>
                                    <span>{moment.duration(currentTimeTracking.start_time).format('HH:mm')}</span>
                                </div>
                                <div className={'c-time-view-group'}>
                                    <span><strong>Einde werkdag</strong></span>
                                    <span>{moment.duration(currentTimeTracking.end_time).format('HH:mm')}</span>
                                </div>
                                <div className={'c-time-view-group'}>
                                    <span><strong>Pauze</strong></span>
                                    <span>{moment.duration(currentTimeTracking.pause_time).format('HH:mm')}</span>
                                </div>
                                {currentTimeTracking.total_kilometers &&
                                <>
                                    <div className={'c-time-view-group'}>
                                        <span><strong>Km's gereden</strong></span>
                                        <span>{currentTimeTracking.total_kilometers}</span>
                                    </div>
                                </>
                                }
                            </>
                            }

                            {currentTimeTracking.type === 'availability' &&
                            <>
                                <div className={'c-time-view-group'}>
                                    <span><strong>Start bereikbaarheid</strong></span>
                                    <span>{moment.duration(currentTimeTracking.start_time).format('HH:mm')}</span>
                                </div>
                                <div className={'c-time-view-group'}>
                                    <span><strong>Einde bereikbaarheid</strong></span>
                                    <span>{moment.duration(currentTimeTracking.end_time).format('HH:mm')}</span>
                                </div>

                                {currentTimeTracking.visits && currentTimeTracking.visits.length &&
                                <div className={'c-time-view-group'}>
                                    <span><strong>Aantal keer bezoek</strong></span>
                                    <span>{currentTimeTracking.visits.length}x</span>
                                </div>
                                }

                            </>
                            }
                        </div>


                        {currentTimeTracking.calls &&
                        <div className={'c-time-view-row c-time-view-row-calls c-time-view-row--multiple'}>
                            <div className={'c-time-view-group'}>
                                <span><strong>Aantal keer gebeld<br/><span
                                    className={'timestamp'}>tussen 00:00 - 06:00</span></strong></span>
                                <span>{callTimesMapped.calls_number_1}x</span>
                            </div>
                            <div className={'c-time-view-group'}>
                                <span><strong>Aantal keer gebeld<br/><span
                                    className={'timestamp'}>tussen 06:00 - 18:00</span></strong></span>
                                <span>{callTimesMapped.calls_number_2}x</span>
                            </div>
                            <div className={'c-time-view-group'}>
                                <span><strong>Aantal keer gebeld<br/><span
                                    className={'timestamp'}>tussen 18:00 - 22:00</span></strong></span>
                                <span>{callTimesMapped.calls_number_3}x</span>
                            </div>
                            <div className={'c-time-view-group'}>
                                <span><strong>Aantal keer gebeld<br/><span
                                    className={'timestamp'}>tussen 22:00 - 00:00</span></strong></span>
                                <span>{callTimesMapped.calls_number_4}x</span>
                            </div>
                        </div>
                        }

                        {currentTimeTracking.type === 'availability' &&
                        <>
                            {currentTimeTracking.visits && currentTimeTracking.visits.length &&
                            <div className={'c-time-view-visits'}>
                                {currentTimeTracking.visits.map((visit, i) => (
                                    <div className={'c-time-view-visits-single'}>
                                        <header>
                                            <h3>Bezoek {i + 1}</h3>
                                        </header>
                                        <div className={'c-time-view-visits-info-wrap'}>
                                            <div className={'c-time-view-visits-single-info'}>
                                                <span>Starttijd</span>
                                                <span>{moment.duration(visit.start_time).format('HH:mm')}</span>
                                            </div>
                                            <div className={'c-time-view-visits-single-info'}>
                                                <span>Eindtijd</span>
                                                <span>{moment.duration(visit.end_time).format('HH:mm')}</span>
                                            </div>
                                            <div className={'c-time-view-visits-single-info'}>
                                                <span>Km's gereden</span>
                                                <span>{visit.business_kilometers + visit.commuting_kilometers}</span>
                                            </div>
                                        </div>
                                    </div>
                                ))}
                            </div>
                            }
                        </>
                        }
                    </div>
                    <footer className={'c-time-totals'}>
                        <div className={'o-container o-container--secondary'}>
                            <div className="c-time-totals-row">
                                <span>Totalen</span>
                                <span>{date}</span>
                            </div>
                            <hr/>
                            {currentTimeTracking.type === 'availability' && currentTimeTracking.visits && currentTimeTracking.visits.length &&
                            <>
                                {currentTimeTracking.visits.map((visit, i) => (
                                    <div className="c-time-totals-row">
                                        <span>Bezoek {i + 1}</span>
                                        <div>
                                            <span>{CalculateHourDifference(visit.start_time, visit.end_time)}</span>
                                            <span>{visit.business_kilometers + visit.commuting_kilometers}km</span>
                                        </div>
                                    </div>
                                ))}
                            </>
                            }
                            {currentTimeTracking.type === 'regular' &&
                            <div className="c-time-totals-row">
                                <span>Km's gereden</span>
                                <div>
                                    <span>{currentTimeTracking.total_kilometers}</span>
                                </div>
                            </div>
                            }

                            {currentTimeTracking.calls && currentTimeTracking.type === 'availability' &&
                            <div className="c-time-totals-row">
                                <span>Aantal keer gebeld</span>
                                <div>
                                    <span>{currentTimeTracking.calls.length}</span>
                                </div>
                            </div>
                            }

                            <div className="c-time-totals-row">
                                <span>Totaal gewerkt</span>
                                <div>
                                    <span>{CalculateHourDifference(currentTimeTracking.start_time, currentTimeTracking.end_time, currentTimeTracking.pause_time)}</span>
                                </div>
                            </div>
                            {currentTimeTracking.status === 'pending' &&
                            <Link to={RouteEnum.TimeTrackingRemove + '/' + id} className={'btn btn--outline btn--icon'}>
                                <i className={'icon-trash'}/>
                                <span>Tijdsregistratie verwijderen</span>
                            </Link>
                            }
                        </div>
                    </footer>
                    <Link to={RouteEnum.Home} className={'btn btn--green btn--icon'}>
                        <i className={'icon-arrow-long'}/>
                        <span>Terug naar dashboard</span>
                    </Link>
                </main>
            )
        } else {
            return (
                <div className={'c-timetracking-loader'}>
                    <Loader
                        type="Watch"
                        color="#51B0AA"
                        height={100}
                        width={100}
                        timeout={10000} //3 secs

                    />
                </div>
            )
        }
    }
}

export default withRouter(connect(mapStateToProps)(TimeTrackingView));