// Checking if the next month is in the future
const ShowNextMonth = (date) => {
    let newDate = new Date();
    newDate.setMonth(date.getMonth() + 1);
    newDate.setFullYear(date.getFullYear());
    const currentDate = new Date();

    return newDate <= currentDate

}

export default ShowNextMonth