import React from 'react'
import './DayOffDay.scss';
import moment from 'moment'
import RouteEnum from "../../../constants/RouteEnum";
import {Link} from "react-router-dom";
import MapDayOffTypes from '../../../utilities/MapDayOffTypes'
import ApprovedSvg from '../../../static/svg/Approved'
import DeclinedSvg from '../../../static/svg/Declined'
import Open from "../../../static/svg/Open";
import TimeTrackingDaySingle from "../TimeTrackingDay/Filled/DayFilled";


class DayOffDay extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            showOptions: false,
            collapsed: false
        }
    }

    _calculateHours(items) {

        let totalHours = 0

        items.map((item) => {
            let difference = moment.utc(moment(item.date_end).diff(moment(item.date_start)))

            // format .3 to .5
            difference = difference.format('hh.mm').replace('.3', '.5')

            totalHours += parseFloat(difference)
        })

        return totalHours
    }

    render() {

        const data = this.props.data
        const items = data.items
        const firstItem = items[0]
        const lastItem = items[items.length - 1]
        const hours = this._calculateHours(items)
        const status = data.leave.status
        const id = data.leave.id
        const type = data.leave.type

        return (
            <div className={"c-days__single c-days__single--white collapsed-" + this.state.collapsed}>


                <div className="c-days__single__dates">
                    <span>{moment(firstItem.date_start).format('DD / MM / YYYY')}</span>
                    <hr/>
                    <span>{moment(lastItem.date_end).format('DD / MM / YYYY')}</span>
                </div>
                <div className="c-days__single__info">
                    <div className="c-days__single__info__type">

                        <h6>

                            {status === 'approved' && <ApprovedSvg/>}

                            {status === 'declined' && <DeclinedSvg/>}

                            {MapDayOffTypes(type)} verlof

                        </h6>

                        <div>
                            <span>-{hours}</span>
                            <span>Uren</span>
                        </div>
                    </div>

                    <div className="c-days__single__info__status">

                        {status !== 'accepted' && status !== 'approved' && status !== 'declined' && this.state.showOptions &&
                        <div className={'c-timetracking-day-options-list'}>
                            <Link to={RouteEnum.DaysOffRemove + '/' + id} className={'btn'}>
                                <span>Annuleren
                                </span>
                            </Link>
                        </div>
                        }
                        <div className={'day-off-actions'}>

                            <div className={'c-timetracking-day-action'} onClick={e => this._clickCollapse()}>
                                <Open/>
                            </div>
                            {status !== 'accepted' && status !== 'approved' && status !== 'declined' &&
                            <div className="c-timetracking-day-options-trigger"
                                 onClick={e => this._clickTimeTrackingOptions()}>
                                <span/>
                                <span/>
                                <span/>
                            </div>
                            }
                        </div>
                    </div>

                </div>


                {this.state.collapsed &&
                <div className={'c-day-off-collapse'}>
                    {items.map((item) => {
                            const date = moment(item.date_start)
                            const day = date.format('DD')
                            const month = date.format('MMM');
                            const year = date.format('YYYY');
                            return (
                                <div class="day">
                                    <span className="day-month">{day + ' ' + month}</span>
                                    <span className={'year'}>{year}</span>
                                </div>
                            )
                        }
                    )}
                </div>
                }

            </div>
        )
    }

    _clickTimeTrackingOptions = () => {
        this.setState({
            showOptions: !this.state.showOptions
        })
    }

    _clickCollapse = () => {
        this.setState({
            collapsed: !this.state.collapsed
        })
    }

}

export default DayOffDay