import React, {Suspense} from 'react';
import './App.scss';
import Header from '././_components/Header/Header'
import Login from "./Login/Login"
import Dashboard from './Dashboard/Dashboard'
import TimeTrackingOverview from "./Timetracking/TimetrackingOverview/TimetrackingOverview"
import TimeTrackingAdd from "./Timetracking/TimetrackingAdd/TimetrackingAdd"
import TimeTrackingRemove from "./Timetracking/TimeTrackingRemove/TimeTrackingRemove"
import TimeTrackingView from "./Timetracking/TimetrackingView/TimetrackingView"
import DaysOffOverview from "./DaysOff/DaysOffOverview/DaysOffOverview"
import DaysOffAdd from './DaysOff/DaysOffAdd/DaysOffAdd'
import DaysOffRemove from './DaysOff/DaysOffRemove/DaysOffRemove'
import ForgotPassword from "./ForgotPassword/ForgotPassword"
import ClientRulesOverview from "./ClientRules/ClientRulesOverview/ClientRulesOverview";
import ClientRulesDetail from "./ClientRules/ClientRulesDetail/ClientRulesDetail";
import ClientRulesDetailOld from "./ClientRules/ClientRulesDetail/ClientRulesDetailOld";
import HappyCheck from "./HappyCheck/HappyCheck"
import {HashRouter} from 'react-router-dom'
import {Route, Switch} from 'react-router-dom'
import RouteEnum from '../constants/RouteEnum'
import ClientAction from "../stores/client/ClientAction"
import {connect} from "react-redux"

import cookie from 'react-cookies'
import Loader from 'react-loader-spinner'
import AuthenticationAction from "../stores/authentication/AuthenticationAction";


const mapStateToProps = (state) => ({
    ...state.client,
    ...state.timeTracking,
    ...state.authentication,
    ...state.serviceWorker
});

class App extends React.Component {

    constructor(props) {
        super(props)
    }

    componentDidMount() {
        if (cookie.load('token')) {
            this.props.dispatch(ClientAction.requestClients());
        } else {
            this.props.dispatch(AuthenticationAction.logout());
        }
    }

    componentDidUpdate() {

        if ((this.props.token || cookie.load('token')) && !this.props.clients) {
            this.props.dispatch(ClientAction.requestClients());
        }
    }

    render() {

        const props = this.props

        return (
            <HashRouter history={props.history} basename={''}>
                <Suspense>
                    <div className="App">
                        <Switch>
                            {cookie.load('token') &&
                            <>
                                <Header/>

                                <Route
                                    exact
                                    path={RouteEnum.Home}
                                    history={props.history}
                                    component={Dashboard}/>

                                <Route
                                    exact={true}
                                    path={RouteEnum.TimeTrackingOverview}
                                    history={props.history}
                                    component={TimeTrackingOverview}/>

                                <Route
                                    exact={true}
                                    path={RouteEnum.TimeTrackingView + '/:id'}
                                    component={TimeTrackingView} history={props.history}/>

                                <Route
                                    exact={true}
                                    path={RouteEnum.TimeTrackingAdd + '/:date'}
                                    component={() => <TimeTrackingAdd edit={false} history={props.history}/>}
                                />

                                <Route
                                    exact={true}
                                    path={RouteEnum.TimeTrackingRemove + '/:id'}
                                    component={() => <TimeTrackingRemove history={props.history}/>}
                                />

                                <Route
                                    exact={true} path={RouteEnum.TimeTrackingEdit + '/:id'}
                                    component={() => <TimeTrackingAdd edit={true} history={props.history}/>}
                                    history={props.history}
                                />

                                <Route
                                    exact={true}
                                    path={RouteEnum.DaysOffOverview}
                                    history={props.history}
                                    component={DaysOffOverview}/>

                                <Route
                                    exact={true}
                                    path={RouteEnum.DaysOffAdd}
                                    history={props.history}
                                    component={DaysOffAdd}/>

                                <Route
                                    exact={true}
                                    path={RouteEnum.HappyCheck + '/:id'}
                                    history={props.history}
                                    component={HappyCheck}/>

                                <Route
                                    exact={true}
                                    path={RouteEnum.DaysOffRemove + '/:id'}
                                    component={() => <DaysOffRemove history={props.history}/>}
                                />

                                <Route
                                    exact={true}
                                    path={RouteEnum.ClientRulesOverview}
                                    history={props.history}
                                    component={ClientRulesOverview}
                                />
                                <Route
                                    exact={true}
                                    path={RouteEnum.ClientRulesDetail + '/:id'}
                                    history={props.history}
                                    component={ClientRulesDetail}
                                />
                                <Route
                                    exact={true}
                                    path={RouteEnum.ClientRulesDetail + '/:id' + RouteEnum.ClientRulesDetailOld + '/:idOld'}
                                    history={props.history}
                                    component={ClientRulesDetailOld}
                                />

                            </>
                            }

                            {cookie.load('token') && props.clients &&
                            <div className={'c-timetracking-loader'}>
                                <Loader
                                    type="Watch"
                                    color="#51B0AA"
                                    height={100}
                                    width={100}
                                    timeout={10000} //3 secs
                                />
                            </div>
                            }

                            {!cookie.load('token') &&
                            <>
                                <Route
                                    exact={true}
                                    path={RouteEnum.ForgotPassword}
                                    component={ForgotPassword}
                                    history={props.history}/>

                                <Route component={Login}
                                       history={props.history}/>
                            </>
                            }

                        </Switch>
                    </div>
                </Suspense>
            </HashRouter>
        );
    }


}

export default connect(mapStateToProps)(App);
