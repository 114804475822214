import React from 'react';
import './ClientRulesDetail.scss'
import {connect} from "react-redux";
import ClientRulesAction from "../../../stores/clientRules/ClientRulesAction";
import Loader from "react-loader-spinner";
import RouteEnum from "../../../constants/RouteEnum";
import {Link} from "react-router-dom";
import {Document, Page, pdfjs} from "react-pdf";
import {useWindowWidth} from '@wojtekmaj/react-hooks';

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const mapStateToProps = (state, ownProps) => ({
    ...state.clientRules
});


class ClientRulesDetail extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            numPages: null,
            pageNumber: 1,
        }
        this.onDocumentLoad = this.onDocumentLoad.bind(this) // ADD THIS LINE
    }

    onDocumentLoad({numPages}) {
        this.setState({numPages})
    }


    componentDidMount() {
        this.props.dispatch(ClientRulesAction.requestClientRulesDetail(this.props.match.params.id))
        this.props.dispatch(ClientRulesAction.requestClientRulesSetSeen(this.props.match.params.id))
    }

    render() {
        const {pageNumber, numPages} = this.state


        if (this.props.clientRules.detail) {
            const detail = this.props.clientRules.detail[0]
            return (
                <div className='pdf-container'>
                    {!!detail.agreement.upload ? (
                        <Document
                            file={detail.agreement.upload}
                            onLoadSuccess={this.onDocumentLoad}>
                            {Array.from(new Array(numPages), (el, index) => (
                                <Page key={`page_${index + 1}`} width={Math.min(window.outerWidth * 1, 1000)}
                                      pageNumber={index + 1}/>
                            ))}
                        </Document>
                    ) : (
                        <div><h2>Er is geen bestand gevonden.</h2></div>
                    )}
                </div>

                /* <div className={'c-time c-timetracking-overview c-time--dark'}>
                    {!!detail &&
                    <>
                        <header className={'c-time-header c-client-rules__header'}>
                            {!!detail.title &&
                            <h1>{detail.title}</h1>
                            }
                            {!!detail.agreement.version &&
                            <small>Versie {detail.agreement.version}</small>
                            }
                        </header>
                        <div className={'o-container o-container--primary'}>
                            <div className={'c-time-form c-client-rules-detail'}>
                                <div className={'c-client-rules-detail__single c-client-rules-detail__list'}>
                                    <header>
                                        <h2>Informatie</h2>
                                    </header>
                                    <div className={'c-client-rules-detail__single__content'}>
                                        {!!detail.agreement.caseload &&
                                        <div className={'c-client-rules-detail__list__single'}>
                                            <span>Caseload</span>
                                            <span>{detail.agreement.caseload}</span>
                                        </div>
                                        }
                                        {!!detail.agreement.case_weight &&
                                        <div className={'c-client-rules-detail__list__single'}>
                                            <span>Zorgzwaarte</span>
                                            <span>{detail.agreement.case_weight} maanden</span>
                                        </div>
                                        }
                                    </div>
                                </div>
                                {!!detail.agreement.package &&
                                <div className={'c-client-rules-detail__single c-client-rules-detail__text'}>
                                    <header>
                                        <h2>Pakket</h2>
                                    </header>
                                    <div className={'c-client-rules-detail__single__content'}>
                                        <p>{detail.agreement.package}</p>
                                    </div>
                                </div>
                                }
                                {!!detail.agreement.registration &&
                                <div className={'c-client-rules-detail__single c-client-rules-detail__text'}>
                                    <header>
                                        <h2>Registratie</h2>
                                    </header>
                                    <div className={'c-client-rules-detail__single__content'}>
                                        <p>{detail.agreement.registration}</p>
                                    </div>
                                </div>
                                }
                                {!!detail.agreement_history.length > 0 &&
                                <div
                                    className={'c-client-rules-detail__single c-client-rules-detail__list c-client-rules-detail__list--single'}>
                                    <header>
                                        <h2>Oudere versies</h2>
                                    </header>
                                    <div className={'c-client-rules-detail__single__content'}>
                                        {detail.agreement_history.map((detailOld) => (
                                            <div className={'c-client-rules-detail__list__single'}>
                                                <Link
                                                    to={RouteEnum.ClientRulesDetail + '/' + detail.id + RouteEnum.ClientRulesDetailOld + '/' + detailOld.id}>Versie {detailOld.version}</Link>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                                }
                                {!!detail.projects &&
                                <div className={'c-client-rules-detail__single c-client-rules-detail__buttons'}>
                                    <header>
                                        <h2>Gekoppelde projecten</h2>
                                    </header>
                                    <div className={'c-client-rules-detail__single__content'}>
                                        {detail.projects.map((projects) => (
                                            <div className={'c-label'}>{projects.title}</div>
                                        ))}
                                    </div>
                                </div>
                                }
                            </div>
                        </div>
                    </>
                    }
                </div>*/
            );
        } else {
            return (
                <div className={'c-timetracking-loader'}>
                    <Loader
                        type="Watch"
                        color="#51B0AA"
                        height={100}
                        width={100}
                        timeout={10000} //3 secs
                    />
                </div>
            )
        }
    }
}

export default connect(mapStateToProps)(ClientRulesDetail);
