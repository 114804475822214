import {BaseModel} from 'sjs-base-model';

export default class ClientModel extends BaseModel {
    id = false
    name = false;


    constructor(data) {
        super();
        this.update(data);
    }
}