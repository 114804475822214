import TimeTrackingAction from './TimetrackingAction'

export default class TimeTrackingReducer {

    static initialState = {
        date: new Date(),
        timeTrackFetching: false,
        redirect: false,
        currentTimeTracking: false,
        weeklyTimeTracking: false,
        fetchDayCount: 0,
        timeTrackings: [],
        formSubmitting: false,
        displayTotals: false,
    };

    static updateObjectInArray(array, indexInsert, itemInsert) {

        return array.map((item, index) => {

            if (index !== indexInsert) {
                // This isn't the item we care about - keep it as-is
                return item
            }
            // Otherwise, this is the one we want - return an updated value
            return {
                ...item,
                ...itemInsert
            }
        })
    }

    static insertArray(arrayExisting, arrayNew) {
        return arrayExisting.concat(arrayNew)
    }

    static reducer(state = TimeTrackingReducer.initialState, action) {
        switch (action.type) {
            case TimeTrackingAction.REQUEST_TIME_TRACKING:
                return {
                    ...state,
                    timeTrackFetching: true,
                };
            case TimeTrackingAction.REQUEST_TIME_TRACKING + '_FINISHED':
                return {
                    ...state,
                    timeTrackFetching: false,
                    ...action.payload,
                };
            case TimeTrackingAction.REQUEST_WEEKLY_TIME_TRACKING_OVERVIEW:
                return {
                    ...state,
                    weeklyTimeTracking: false,
                };
            case TimeTrackingAction.REQUEST_WEEKLY_TIME_TRACKING_OVERVIEW + '_FINISHED':
                return {
                    ...state,
                    weeklyTimeTracking: action.payload,
                };

            case TimeTrackingAction.REGISTER_TIME_TRACKING:
                return {
                    ...state,
                    formSubmitting: true,
                };
            case TimeTrackingAction.REQUEST_TIME_TRACKING_DAY:

                if (state.fetchDayCount === 1) {
                    // Empty again if it starts running
                    return {
                        ...state,
                        fetchDayCount: state.fetchDayCount + 1,
                        timeTrackings: [],
                    };
                } else {
                    return {
                        ...state,
                        fetchDayCount: state.fetchDayCount + 1,
                    };
                }


            case TimeTrackingAction.REQUEST_TIME_TRACKING_DAY + '_FINISHED':

                if (action.payload.error) {
                    return {
                        ...state,
                        ...action.payload
                    };
                } else {

                    if (state.weeklyTimeTracking[action.payload.weekKey]){
                        return {
                            ...state,
                            weeklyTimeTracking: {
                                ...state.weeklyTimeTracking,
                                [action.payload.weekKey]: {
                                    ...state.weeklyTimeTracking[action.payload.weekKey],
                                    weekData: TimeTrackingReducer.updateObjectInArray(state.weeklyTimeTracking[action.payload.weekKey].weekData, action.payload.dayKey, action.payload.day)

                                }
                            },
                            timeTrackings: TimeTrackingReducer.insertArray(state.timeTrackings, action.payload.timeTrackings),
                            fetchDayCount: state.fetchDayCount + -1,
                            //Return redirect to overview to false, need to find a better location
                            redirect: false,
                            timeTrackFetching: false,
                            formSubmitting: false
                        };
                    }else{
                        return {
                            ...state,
                            weeklyTimeTracking: false,
                            timeTrackings: TimeTrackingReducer.insertArray(state.timeTrackings, action.payload.timeTrackings),
                            fetchDayCount: state.fetchDayCount + -1,
                            //Return redirect to overview to false, need to find a better location
                            redirect: false,
                            timeTrackFetching: false,
                            formSubmitting: false
                        };
                    }

                }


            case TimeTrackingAction.REGISTER_TIME_TRACKING + '_FINISHED':
                return Object.assign({}, state, {
                    formSubmitting: false,
                    redirect: true,
                    currentTimeTracking: false,
                    ...action.payload
                })
            case TimeTrackingAction.EDIT_TIME_TRACKING:
                return Object.assign({}, state, {
                    formSubmitting: true,
                })
            case TimeTrackingAction.EDIT_TIME_TRACKING + '_FINISHED':
                return {
                    ...state,
                    formSubmitting: false,
                    redirect: true,
                    currentTimeTracking: false,
                    ...action.payload
                };

            case TimeTrackingAction.SET_ACTIVE_TIME_TRACKING_OPTIONS:
                return {
                    ...state,
                    activeTimeTrackingOptionsId: action.payload.sjsId
                }

            case TimeTrackingAction.DISPLAY_TOTALS:
                return {
                    ...state,
                    displayTotals: !state.displayTotals
                }

            case TimeTrackingAction.UNSET_TIME_TRACKING:
                return {
                    ...state,
                    currentTimeTracking: false
                }

            case TimeTrackingAction.CHANGE_MONTH:

                let newDate = new Date();
                newDate.setFullYear(state.date.getFullYear())
                newDate.setMonth(state.date.getMonth() + action.payload.direction);

                return {
                    ...state,
                    weeklyTimeTracking: false,
                    date: newDate,
                    timeTrackings: [],
                }

            case TimeTrackingAction.RESET_MONTH:
                const resetDate = new Date();

                return {
                    ...state,
                    weeklyTimeTracking: false,
                    date: resetDate,
                    timeTrackings: [],
                    currentTimeTracking: false
                }

            case TimeTrackingAction.REMOVE_TIME_TRACKING:
                return {
                    ...state,
                    formSubmitting: true,
                };
            case TimeTrackingAction.REMOVE_TIME_TRACKING + '_FINISHED':
                return {
                    ...state,
                    formSubmitting: false,
                    redirect: true,
                };
            default:
                return state;
        }
    }
}
