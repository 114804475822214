import RouteEnum from '../../constants/RouteEnum'
import cookie from 'react-cookies'

export default class ClientRulesEffect {

    static async requestClientRulesList() {
        return await fetch(RouteEnum.API + '/api/agreement/list', {
            method: 'GET',
            headers: {
                'Authorization': 'Bearer ' + cookie.load('token'),
                'Accept': 'application/json',
            },
        }).then((response) => {
            return response.json()
        }).then((response) => {
            if (response.status === 401) {
                cookie.remove('token', {path: '/'})
            }

            return {
                ...response.parameters.agreements,
            }

        })
    }

    static async requestClientRulesDetail(id) {
        return await fetch(RouteEnum.API + '/api/agreement/detail?id=' + id, {
            method: 'GET',
            headers: {
                'Authorization': 'Bearer ' + cookie.load('token'),
                'Accept': 'application/json',
                'Content-Type': 'application/json'
            },
        }).then((response) => {
            return response.json()
        }).then((response) => {
            if (response.status === 401) {
                cookie.remove('token', {path: '/'})
            }

            return {
                ...response.parameters.agreement,
            }
        })
    }

    static async requestClientRulesSetSeen(id) {
        return await fetch(RouteEnum.API + '/api/agreement/set-seen?id=' + id, {
            method: 'GET',
            headers: {
                'Authorization': 'Bearer ' + cookie.load('token'),
                'Accept': 'application/json',
            },
        }).then((response) => {
            return response.json()
        }).then((response) => {
            if (response.status === 401) {
                cookie.remove('token', {path: '/'})
            }
        })
    }

    static async requestClientRulesInfo() {
        return await fetch(RouteEnum.API + '/api/agreement/more-info', {
            method: 'GET',
            headers: {
                'Authorization': 'Bearer ' + cookie.load('token'),
                'Accept': 'application/json',
            },
        }).then((response) => {
            return response.json()
        }).then((response) => {
            if (response.status === 401) {
                cookie.remove('token', {path: '/'})
            }

            return {
                ...response.parameters.notification,
            }

        })
    }

}
