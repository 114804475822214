import HappyCheckAction from './HappyCheckAction'

export default class HappyCheckReducer {

    static initialState = {
        happyChecksOpen: false,
        happyCheckRegister: false,
        happyCheckInfo: false
    };

    static reducer(state = HappyCheckReducer.initialState, action) {

        switch (action.type) {

            case HappyCheckAction.REQUEST_OPEN:

                return {
                    ...state,
                    happyChecksOpen: false
                };

            case HappyCheckAction.REQUEST_OPEN + '_FINISHED':
                return {
                    ...state,
                    happyChecksOpen: action.payload,
                }

            case HappyCheckAction.REQUEST_REGISTER:

                return {
                    ...state,
                    happyCheckRegister: false
                };

            case HappyCheckAction.REQUEST_REGISTER + '_FINISHED':
                return {
                    ...state,
                    happyCheckRegister: action.payload,
                }

            case HappyCheckAction.REQUEST_INFO:

                return {
                    ...state,
                    happyCheckInfo: false
                };

            case HappyCheckAction.REQUEST_INFO + '_FINISHED':
                return {
                    ...state,
                    happyCheckInfo: action.payload,
                }

            default:
                return state;
        }
    }

}
