import React from 'react'
import './ClientRulesOverview.scss'
import {connect} from "react-redux";
import Loader from "react-loader-spinner";
import ClientRulesAction from "../../../stores/clientRules/ClientRulesAction";
import ClientRuleSingle from "../_components/ClientRuleSingle";
import HappyCheckModal from "../../HappyCheck/_components/Modal";

const mapStateToProps = (state, ownProps) => ({
    ...state.clientRules
});


class ClientRulesOverview extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            activeModal: false,
        }
        this.toggleModal = this.toggleModal.bind(this)
    }

    componentDidMount() {
        this.props.dispatch(ClientRulesAction.requestClientRulesList());
        this.props.dispatch(ClientRulesAction.requestClientRulesInfo());
    }

    toggleModal = () => {
        this.setState({
            activeModal: !this.state.activeModal
        })
    }

    render() {

        const rules = this.props.clientRules.list
        let loading = false
        if (!rules) loading = true

        return (
            <>
                {!!this.props.clientRules.info.notification && this.state.activeModal &&
                <HappyCheckModal modalHandler={this.toggleModal} {...this.props.clientRules.info}/>
                }

                <div className={'c-time c-time--dark'}>
                    <header className={'c-time-header c-client-rules__header'}>
                        <h1>
                            Klantafspraken overzicht
                        </h1>
                        {!!this.props.clientRules.info.notification &&
                        <button onClick={() => this.toggleModal()}>Meer info</button>
                        }
                    </header>
                    <div className={'o-container o-container--primary'}>

                        {!!rules &&
                        <div className={'c-time-form c-client-rules'}>
                            {Object.values(rules).map((rule) => (
                                <ClientRuleSingle {...rule} />
                            ))}
                        </div>
                        }

                        {loading &&
                        <div className={'c-timetracking-loader'}>
                            <Loader
                                type="Watch"
                                color="#51B0AA"
                                height={100}
                                width={100}
                                timeout={10000} //3 secs
                            />
                        </div>
                        }

                    </div>

                </div>
            </>
        );
    }
}

export default connect(mapStateToProps)(ClientRulesOverview);
