import React from 'react'
import {connect} from 'react-redux';
import TimeTrackingAction from '../../../stores/timetracking/TimetrackingAction';
import Moment from 'react-moment';
import 'moment/locale/nl';
import Loader from 'react-loader-spinner'
import SwitchMonth from "./_components/SwitchMonth/SwitchMonth";
import './TimetrackingOverview.scss';
import Day from "../../_components/TimeTrackingDay/TimeTrackingDay";
import PdfLink from '../../../utilities/PdfLink'

import TotalsPopup from '../../_components/TotalsPopup/TotalsPopup'
import cookie from "react-cookies";
import AuthenticationAction from "../../../stores/authentication/AuthenticationAction";


const mapStateToProps = (state, ownProps) => ({
    ...state.timeTracking,
    ...state.client
});

class TimeTrackingOverview extends React.Component {

    constructor(props) {
        super(props)
    }

    componentDidMount() {
        if (!cookie.load('token')){
            this.props.dispatch(AuthenticationAction.logout());
        }

        this.props.dispatch(TimeTrackingAction.unsetTimeTracking());
        if (!this.props.weeklyTimeTracking) {
            this.props.dispatch(TimeTrackingAction.requestWeeklyTimeTrackingOverview());
        }
    }

    render() {
        const weeklyTimeTracking = this.props.weeklyTimeTracking


        if (weeklyTimeTracking) {

            let loading = false
            if (this.props.fetchDayCount > 0) loading = true

            let timeTrackingPdf = null;
            if (!loading) {
                timeTrackingPdf = <PdfLink/>
            }

            return (
                <div className={'c-timetracking-overview'}>
                    <div className={'o-container o-container--primary'}>

                        <SwitchMonth {...this.props} clickChangeMonth={this._clickChangeMonth}/>

                        <div className={'load-timetracking-' + loading}>

                            {Object.keys(weeklyTimeTracking).reverse().map((timeTrackingWeekKey) => {
                                const timeTrackingWeek = weeklyTimeTracking[timeTrackingWeekKey]

                                return (
                                    <div className={'c-timetracking-week'} key={'week-' + timeTrackingWeekKey}>
                                        <div className={'c-timetracking-week-name'}>

                                        <span>week {timeTrackingWeek.weekNumber} - {timeTrackingWeek.firstDate} t/m {timeTrackingWeek.lastDate}
                                            &nbsp;<Moment format="MMMM">{this.props.date}</Moment>
                                        </span>
                                            
                                        </div>

                                        <div className={'c-timetracking-week-items'}>

                                            {Object.keys(timeTrackingWeek.weekData).reverse().map((dayKey) => {
                                                const timeTrackingDay = timeTrackingWeek.weekData[dayKey]
                                                return (
                                                    <Day data={timeTrackingDay} weekKey={timeTrackingWeekKey}
                                                         key={timeTrackingWeekKey + dayKey} dayKey={parseInt(dayKey)}/>
                                                )
                                            })}
                                        </div>

                                    </div>
                                )
                            })}
                        </div>

                        {loading &&
                        <div className={'c-timetracking-loader'}>
                            <Loader
                                type="Watch"
                                color="#51B0AA"
                                height={100}
                                width={100}
                                timeout={10000} //3 secs
                            />
                        </div>
                        }

                    </div>
                    {!loading &&
                    <TotalsPopup
                        pdfLink={timeTrackingPdf}
                    />
                    }

                </div>
            )
        } else {
            return null
        }
    }

    _clickChangeMonth = (direction) => {
        this.props.dispatch(TimeTrackingAction.changeMonth(direction))
        this.props.dispatch(TimeTrackingAction.requestWeeklyTimeTrackingOverview());
    }
}

export default connect(mapStateToProps)(TimeTrackingOverview);
