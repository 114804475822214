import React from 'react'
import InlineSVG from 'svg-inline-react';

const LogoTaglineData = `
<?xml version="1.0" encoding="UTF-8"?>
<svg width="177px" height="91px" viewBox="0 0 177 91" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
    <!-- Generator: Sketch 62 (91390) - https://sketch.com -->
    <title>Group</title>
    <desc>Created with Sketch.</desc>
    <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
        <g id="Artboard" transform="translate(-127.000000, -272.000000)">
            <g id="Group-9" transform="translate(127.000000, 137.276000)">
                <g id="Group" transform="translate(0.045300, 134.944200)">
                    <path d="M44.198,24.7673 C44.198,36.7533 34.482,46.4693 22.496,46.4693 C10.51,46.4693 0.794,36.7533 0.794,24.7673 C0.794,12.7813 10.51,3.0653 22.496,3.0653 C34.482,3.0653 44.198,12.7813 44.198,24.7673" id="Fill-1" fill="#51B1AC"></path>
                    <path d="M33.3471,24.7673 C33.3471,30.7603 28.4891,35.6183 22.4961,35.6183 C16.5031,35.6183 11.6451,30.7603 11.6451,24.7673 C11.6451,18.7743 16.5031,13.9163 22.4961,13.9163 C28.4891,13.9163 33.3471,18.7743 33.3471,24.7673" id="Fill-5" fill="#F1AF9C"></path>
                    <polygon id="Fill-10" fill="#3B556B" points="79.8216 11.557 79.8216 14.789 67.1736 32.34 79.9326 32.34 79.9326 39.026 56.8106 39.026 56.8106 36.185 69.9596 17.742 57.8136 17.742 57.8136 11.557"></polygon>
                    <path d="M90.797,0.0801 L90.797,15.2901 C92.413,12.4491 96.926,10.8891 99.824,10.8891 C107.847,10.8891 113.808,15.7921 113.808,25.2641 C113.808,34.2901 107.736,39.6391 99.657,39.6391 C96.313,39.6391 92.914,38.5251 90.797,35.2371 L90.352,39.0261 L84,39.0261 L84,0.0801 L90.797,0.0801 Z M91.243,25.2641 C91.243,30.2221 94.921,33.3431 99.21,33.3431 C103.557,33.3431 107.011,30.0551 107.011,25.2641 C107.011,20.3051 103.557,17.2401 99.21,17.2401 C94.921,17.2401 91.243,20.4721 91.243,25.2641 L91.243,25.2641 Z" id="Fill-12" fill="#3B556B"></path>
                    <polygon id="Fill-17" fill="#3B556B" points="146.1809 11.5018 134.4799 39.1378 127.1249 39.1378 115.3689 11.5018 122.7789 11.5018 126.5679 20.5838 130.8019 32.1728 134.9819 20.6948 138.7699 11.5018"></polygon>
                    <path d="M176.7697,25.32 C176.7697,33.232 171.3647,39.639 162.4497,39.639 C153.5357,39.639 148.1867,33.232 148.1867,25.32 C148.1867,17.463 153.6467,11 162.3947,11 C171.1427,11 176.7697,17.463 176.7697,25.32 M154.9837,25.32 C154.9837,29.499 157.4917,33.399 162.4497,33.399 C167.4097,33.399 169.9167,29.499 169.9167,25.32 C169.9167,21.197 167.0187,17.185 162.4497,17.185 C157.5467,17.185 154.9837,21.197 154.9837,25.32" id="Fill-19" fill="#3B556B"></path>
                    <path d="M60.8096,57.7609 C59.5226,57.7609 58.3266,58.2139 58.3806,59.1739 C58.3806,60.2249 59.7216,60.4969 60.8276,60.6419 C62.4216,60.8769 64.5236,61.1489 64.5236,63.3239 C64.4696,65.2079 62.6756,65.9329 60.8456,65.9329 C59.3056,65.9329 57.8016,65.4259 56.8766,64.1939 L57.6386,63.3599 C58.4176,64.3929 59.7036,64.7919 60.8636,64.8099 C61.8786,64.8099 63.3096,64.5199 63.3096,63.3059 C63.3096,62.1279 62.1686,61.9099 60.7186,61.7109 C59.0156,61.4759 57.1856,61.0409 57.1856,59.2109 C57.1856,57.4349 59.0336,56.6559 60.7916,56.6559 C62.1506,56.6559 63.2376,57.0179 64.1616,57.8699 L63.4546,58.6669 C62.6026,57.8879 61.7696,57.7609 60.8096,57.7609" id="Fill-23" fill="#636463"></path>
                    <path d="M66.6607,56.8005 L67.8387,56.8005 L67.8747,58.7025 C68.5817,57.4345 69.8867,56.6735 71.4447,56.6735 C73.9817,56.6735 75.9027,58.4495 75.9027,61.2765 C75.9027,64.1935 73.9817,65.9325 71.3717,65.9325 C69.9947,65.9325 68.5817,65.2995 67.8567,63.9405 L67.8567,70.0285 L66.6607,70.0285 L66.6607,56.8005 Z M74.6887,61.2765 C74.6887,59.0475 73.2567,57.8335 71.3177,57.8335 C69.3427,57.8335 67.9657,59.3195 67.9657,61.2945 C67.9657,63.2695 69.3787,64.7555 71.3177,64.7555 C73.2017,64.7555 74.6887,63.5235 74.6887,61.2765 L74.6887,61.2765 Z" id="Fill-27" fill="#636463"></path>
                    <path d="M82.2262,64.7733 C83.2762,64.7733 84.6352,64.3563 85.2522,63.5413 L86.0672,64.2663 C85.1792,65.3723 83.6572,65.9333 82.2262,65.9333 C79.5622,65.9333 77.5872,64.1213 77.5872,61.2763 C77.5872,58.6133 79.5082,56.6193 82.1722,56.6733 C84.6542,56.6193 86.6662,58.0513 86.6662,61.0773 C86.6662,61.3123 86.6472,61.5483 86.6292,61.8023 L78.8372,61.8023 C78.9822,63.6863 80.4502,64.7733 82.2262,64.7733 M82.1892,57.7423 C80.3782,57.7423 79.0732,58.8123 78.8372,60.7693 L85.4872,60.7693 C85.4332,58.8123 84.0022,57.7423 82.1892,57.7423" id="Fill-31" fill="#636463"></path>
                    <path d="M92.8436,65.933 C90.3066,65.933 88.2046,64.23 88.2046,61.294 C88.2046,58.359 90.2526,56.674 92.8436,56.674 C94.0576,56.674 95.0906,57.072 96.0146,57.997 L95.2176,58.776 C94.5466,58.124 93.7316,57.815 92.8616,57.815 C90.9586,57.815 89.4186,59.084 89.4186,61.294 C89.4186,63.559 90.9766,64.773 92.8436,64.773 C93.7676,64.773 94.6556,64.429 95.3256,63.759 L96.1596,64.574 C95.2176,65.499 94.0756,65.933 92.8436,65.933" id="Fill-35" fill="#636463"></path>
                    <path d="M98.7496,53.1404 C99.1846,53.1404 99.6196,53.4304 99.6196,53.9914 C99.6196,54.5714 99.1846,54.8434 98.7496,54.8434 C98.3326,54.8434 97.8986,54.5714 97.8986,53.9914 C97.8986,53.4304 98.3326,53.1404 98.7496,53.1404 L98.7496,53.1404 Z M98.1336,65.7524 L99.3476,65.7524 L99.3476,56.7654 L98.1336,56.7654 L98.1336,65.7524 Z" id="Fill-39" fill="#636463"></path>
                    <path d="M110.8718,56.8005 L110.8718,65.7515 L109.7478,65.7515 L109.7478,63.9765 C108.9698,65.2995 107.5008,65.9325 106.1608,65.9325 C103.6238,65.9325 101.5938,64.1215 101.5938,61.2765 C101.5938,58.4675 103.6418,56.6735 106.1788,56.6735 C107.5558,56.6735 109.0418,57.3085 109.7308,58.5765 L109.7308,56.8005 L110.8718,56.8005 Z M102.8078,61.2765 C102.8078,63.3785 104.3118,64.7915 106.2148,64.7915 C108.5338,64.7915 109.6758,63.0345 109.6758,61.2765 C109.6758,59.5365 108.5338,57.7795 106.2148,57.7795 C104.3118,57.7795 102.8078,59.1745 102.8078,61.2765 L102.8078,61.2765 Z" id="Fill-43" fill="#636463"></path>
                    <polygon id="Fill-48" fill="#636463" points="113.7867 65.7518 115.0017 65.7518 115.0017 53.0678 113.7867 53.0678"></polygon>
                    <path d="M118.5713,53.1404 C119.0063,53.1404 119.4413,53.4304 119.4413,53.9914 C119.4413,54.5714 119.0063,54.8434 118.5713,54.8434 C118.1543,54.8434 117.7203,54.5714 117.7203,53.9914 C117.7203,53.4304 118.1543,53.1404 118.5713,53.1404 L118.5713,53.1404 Z M117.9553,65.7524 L119.1693,65.7524 L119.1693,56.7654 L117.9553,56.7654 L117.9553,65.7524 Z" id="Fill-50" fill="#636463"></path>
                    <path d="M125.1852,57.7609 C123.8982,57.7609 122.7022,58.2139 122.7562,59.1739 C122.7562,60.2249 124.0972,60.4969 125.2032,60.6419 C126.7972,60.8769 128.8992,61.1489 128.8992,63.3239 C128.8452,65.2079 127.0512,65.9329 125.2212,65.9329 C123.6812,65.9329 122.1772,65.4259 121.2522,64.1939 L122.0142,63.3599 C122.7932,64.3929 124.0792,64.7919 125.2392,64.8099 C126.2542,64.8099 127.6852,64.5199 127.6852,63.3059 C127.6852,62.1279 126.5442,61.9099 125.0942,61.7109 C123.3912,61.4759 121.5612,61.0409 121.5612,59.2109 C121.5612,57.4349 123.4092,56.6559 125.1672,56.6559 C126.5262,56.6559 127.6132,57.0179 128.5372,57.8699 L127.8302,58.6669 C126.9782,57.8879 126.1452,57.7609 125.1852,57.7609" id="Fill-54" fill="#636463"></path>
                    <path d="M133.3022,56.873 L136.2022,56.873 L136.2022,57.906 L133.3022,57.906 L133.3022,62.907 C133.3022,64.049 133.6102,64.773 134.8242,64.773 C135.2412,64.773 135.6942,64.628 136.1292,64.429 L136.5092,65.444 C135.9122,65.716 135.3312,65.879 134.7332,65.879 C132.8312,65.879 132.0882,64.756 132.0882,62.907 L132.0882,57.906 L130.2582,57.906 L130.2582,56.873 L132.0882,56.873 L132.0882,54.282 L133.3022,54.155 L133.3022,56.873 Z" id="Fill-58" fill="#636463"></path>
                    <path d="M142.1263,64.7733 C143.1763,64.7733 144.5353,64.3563 145.1523,63.5413 L145.9673,64.2663 C145.0793,65.3723 143.5573,65.9333 142.1263,65.9333 C139.4623,65.9333 137.4873,64.1213 137.4873,61.2763 C137.4873,58.6133 139.4083,56.6193 142.0723,56.6733 C144.5543,56.6193 146.5663,58.0513 146.5663,61.0773 C146.5663,61.3123 146.5473,61.5483 146.5293,61.8023 L138.7373,61.8023 C138.8823,63.6863 140.3503,64.7733 142.1263,64.7733 M142.0893,57.7423 C140.2783,57.7423 138.9733,58.8123 138.7373,60.7693 L145.3883,60.7693 C145.3333,58.8123 143.9023,57.7423 142.0893,57.7423" id="Fill-62" fill="#636463"></path>
                    <path d="M155.7525,60.733 C155.7525,58.794 154.6475,57.797 152.8345,57.797 C151.0955,57.797 149.8995,59.174 149.8995,60.877 L149.8995,65.752 L148.6855,65.752 L148.6855,56.8 L149.8445,56.8 L149.8815,58.304 C150.6055,57.09 151.8745,56.655 153.0525,56.655 C153.5955,56.655 154.1395,56.746 154.5925,56.91 C156.0415,57.398 156.9845,58.74 156.9845,60.715 L156.9845,65.752 L155.7525,65.752 L155.7525,60.733 Z" id="Fill-66" fill="#636463"></path>
                    <path d="M165.046,53.1404 C165.481,53.1404 165.916,53.4304 165.916,53.9914 C165.916,54.5714 165.481,54.8434 165.046,54.8434 C164.629,54.8434 164.195,54.5714 164.195,53.9914 C164.195,53.4304 164.629,53.1404 165.046,53.1404 L165.046,53.1404 Z M164.43,65.7524 L165.644,65.7524 L165.644,56.7654 L164.43,56.7654 L164.43,65.7524 Z" id="Fill-70" fill="#636463"></path>
                    <path d="M175.5375,60.733 C175.5375,58.794 174.4325,57.797 172.6195,57.797 C170.8805,57.797 169.6845,59.174 169.6845,60.877 L169.6845,65.752 L168.4705,65.752 L168.4705,56.8 L169.6295,56.8 L169.6665,58.304 C170.3905,57.09 171.6595,56.655 172.8375,56.655 C173.3805,56.655 173.9245,56.746 174.3775,56.91 C175.8265,57.398 176.7695,58.74 176.7695,60.715 L176.7695,65.752 L175.5375,65.752 L175.5375,60.733 Z" id="Fill-74" fill="#636463"></path>
                    <path d="M66.2455,80.9189 C66.2455,83.6909 64.3065,85.5209 61.6425,85.5209 C59.0335,85.5209 57.0405,83.6909 57.0405,80.9189 C57.0405,78.1459 58.9615,76.2619 61.6425,76.2619 C64.2525,76.2619 66.2455,78.1459 66.2455,80.9189 M58.2545,80.9189 C58.2545,83.0209 59.6675,84.3799 61.6425,84.3799 C63.6545,84.3799 65.0315,83.0209 65.0315,80.9189 C65.0315,78.8349 63.6175,77.3669 61.6425,77.3669 C59.6495,77.3669 58.2545,78.8349 58.2545,80.9189" id="Fill-78" fill="#636463"></path>
                    <path d="M69.6871,81.4083 C69.6871,83.2743 70.6831,84.3253 72.4591,84.3253 C74.2171,84.3253 75.4491,83.0023 75.4491,81.2633 L75.4491,76.3883 L76.6451,76.3883 L76.6451,85.3403 L75.5211,85.3403 L75.4851,83.8363 C74.7431,84.9953 73.5651,85.5033 72.3141,85.5213 C69.9951,85.5213 68.4731,83.9443 68.4731,81.4263 L68.4731,76.3883 L69.6871,76.3883 L69.6871,81.4083 Z" id="Fill-82" fill="#636463"></path>
                    <path d="M88.1505,85.3401 L86.9725,85.3401 L86.9365,83.5641 C86.2115,84.8691 84.8525,85.5211 83.4205,85.5211 C80.8845,85.5211 78.9095,83.8541 78.9095,80.8821 C78.9095,77.9291 80.8845,76.2081 83.4205,76.2621 C84.8165,76.2431 86.2665,76.8591 86.9365,78.1831 L86.9365,72.6741 L88.1505,72.6741 L88.1505,85.3401 Z M80.1235,80.8821 C80.1235,83.1291 81.6275,84.3611 83.4935,84.3611 C85.4145,84.3611 86.8825,82.9481 86.8825,80.8651 C86.8825,78.8171 85.3955,77.4031 83.4935,77.4031 C81.6275,77.4031 80.1235,78.6181 80.1235,80.8821 L80.1235,80.8821 Z" id="Fill-86" fill="#636463"></path>
                    <path d="M95.0355,84.3613 C96.0855,84.3613 97.4445,83.9443 98.0615,83.1293 L98.8765,83.8543 C97.9885,84.9603 96.4665,85.5213 95.0355,85.5213 C92.3715,85.5213 90.3965,83.7093 90.3965,80.8643 C90.3965,78.2013 92.3175,76.2073 94.9815,76.2613 C97.4635,76.2073 99.4745,77.6393 99.4745,80.6653 C99.4745,80.9003 99.4565,81.1363 99.4385,81.3903 L91.6465,81.3903 C91.7915,83.2743 93.2595,84.3613 95.0355,84.3613 M94.9985,77.3303 C93.1875,77.3303 91.8825,78.4003 91.6465,80.3573 L98.2965,80.3573 C98.2425,78.4003 96.8115,77.3303 94.9985,77.3303" id="Fill-90" fill="#636463"></path>
                    <path d="M102.8082,77.8567 C103.3882,76.6427 104.5662,76.2437 105.6532,76.2437 C106.4692,76.2437 107.0312,76.4257 107.6832,76.8777 L107.1212,77.8927 C106.6682,77.5847 106.1432,77.4217 105.5812,77.4217 C104.0402,77.4217 102.8082,78.6177 102.8082,80.2487 L102.8082,85.3397 L101.5942,85.3397 L101.5942,76.3887 L102.7362,76.3887 L102.8082,77.8567 Z" id="Fill-94" fill="#636463"></path>
                    <path d="M112.9011,84.3613 C113.9511,84.3613 115.3101,83.9443 115.9271,83.1293 L116.7421,83.8543 C115.8541,84.9603 114.3321,85.5213 112.9011,85.5213 C110.2371,85.5213 108.2621,83.7093 108.2621,80.8643 C108.2621,78.2013 110.1831,76.2073 112.8471,76.2613 C115.3291,76.2073 117.3401,77.6393 117.3401,80.6653 C117.3401,80.9003 117.3221,81.1363 117.3041,81.3903 L109.5121,81.3903 C109.6571,83.2743 111.1251,84.3613 112.9011,84.3613 M112.8641,77.3303 C111.0531,77.3303 109.7481,78.4003 109.5121,80.3573 L116.1631,80.3573 C116.1081,78.4003 114.6771,77.3303 112.8641,77.3303" id="Fill-98" fill="#636463"></path>
                    <path d="M126.5274,80.3212 C126.5274,78.3822 125.4214,77.3852 123.6094,77.3852 C121.8704,77.3852 120.6744,78.7622 120.6744,80.4652 L120.6744,85.3402 L119.4604,85.3402 L119.4604,76.3882 L120.6194,76.3882 L120.6564,77.8922 C121.3804,76.6782 122.6494,76.2432 123.8274,76.2432 C124.3704,76.2432 124.9144,76.3342 125.3674,76.4982 C126.8164,76.9862 127.7594,78.3282 127.7594,80.3032 L127.7594,85.3402 L126.5274,85.3402 L126.5274,80.3212 Z" id="Fill-102" fill="#636463"></path>
                    <polygon id="Fill-107" fill="#636463" points="136.7638 76.9145 131.4728 84.1805 136.7818 84.1805 136.7818 85.3405 129.6248 85.3405 129.6248 84.8875 134.9698 77.4945 129.9328 77.4945 129.9328 76.3885 136.7638 76.3885"></polygon>
                    <path d="M147.3636,80.9189 C147.3636,83.6909 145.4246,85.5209 142.7606,85.5209 C140.1516,85.5209 138.1586,83.6909 138.1586,80.9189 C138.1586,78.1459 140.0796,76.2619 142.7606,76.2619 C145.3706,76.2619 147.3636,78.1459 147.3636,80.9189 M139.3726,80.9189 C139.3726,83.0209 140.7856,84.3799 142.7606,84.3799 C144.7726,84.3799 146.1496,83.0209 146.1496,80.9189 C146.1496,78.8349 144.7356,77.3669 142.7606,77.3669 C140.7686,77.3669 139.3726,78.8349 139.3726,80.9189" id="Fill-109" fill="#636463"></path>
                    <path d="M150.8228,77.8567 C151.4028,76.6427 152.5808,76.2437 153.6678,76.2437 C154.4838,76.2437 155.0458,76.4257 155.6978,76.8777 L155.1358,77.8927 C154.6828,77.5847 154.1578,77.4217 153.5958,77.4217 C152.0548,77.4217 150.8228,78.6177 150.8228,80.2487 L150.8228,85.3397 L149.6088,85.3397 L149.6088,76.3887 L150.7508,76.3887 L150.8228,77.8567 Z" id="Fill-113" fill="#636463"></path>
                    <path d="M165.4462,76.5336 L164.4862,77.6746 C165.1562,78.4176 165.6812,79.2156 165.6812,80.6476 C165.6812,82.2236 164.8122,83.8726 162.9812,84.3976 C164.6672,84.8506 165.6272,85.8296 165.6272,87.3336 C165.6272,89.5806 163.3982,90.7406 161.0252,90.7406 C158.4512,90.7406 156.4942,89.3816 156.4942,87.0436 L157.7082,87.0436 C157.7082,88.7286 159.3032,89.5616 161.0252,89.5616 C162.6742,89.5616 164.3952,88.9276 164.3952,87.3336 C164.3952,85.7566 162.6912,85.0496 161.0252,85.0496 C158.2702,85.0496 156.3322,83.4376 156.3322,80.6476 C156.3322,77.7296 158.7782,76.2616 161.0252,76.2616 C161.8942,76.2616 162.9812,76.4796 163.6702,76.9686 L164.6122,75.8446 L165.4462,76.5336 Z M157.5632,80.6476 C157.5632,82.8756 159.0862,83.9266 161.0252,83.9266 C163.1082,83.9266 164.4672,82.4406 164.4672,80.6476 C164.4672,78.4176 162.7822,77.3856 161.0252,77.3856 C159.3762,77.3856 157.5632,78.4006 157.5632,80.6476 L157.5632,80.6476 Z" id="Fill-117" fill="#636463"></path>
                </g>
            </g>
            <g id="Group-16" transform="translate(211.000000, 134.276000)"></g>
            <g id="Group-47" transform="translate(183.000000, 145.276000)"></g>
            <g id="Group-106" transform="translate(184.000000, 187.276000)"></g>
        </g>
    </g>
</svg>
`

const LogoTagline = (props) => {

    return (
        <InlineSVG src={LogoTaglineData}/>
    )

}

export default LogoTagline