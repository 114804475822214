import React from 'react'
import './Statistics.scss'
import {connect} from "react-redux";
import moment from 'moment'

const mapStateToProps = (state, ownProps) => ({
    ...state.dayOff
});

class Statistics extends React.Component {

    constructor(props) {
        super(props);
    }

    render() {

        const now = new moment();
        const currentMonth = now.format('MMMM')
        const currentYear = now.format('YYYY')
        const totals = this.props.totals

        if (totals) {

            return (
                <div className="c-dashboard__statistics">
                    <div className="c-dashboard__single">
                        <div className="c-dashboard__header c-dashboard__header--dark">
                            <h2>Werkstatistieken {currentMonth} {currentYear}</h2>
                        </div>
                        <div className="c-statistics">
                            <div className="c-statistics__single">
                                <small>Totaal gewerkte uren</small>
                                <h3>{totals.worked_this_month}</h3>
                            </div>
                            <div className="c-statistics__single">
                                <small>Totaal uren verzuim</small>
                                <h3>{totals.sick_hours}</h3>
                            </div>
                            <div className="c-statistics__single">
                                <small>Beschikbare uren {currentYear}</small>
                                <h3>{totals.available_this_year}</h3>
                            </div>
                            <div className="c-statistics__single">
                                <small>Uren opgenomen {currentYear}</small>
                                <h3>{totals.used_this_year}</h3>
                            </div>
                            <div className="c-statistics__single">
                                <small>Totaal beschikbaar</small>
                                <h3>{totals.available}</h3>
                            </div>
                        </div>
                    </div>
                </div>
            )
        } else {
            return null;
        }
    }

}

export default connect(mapStateToProps)(Statistics);