import ActionUtility from '../../utilities/ActionUtility'

export default class ServiceWorkerAction {
    static SW_INIT = 'ServiceWorkerAction.SW_INIT'
    static SW_UPDATE = 'ServiceWorkerAction.SW_UPDATE'

    static swInit() {
        console.log('sw initialize')
        return ActionUtility.createAction(ServiceWorkerAction.SW_INIT, {});
    }

    static swUpdate(reg) {
        console.log('sw update available')
        return ActionUtility.createAction(ServiceWorkerAction.SW_UPDATE, reg);
    }
}