import React from 'react'
import '../HappyCheck.scss'
import HappyCheckModal from "./Modal";
import NotificationAction from "../../../stores/notification/NotificationAction";
import {connect} from "react-redux";
import HappyCheckAction from "../../../stores/happyCheck/HappyCheckAction";

const mapStateToProps = (state, ownProps) => ({
    ...state.happyCheck
});

class Header extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            activeModal: false,
        }
        this.toggleModal = this.toggleModal.bind(this)
    }


    toggleModal = () => {
        this.setState({
            activeModal: !this.state.activeModal
        })
    }

    render() {

        return (

            <>
                {!!this.props.happyCheckInfo && this.state.activeModal &&
                    <HappyCheckModal modalHandler={this.toggleModal} {...this.props.happyCheckInfo.info}/>
                }

                <header className={'c-time-header c-happy-check-header'}>
                    <div>
                        <h1>
                            Happycheck
                        </h1>
                        {!!this.props.month && !!this.props.year &&
                        <small>{this.props.month} {this.props.year}</small>
                        }
                    </div>
                    <button onClick={() => this.toggleModal()}>meer info</button>
                </header>
            </>

        );
    }
}


export default connect(mapStateToProps)(Header);

