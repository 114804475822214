import AuthenticationAction from './AuthenticationAction'
import cookie from 'react-cookies'

export default class AuthenticationReducer {

    static initialState = {
        token: cookie.load('token'),
        error: false,
        g_auth: false,
        authApiPending: false,
        serviceWorkerInitialized: false,
        serviceWorkerUpdated: false,
        serviceWorkerRegistration: null,
        forgotSubmit: false,
        forgotError: false
    };

    static reducer(state = AuthenticationReducer.initialState, action) {
        switch (action.type) {
            case AuthenticationAction.REQUEST_LOGIN:
                return {
                    ...state,
                    token: false,
                    authApiPending: true
                };
            case AuthenticationAction.REQUEST_LOGIN + '_FINISHED':
                return {
                    ...state,
                    ...action.payload,
                    authApiPending: false
                };
            case AuthenticationAction.REQUEST_OTP:
                return {
                    ...state,
                    authApiPending: true
                };
            case AuthenticationAction.REQUEST_OTP + '_FINISHED':
                return {
                    ...state,
                    ...action.payload,
                    authApiPending: false
                };
            case AuthenticationAction.LOGOUT:
                cookie.remove('token', {path: '/'})
                return {
                    ...state,
                    token: false
                };
            case AuthenticationAction.RESET_PASSWORD:
                return {
                    ...state,
                    authApiPending: true
                };

            case AuthenticationAction.RESET_PASSWORD + '_FINISHED':
                return {
                    ...state,
                    ...action.payload,
                    authApiPending: false
                };

            default:
                return state;
        }
    }
}