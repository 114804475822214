import React from 'react'
import {connect} from "react-redux";
import {Link} from "react-router-dom";
import RouteEnum from "../../../../constants/RouteEnum";
import GetClientById from "../../../../utilities/ClientById"
import moment from 'moment';


const mapStateToProps = (state) => ({
    ...state.timeTracking,
    ...state.client
});

class TimeTracking extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            showOptions: false
        }
    }

    render() {

        const {start_time, end_time, time, visits, total_kilometers, type, customer_id, status, calls, sjsId, id} = this.props.data;


        const activeOptions = this.state.showOptions;
        const client = GetClientById(this.props.clients, customer_id);
        return (


            <div className={'c-timetracking-single'}>
                {activeOptions &&
                <div className={'c-timetracking-day-options-list'}>
                    <Link to={RouteEnum.TimeTrackingView + '/' + id} className={'btn'}>
                        <span>Bekijken</span>
                    </Link>
                    {status !== 'accepted' &&
                    <>
                        <Link to={RouteEnum.TimeTrackingEdit + '/' + id} className={'btn'}>
                            <span>Bewerken</span>
                        </Link>
                        <Link to={RouteEnum.TimeTrackingRemove + '/' + id} className={'btn'}>
                            <span>Verwijderen</span>
                        </Link>
                    </>
                    }
                </div>
                }

                <div className={'c-timetrackingday-info'}>

                    <div className={'c-timetracking-day-info-icon'}>
                        <div className={'c-timetracking-day-info-icon-circle'}/>
                    </div>

                    <div className={'c-timetracking-day-info-text'}>
                        <h4>
                            {status === 'accepted' &&
                            <i className={'icon icon-lock is-green'}/>
                            }

                            {status === 'declined' &&
                            <>
                                <span className={'icon-alert'}>!</span>
                            </>
                            }

                            {moment.duration(start_time).format('HH:mm')} - {moment.duration(end_time).format('HH:mm')}

                        </h4>

                        {client &&
                        <h3>{client.name}</h3>
                        }

                        <div className={'c-timetracking-day-info-totals'}>
                            <div>
                                <i className={'icon-driven'}/>
                                <span>{total_kilometers}km</span>
                            </div>
                            <div>
                                <img src={'./images/clock.svg'} className={'clock-image'}/>
                                <span>{time}</span>
                            </div>
                            {type === 'availability' &&
                            <>
                                {visits && visits.length &&
                                <div>
                                    <i className={'icon-house'}/>
                                    <span>{visits.length}x</span>
                                </div>
                                }
                                {calls && calls.length &&
                                <div>
                                    <i className={'icon-phone'}/>
                                    <span>{calls.length}x</span>
                                </div>
                                }
                            </>
                            }
                        </div>
                    </div>
                </div>

                <div className={'c-timetracking-day-action'} onClick={() => this._clickTimeTrackingOptions(sjsId)}>
                    <div className={'c-timetracking-day-options-trigger'}>
                        <span/>
                        <span/>
                        <span/>
                    </div>
                </div>

            </div>

        )
    }

    _clickTimeTrackingOptions = () => {
        this.setState({
            showOptions: !this.state.showOptions
        })
    }
}

export default connect(mapStateToProps)(TimeTracking);
