import React from 'react'
import {withRouter} from "react-router";
import {connect} from "react-redux";
import DayOffAction from "../../../stores/dayOff/DayOffAction";
import RouteEnum from "../../../constants/RouteEnum";
import Loader from 'react-loader-spinner'
import cookie from "react-cookies";
import AuthenticationAction from "../../../stores/authentication/AuthenticationAction";


const mapStateToProps = (state, ownProps) => ({
    ...state.dayOff,
});


class DaysOffRemove extends React.Component {

    constructor(props) {
        super(props)
    }

    componentDidMount() {
        if (!cookie.load('token')){
            this.props.dispatch(AuthenticationAction.logout());
        }

        let {redirectDayOff} = this.props;
        if (redirectDayOff === true) {
            this.props.history.push(RouteEnum.DaysOffOverview);
        }
    }

    componentDidUpdate() {
        let {redirectDayOff} = this.props;
        if (redirectDayOff === true) {
            this.props.history.push(RouteEnum.DaysOffOverview);
        }
    }

    render() {
        if (!this.props.formSubmitting) {

            return (
                <div className={'c-time-tracking-remove'}>
                    <div className={'c-time-tracking-remove__container'}>
                        <div className={'c-time-tracking-remove__content'}>
                            <h2>Weet u zeker dat u de een aanvraag wilt doen om uw aanvraag te annuleren?</h2>
                        </div>
                        <div className={'c-time-tracking-remove__buttons'}>
                            <button onClick={this._clickRemove.bind(this)}>Ja</button>
                            <button onClick={this._clickBack.bind(this)}>Nee (ga terug)</button>
                        </div>
                    </div>
                </div>
            )
        } else {
            return (
                <div className={'c-timetracking-loader'}>
                    <Loader
                        type="Bars"
                        color="#51B0AA"
                        height={50}
                        width={50}
                        timeout={10000}
                    />
                </div>
            )
        }
    }

    _clickBack() {
        this.props.history.goBack();
    }

    _clickRemove() {
        this.props.dispatch(DayOffAction.removeDayOff(this.props.match.params.id))
    }
}

export default withRouter(connect(mapStateToProps)(DaysOffRemove))
