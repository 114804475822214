import React from 'react'
import './TimeTrackingDay.scss';
import TimeTrackingDayOpen from './Filled/DayFilled'
import TimeTrackingDayEmpty from './Empty/DayEmpty'
import {connect} from "react-redux";
import TimeTrackingAction from "../../../stores/timetracking/TimetrackingAction";
import cookie from "react-cookies";

const mapStateToProps = (state, ownProps) => ({
    ...state.timeTracking,
});

class TimeTrackingDay extends React.Component {

    constructor(props) {
        super(props)
    }

    componentDidMount() {
        const data = {
            day: this.props.data,
            weekKey: this.props.weekKey,
            dayKey: this.props.dayKey
        }

        if(cookie.load('token')){
            this.props.dispatch(TimeTrackingAction.requestTimeTrackingDay(data));
        }
    }

    render() {
        const timeTracking = this.props.data.timeTracking
        if (timeTracking && timeTracking.length > 0) {
            return <TimeTrackingDayOpen
                key={this.props.key}
                data={this.props.data}
            />
        } else {
            return <TimeTrackingDayEmpty
                key={this.props.key}
                data={this.props.data}
                date={"Vrijdag 5 oktober"}
                text={"Vul een tijdregistratie in …."}
            />
        }
    }
}

export default  connect(mapStateToProps)(TimeTrackingDay);
