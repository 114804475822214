import React from 'react'

const WorkType = (props) => {
    const {id, data} = props;

    let mainClass = 'c-work-select'
    if (props.active) mainClass += ' is-active'

    return (
        <div className="c-time-form-group">
            <div className={mainClass} onClick={props.onClick.bind(this, 'type', data.value)}>
                <span>{data.title}</span>
            </div>
        </div>
    )
}

export default WorkType