import cookie from 'react-cookies'
import RouteEnum from '../../constants/RouteEnum'

export default class AuthenticationEffect {

    static async requestLogin(values) {
        return await fetch(RouteEnum.API + '/api/connect/login', {
            method: 'POST',
            headers: {
                'Accept': 'application/json',

            },
            body: JSON.stringify(values)
        }).then((response) => {
                return response.json()
            }
        ).then((response) => {
            if (response.status === 200) {
                const g_auth = response.parameters.g_auth
                if (g_auth) {
                    return {
                        g_auth: g_auth,
                        token: false,
                        error: false
                    };
                } else {

                    const token = response.parameters.token
                    cookie.save('token', token, {path: '/'})

                    return {
                        g_auth: false,
                        token: response.parameters.token,
                        error: false,
                    };
                }
            } else {
                return {
                    token: false,
                    error: 'De ingevoerde gegevens zijn niet bij ons bekend'
                }
            }
        })
    }

    static async requestOTP(values) {

        return await fetch(RouteEnum.API + '/api/connect/one-time-password', {
            method: 'POST',
            headers: {
                'Accept': 'application/json'
            },
            body: JSON.stringify(values)
        }).then((response) => {
                return response.json()
            }
        ).then((response) => {

            if (response.status === 200) {

                const token = response.parameters.token
                cookie.save('token', token, {path: '/'})

                return {
                    g_auth: false,
                    token: token,
                    error: false,
                };

            } else {
                return {
                    token: false,
                    error: 'Onjuist eenmalig wachtwoord'
                }
            }
        })
    }

    static async resetPassword(username) {

        return await fetch(RouteEnum.API + '/api/connect/reset-password', {
            method: 'POST',
            headers: {
                'Accept': 'application/json'
            },
            body: JSON.stringify({
                username: username
            })
        }).then((response) => {
                return response.json()
            }
        ).then((response) => {
            if (response.status === 200) {
                return{
                    forgotSubmit: true,
                    forgotError: 'Volg de instructies in uw email'
                }
            } else {
                return{
                    forgotSubmit: true,
                    forgotError: 'Uw gebruikersnaam is niet bij ons bekend'
                }
            }
        }).catch(function (error) {
            return {
                forgotSubmit: true,
                forgotError: 'Er is iets misgegaan. Neem a.u.b. contact op met het beheer bij ZBVO'
            }
        })
    }

}