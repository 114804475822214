import React from 'react'
import Plus from "../../../../static/svg/Plus";
import {Link} from 'react-router-dom'
import RouteEnum from '../../../../constants/RouteEnum'
import Moment from 'react-moment';
import 'moment/locale/nl';
import moment from "moment";

class DayEmpty extends React.Component {
    render() {

        const {day} = this.props.data;

        return (
            <Link to={RouteEnum.TimeTrackingAdd + '/' + moment(day).format('YYYY-MM-DD')}
                  className={'c-timetracking-day c-timetracking-day--empty'}>
                <div className={'c-timetracking-day-info'}>

                    <div className={'c-timetracking-day-info-icon'}>
                        <div className={'c-timetracking-day-info-icon-circle'}/>
                    </div>

                    <div className={'c-timetracking-day-info-text'}>
                        <h3><Moment
                            locale="nl"
                            format="dddd D MMMM">{day}</Moment></h3>
                        <p>{this.props.text}</p>
                    </div>
                </div>

                <div className={'c-timetracking-day-action'}>
                    <Plus/>
                </div>
            </Link>
        )

    }
}

export default DayEmpty