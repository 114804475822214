import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';

import './index.css';

import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { createBrowserHistory } from 'history';
import rootStore from './stores/rootStore';
import * as Sentry from '@sentry/react';
import App from './views/App';
import * as serviceWorker from './serviceWorker';
import ServiceWorkerAction from './stores/serviceWorker/ServiceWorkerAction';

if (process.env.NODE_ENV === 'production') {
    Sentry.init({dsn: "https://0f111eb9ee5640c2b6c6797fa558658f@o425936.ingest.sentry.io/5366862"});
}

(async (window) => {

    const initialState = {};
    const history = createBrowserHistory();
    const store = rootStore(initialState, history);

    const rootEl = document.getElementById('root');
    const render = (Component, el) => {
        ReactDOM.render(
            <Provider store={store}>
                <Component history={history} dispatch={store.dispatch} />
            </Provider>,
            el
        );
    };

    render(App, rootEl);
})(window);

serviceWorker.unregister();
