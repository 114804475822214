import React from 'react'
import './DaysOffAdd.scss'
import {Formik, Field, FieldArray} from 'formik'
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file
import {DateRange} from 'react-date-range';
import moment from 'moment'
import DayOffAction from "../../../stores/dayOff/DayOffAction";
import {withRouter} from "react-router";
import {connect} from "react-redux";
import RouteEnum from "../../../constants/RouteEnum";
import Loader from 'react-loader-spinner'
import * as rdrLocales from 'react-date-range/dist/locale';
import DayPicker, {DateUtils} from 'react-day-picker';
import "react-day-picker/lib/style.css";
import cookie from "react-cookies";
import AuthenticationAction from "../../../stores/authentication/AuthenticationAction";


const mapStateToProps = (state, ownProps) => ({
    ...state.dayOff,
    ...state.client
});

const MONTHS = [
    'Januari',
    'Februari',
    'Maart',
    'April',
    'Mei',
    'Juni',
    'Juli',
    'Augustus',
    'September',
    'Oktober',
    'November',
    'December',
];
const WEEKDAYS_LONG = [
    'Zondag',
    'Maandag',
    'Dinsdag',
    'Woensdag',
    'Donderdag',
    'Vrijdag',
    'Zaterdag',
];
const WEEKDAYS_SHORT = ['Zo', 'Ma', 'Di', 'Wo', 'Do', 'Vr', 'Za'];

class DaysOffAdd extends React.Component {

    constructor(props) {
        super(props);
        this.handleDayClick = this.handleDayClick.bind(this);
        this.state = {
            selectedDays: [],
            showAll: false,
        }
    }

    componentDidMount() {
        if (!cookie.load('token')){
            this.props.dispatch(AuthenticationAction.logout());
        }

        this.props.dispatch(DayOffAction.requestTotals());
    }

    componentDidUpdate() {
        let {redirectDayOff} = this.props;
        if (redirectDayOff === true) {
            this.props.history.push(RouteEnum.DaysOffOverview);
        }
    }

    getRangeDays(startDate, endDate) {
        let dateArray = [];
        let currentDate = moment(startDate);
        let stopDate = moment(endDate);
        while (currentDate <= stopDate) {
            let dateObject = {}
            const day = moment(currentDate).format('dddd');
            //if (day !== 'zaterdag' && day !== 'zondag') {
            dateObject.moment = moment(currentDate)
            dateObject.date = moment(currentDate).format('DD / MM / YYYY')
            dateObject.hours = 8
            dateObject.edit = false
            dateArray.push(dateObject)

            currentDate = moment(currentDate).add(1, 'days')
        }
        return dateArray
    }

    handleChange(range, setFieldValue) {
        setFieldValue('dateRangeData', [range])
        setFieldValue('dateRangeDates', this.getRangeDays(range.startDate, range.endDate))
    }

    _onClickEditHour(key, currentValues) {
        currentValues[key].edit = true
        currentValues[key].tempHours = currentValues[key].hours
        this.setState({selectedDays: currentValues});
    }

    _setTempHours(value, key, currentValues) {
        currentValues[key].tempHours = value
        this.setState({selectedDays: currentValues});
    }

    _setHours(key, currentValues) {
        // Setting the hours to the value of the tempHours
        currentValues[key].hours = parseFloat(currentValues[key].tempHours)
        currentValues[key].edit = false
        this.setState({selectedDays: currentValues});
    }

    _setActiveType(key, currentValues, setFieldValue) {
        setFieldValue('activeType', currentValues.types[key])
    }

    _unsetActiveType(setFieldValue) {
        setFieldValue('activeType', false)
    }

    handleDayClick(day, {selected}) {
        day.moment = moment(day)
        day.date = moment(day).format('DD / MM / YYYY')
        day.hours = 8
        day.edit = false
        let {selectedDays} = this.state;
        if (selected) {
            const selectedIndex = selectedDays.findIndex(selectedDay =>
                DateUtils.isSameDay(selectedDay, day)
            );
            selectedDays.splice(selectedIndex, 1);
        } else {
            selectedDays.push(day);
        }

        selectedDays.sort(function compare(a, b) {
            var dateA = new Date(a.moment);
            var dateB = new Date(b.moment);
            return dateA - dateB;
        });


        this.setState({selectedDays});
    }

    arrayCheck(val, data) {
        return data.some(item => val.name === item.name);
    }

    _showAllDays() {
        this.setState({
            showAll: true
        })
    }

    calculateTotalHours(values) {

        let totalHours = 0;

        values.map((item) => {
            totalHours += parseFloat(item.hours)
        })

        return totalHours
    }

    _reformatData(values) {
        let formattedObject = {}
        formattedObject.type = values.activeType.value
        formattedObject.description = values.description
        formattedObject.dates = []

        this.state.selectedDays.map((item) => {
            const startTime = -2;

            if (item.hours > 0) {

                let formattedDate = {}

                formattedDate.date_start = item.moment.clone().add(startTime, 'hours').toDate()
                formattedDate.date_end = item.moment.clone().add(startTime + item.hours, 'hours').toDate()

                formattedObject.dates.push(formattedDate)

            }
        })

        return formattedObject;
    }


    _handleSubmit(values) {
        const formattedData = this._reformatData(values);
        this.props.dispatch(DayOffAction.registerDayOff(formattedData));
    }

    render() {
        moment.locale('nl');
        const now = new moment()
        const year = now.format('YYYY')
        const lastYear = now.clone().subtract(1, 'year').format('YYYY')
        const today = new Date();

        let initialValues = {
            types: [
                {label: 'Regulier', value: 'regular'},
                {label: 'Ziekte', value: 'sick'},
                {label: 'Onbetaald', value: 'unpaid'},
                {label: 'Bijzonder', value: 'special'},
                {label: 'Zorgverlof', value: 'care_leave'},
            ],
            activeType: false,
            dateRangeData: [{
                activeType: false,
                startDate: new Date(),
                endDate: new Date(),
            }],
            datePickData: [],
            dateRangeDates: false,
            description: null

        }

        let maxShownDays = 4;

        if (this.state.showAll) {
            maxShownDays = 999
        }

        if (!this.props.formSubmitting) {

            return (
                <>
                    <div className="c-time">
                        <header className="c-time-header">
                            <h1>
                                <time dateTime="1589414400000">{new moment().format('dddd D MMMM YYYY')}</time>
                            </h1>
                        </header>

                        <Formik
                            initialValues={initialValues}
                            onSubmit={(values) => {
                                this._handleSubmit(values)
                            }}
                        >
                            {({values, setFieldValue, handleSubmit, errors}) => (
                                <form onSubmit={handleSubmit} className="u-float-padding">

                                    <div className="o-container o-container--secondary">
                                        <div className="c-time-form">
                                            {!values.activeType &&
                                            <div
                                                className="c-time-form-row c-time-form-row--half c-time-form-row--work">
                                                <label htmlFor="">Soort verlof</label>
                                                <div className="c-work-select-wrap c-work-select-wrap--whole">
                                                    {values.types.map((item, key) => (
                                                        <div className="c-time-form-group"
                                                             onClick={this._setActiveType.bind(this, key, values, setFieldValue)}>
                                                            <div className="c-work-select"><span>{item.label}</span>
                                                            </div>
                                                        </div>

                                                    ))}
                                                </div>
                                            </div>
                                            }
                                            {values.activeType &&
                                            <>

                                                <div
                                                    className="c-time-form-row c-time-form-row--half c-time-form-row--work">
                                                    <label htmlFor="">Soort verlof</label>
                                                    <div className="c-work-select-wrap c-work-select-wrap--selected">
                                                        <div className="c-time-form-group">
                                                            <div className="c-work-select is-active">
                                                                <span>{values.activeType.label}</span>
                                                            </div>
                                                            <a className="c-time-form__select"
                                                               onClick={this._unsetActiveType.bind(this, setFieldValue)}><span>Wijzigen</span>
                                                            </a>
                                                        </div>
                                                    </div>
                                                </div>


                                                <hr className="divider"/>
                                                {this.props.totals &&
                                                <div className="c-time-form-row">

                                                    <div className="c-stat-card c-stat-card--light">
                                                        <div className="c-stat-card__single c-stat-card__single--sum">
                                                            <p>Meegenomen uren {lastYear} (+ geldig
                                                                tot 1 juli)</p>
                                                            <span>{this.props.totals.available_last_year}</span></div>
                                                        <div className="c-stat-card__single c-stat-card__single--sum">
                                                            <p>Meegenomen uren
                                                                bovenwettelijk</p>
                                                            <span>{this.props.totals.legal_hours}</span></div>
                                                        {1 === 0 &&
                                                        <>
                                                            <div
                                                                className="c-stat-card__single c-stat-card__single--sum">
                                                                <p>Totaal jaarrecht verlof uren {year}</p>
                                                                <span>nnb</span></div>
                                                        </>
                                                        }
                                                        <div className="c-stat-card__single  c-stat-card__single--sum">
                                                            <p>Totaal beschikbaar in {year}</p>
                                                            <span>{this.props.totals.available_this_year}</span>
                                                        </div>
                                                        <div className="c-stat-card__single "><p>Verlof uren
                                                            beschikbaar</p>
                                                            <span>{this.props.totals.available}</span>
                                                        </div>

                                                    </div>
                                                </div>

                                                }

                                                <hr className="divider"/>

                                                <DayPicker
                                                    selectedDays={this.state.selectedDays}
                                                    onDayClick={this.handleDayClick}
                                                    months={MONTHS}
                                                    weekdaysLong={WEEKDAYS_LONG}
                                                    weekdaysShort={WEEKDAYS_SHORT}
                                                    firstDayOfWeek={1}
                                                />

                                                {this.state.selectedDays && this.state.selectedDays.length > 0 &&
                                                <>
                                                    <div className="c-time-form-row">
                                                        <div className="c-hours-selector" style={{minWidth: '100%'}}>
                                                            {values.activeType.value !== 'sick' &&
                                                            <p>
                                                                Wil je geen hele dag vrij? Wijzig hieronder de op te
                                                                nemen
                                                                uren
                                                                van
                                                                de
                                                                zojuist aangevinkte dagen:
                                                            </p>
                                                            }

                                                            {this.state.selectedDays.slice(0, maxShownDays).map((item, key) => {
                                                                return (
                                                                    <div className="c-hours-selector__single">
                                                                        <div>
                                                                            <svg width="96px" height="98px"
                                                                                 viewBox="0 0 96 98"
                                                                                 version="1.1"
                                                                            >
                                                                                <g id="Page-1" stroke="none"
                                                                                   stroke-width="1"
                                                                                   fill="none"
                                                                                   fill-rule="evenodd">
                                                                                    <g id="calendar" fill="#51B0AA"
                                                                                       fill-rule="nonzero">
                                                                                        <path
                                                                                            d="M34.5708031,53.8622048 C34.5708031,52.6392047 33.578803,51.6484046 32.3548029,51.6484046 L24.6218022,51.6484046 C23.3992021,51.6484046 22.407002,52.6392047 22.407002,53.8622048 L22.407002,61.5942055 C22.407002,62.8188056 23.3992021,63.8100057 24.6218022,63.8100057 L32.3548029,63.8100057 C33.578803,63.8100057 34.5708031,62.8188056 34.5708031,61.5942055 L34.5708031,53.8622048 L34.5708031,53.8622048 Z"
                                                                                            id="Path"/>
                                                                                        <path
                                                                                            d="M53.8966048,53.8622048 C53.8966048,52.6392047 52.9044047,51.6484046 51.6828046,51.6484046 L43.9488039,51.6484046 C42.7262038,51.6484046 41.7340037,52.6392047 41.7340037,53.8622048 L41.7340037,61.5942055 C41.7340037,62.8188056 42.7262038,63.8100057 43.9488039,63.8100057 L51.6828046,63.8100057 C52.9044047,63.8100057 53.8966048,62.8188056 53.8966048,61.5942055 L53.8966048,53.8622048 Z"
                                                                                            id="Path"/>
                                                                                        <path
                                                                                            d="M73.2234065,53.8622048 C73.2234065,52.6392047 72.2312065,51.6484046 71.0086063,51.6484046 L63.2756057,51.6484046 C62.0516055,51.6484046 61.0596055,52.6392047 61.0596055,53.8622048 L61.0596055,61.5942055 C61.0596055,62.8188056 62.0516055,63.8100057 63.2756057,63.8100057 L71.0086063,63.8100057 C72.2312065,63.8100057 73.2234065,62.8188056 73.2234065,61.5942055 L73.2234065,53.8622048 Z"
                                                                                            id="Path"/>
                                                                                        <path
                                                                                            d="M34.5708031,73.1900065 C34.5708031,71.9650064 33.578803,70.9750063 32.3548029,70.9750063 L24.6218022,70.9750063 C23.3992021,70.9750063 22.407002,71.9650064 22.407002,73.1900065 L22.407002,80.9206072 C22.407002,82.1444073 23.3992021,83.1354074 24.6218022,83.1354074 L32.3548029,83.1354074 C33.578803,83.1354074 34.5708031,82.1442073 34.5708031,80.9206072 L34.5708031,73.1900065 L34.5708031,73.1900065 Z"
                                                                                            id="Path"/>
                                                                                        <path
                                                                                            d="M53.8966048,73.1900065 C53.8966048,71.9650064 52.9044047,70.9750063 51.6828046,70.9750063 L43.9488039,70.9750063 C42.7262038,70.9750063 41.7340037,71.9650064 41.7340037,73.1900065 L41.7340037,80.9206072 C41.7340037,82.1444073 42.7262038,83.1354074 43.9488039,83.1354074 L51.6828046,83.1354074 C52.9044047,83.1354074 53.8966048,82.1442073 53.8966048,80.9206072 L53.8966048,73.1900065 Z"
                                                                                            id="Path"/>
                                                                                        <path
                                                                                            d="M73.2234065,73.1900065 C73.2234065,71.9650064 72.2312065,70.9750063 71.0096063,70.9750063 L63.2756057,70.9750063 C62.0516055,70.9750063 61.0596055,71.9650064 61.0596055,73.1900065 L61.0596055,80.9206072 C61.0596055,82.1444073 62.0516055,83.1354074 63.2756057,83.1354074 L71.0096063,83.1354074 C72.2312065,83.1354074 73.2234065,82.1442073 73.2234065,80.9206072 L73.2234065,73.1900065 L73.2234065,73.1900065 Z"
                                                                                            id="Path"/>
                                                                                        <path
                                                                                            d="M87.0508078,10.870801 L87.0508078,22.680802 C87.0508078,28.0188025 82.7204074,32.3204029 77.3832069,32.3204029 L71.2846064,32.3204029 C65.9470059,32.3204029 61.5592055,28.0188025 61.5592055,22.680802 L61.5592055,10.828401 L34.071203,10.828401 L34.071203,22.680802 C34.071203,28.0188025 29.6836027,32.3204029 24.3468022,32.3204029 L18.2470016,32.3204029 C12.9100012,32.3204029 8.57980077,28.0188025 8.57980077,22.680802 L8.57980077,10.870801 C3.91520035,11.011401 0.0822000073,14.8712013 0.0822000073,19.6154018 L0.0822000073,88.8276079 C0.0822000073,93.6610084 3.99980036,97.6306087 8.83320079,97.6306087 L86.7972078,97.6306087 C91.6234082,97.6306087 95.5482085,93.6528084 95.5482085,88.8276079 L95.5482085,19.6154018 C95.5482085,14.8712013 91.7154082,11.011401 87.0508078,10.870801 Z M84.2182075,84.5176076 C84.2182075,86.6064077 82.5246074,88.3010079 80.4350072,88.3010079 L15.0288013,88.3010079 C12.9392012,88.3010079 11.245601,86.6064077 11.245601,84.5176076 L11.245601,48.7670044 C11.245601,46.6774042 12.9390012,44.982804 15.0288013,44.982804 L80.4348072,44.982804 C82.5244074,44.982804 84.2182075,46.6774042 84.2182075,48.7670044 L84.2182075,84.5176076 L84.2182075,84.5176076 Z"
                                                                                            id="Shape"/>
                                                                                        <path
                                                                                            d="M18.2256016,25.9890023 L24.2580022,25.9890023 C26.0890023,25.9890023 27.5736025,24.5066022 27.5736025,22.675602 L27.5736025,3.3146003 C27.5736025,1.48340013 26.0890023,0 24.2580022,0 L18.2256016,0 C16.3944015,0 14.9100013,1.48340013 14.9100013,3.3146003 L14.9100013,22.675602 C14.9100013,24.5066022 16.3944015,25.9890023 18.2256016,25.9890023 Z"
                                                                                            id="Path"/>
                                                                                        <path
                                                                                            d="M71.2070064,25.9890023 L77.2394069,25.9890023 C79.0692071,25.9890023 80.5538072,24.5066022 80.5538072,22.675602 L80.5538072,3.3146003 C80.5540072,1.48340013 79.0694071,0 77.2394069,0 L71.2070064,0 C69.3762062,0 67.8916061,1.48340013 67.8916061,3.3146003 L67.8916061,22.675602 C67.8916061,24.5066022 69.3762062,25.9890023 71.2070064,25.9890023 Z"
                                                                                            id="Path"/>
                                                                                    </g>
                                                                                </g>
                                                                            </svg>
                                                                            <p>{item.date}</p>
                                                                        </div>
                                                                        {!item.edit &&
                                                                        <div>
                                                                            <small>{item.hours} uur</small>
                                                                            <button
                                                                                onClick={this._onClickEditHour.bind(this, key, this.state.selectedDays)}>
                                                                                <span>Wijzigen</span>
                                                                            </button>
                                                                        </div>
                                                                        }
                                                                        {item.edit &&
                                                                        <div>
                                                                            <select
                                                                                onChange={e => this._setTempHours(e.target.value, key, this.state.selectedDays)}>
                                                                                {Array.from(Array(17), (e, i) => {

                                                                                    let selected = item.tempHours === i / 2

                                                                                    if (i > 0) {
                                                                                        return (
                                                                                            <option selected={selected}
                                                                                                    value={i / 2}>{i / 2}</option>
                                                                                        )
                                                                                    }
                                                                                })}
                                                                            </select>
                                                                            <button
                                                                                onClick={this._setHours.bind(this, key, this.state.selectedDays)}>
                                                                                <span>Bevestigen</span>
                                                                            </button>
                                                                        </div>
                                                                        }
                                                                    </div>
                                                                )
                                                            })}
                                                            {this.state.selectedDays.length > maxShownDays &&
                                                            <div className="c-hours-selector__show-all">
                                                                <button onClick={this._showAllDays.bind(this)}>
                                                                    <span>Toon alle dagen ({this.state.selectedDays.length})</span>
                                                                </button>
                                                            </div>
                                                            }
                                                        </div>
                                                    </div>


                                                    {this.state.selectedDays.length > 0 &&
                                                    <>
                                                        <hr className="divider"/>
                                                        <div className="c-time-form-row">
                                                            <div className="c-totals">
                                                                <p><strong>Totalen</strong></p>
                                                                <div className="c-totals__wrap">
                                                                    <div className="c-totals__single">
                                                                        {this.state.selectedDays.length > 0 &&
                                                                        <div>
                                                                            <span>{moment(this.state.selectedDays[0]).format('DD / MM / YYYY')}</span>

                                                                            {this.state.selectedDays.length > 1 &&
                                                                            <>
                                                                                <figure>-</figure>
                                                                                <span>{moment(this.state.selectedDays[this.state.selectedDays.length - 1]).format('DD / MM / YYYY')}</span>
                                                                            </>
                                                                            }

                                                                        </div>
                                                                        }
                                                                        <p>{this.calculateTotalHours(this.state.selectedDays)} uur</p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>

                                                    </>
                                                    }
                                                </>
                                                }
                                            </>
                                            }

                                            {this.state.selectedDays.length > 0 && values.activeType &&
                                            <>
                                                <hr className="divider"/>
                                                <div className="c-time-form-row">
                                                    <div className="c-time-form-group">
                                                        <label htmlFor="">Opmerking / notitie</label>
                                                        <div className="c-time-note">
                                                            <Field
                                                                name="description"
                                                                component="textarea"
                                                                rows="5"
                                                            />
                                                        </div>
                                                    </div>
                                                </div>

                                            </>
                                            }
                                        </div>
                                    </div>

                                    {this.state.selectedDays && this.state.selectedDays.length > 0 && values.activeType &&
                                    <div className="c-floating-btn">
                                        <div className="o-container o-container--secondary">
                                            <button type={'submit'}>Verlof aanvragen</button>
                                        </div>
                                    </div>
                                    }

                                </form>
                            )}

                        </Formik>

                    </div>
                </>
            )
        } else {
            return (
                <div className={'c-timetracking-loader'}>
                    <Loader
                        type="Bars"
                        color="#51B0AA"
                        height={50}
                        width={50}
                        timeout={10000}
                    />
                </div>
            )
        }
    }
}

export default withRouter(connect(mapStateToProps)(DaysOffAdd));
