const MapDayOffTypes = (typeCode) => {

    const mapping = {
        regular: 'Regulier',
        sick: 'Ziekte',
        unpaid: 'Onbetaald',
        special: 'Bijzonder',
        care_leave: 'Zorg'
    }

    return mapping[typeCode];
}

export default MapDayOffTypes