import ActionUtility from '../../utilities/ActionUtility'
import AuthenticationEffect from "./AuthenticationEffect";

export default class AuthenticationAction {
    static REQUEST_LOGIN = 'AuthenticationAction.REQUEST_LOGIN';
    static REQUEST_OTP = 'AuthenticationAction.OTP'
    static LOGOUT = 'AuthenticationAction.LOGOUT'
    static RESET_PASSWORD = 'AuthenticationAction.RESET_PASSWORD'

    static requestLogin(values) {
        return async (dispatch, getState) => {
            await ActionUtility.createThunkEffect(dispatch, AuthenticationAction.REQUEST_LOGIN, AuthenticationEffect.requestLogin, values);
        };
    }

    static requestOTP(values) {
        return async (dispatch, getState) => {
            await ActionUtility.createThunkEffect(dispatch, AuthenticationAction.REQUEST_OTP, AuthenticationEffect.requestOTP, values);
        };
    }

    static logout() {
        return ActionUtility.createAction(AuthenticationAction.LOGOUT, {
            loggedOut: true
        });
    }

    static resetPassword(username) {
        return async (dispatch, getState) => {
            await ActionUtility.createThunkEffect(dispatch, AuthenticationAction.RESET_PASSWORD, AuthenticationEffect.resetPassword, username);
        };
    }
}