import React from 'react'
import InlineSVG from 'svg-inline-react';

const DeclinedData = `
<svg width="102px" height="102px" viewBox="0 0 102 102" version="1.1">
    <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
        <g id="Group-29">
            <circle id="Oval-Copy-3" fill="#FF7F7F" cx="51" cy="51" r="51"></circle>
            <path
                d="M56.6315789,19 L56.631,46.368 L84,46.3684211 L84,56.6315789 L56.631,56.631 L56.6315789,84 L46.3684211,84 L46.368,56.631 L19,56.6315789 L19,46.3684211 L46.368,46.368 L46.3684211,19 L56.6315789,19 Z"
                id="Combined-Shape" fill="#FFFFFF"
                transform="translate(51.500000, 51.500000) rotate(45.000000) translate(-51.500000, -51.500000) "></path>
        </g>
    </g>
</svg>
`

const Declined = (props) => {

    return (
        <InlineSVG src={DeclinedData}/>
    )

}

export default Declined