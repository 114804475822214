import {BaseModel} from 'sjs-base-model';
import CalculateHourDifference from '../../../utilities/CalculateHourDifference'


export default class TimeTrackingModel extends BaseModel {
    id = 0;
    start_time = '00:00:00';
    end_time = '00:00:00';
    pause_time = '00:00:00';
    kilometers = 0;
    business_kilometers = 0;
    commuting_kilometers = 0;
    total_kilometers = 0;

    type = {
        value: 'regular'
    }
    customer_id = false;
    time = '00:00:00';
    calls = 0;
    status = false;
    visits = 0
    date = new Date()
    clocks = false
    kilometer_type = false
    remarks: null

    constructor(data, timeTrackObjects) {
        super();
        this.update(data, timeTrackObjects);
    }

    totalKmCalc = (data) => {
        let km = data.business_kilometers + data.commuting_kilometers

        if (data.visits) {
            data.visits.map((visit) => {
                km += visit.business_kilometers;
                km += visit.commuting_kilometers;
            })
        }

        return km
    }

    update(data) {


        this.id = data.id
        this.start_time = data.start_time
        this.end_time = data.end_time
        this.pause_time = data.pause_time
        this.kilometers = data.kilometers
        this.status = data.status
        this.type = data.type
        this.customer_id = data.customer_id

        this.business_kilometers = data.business_kilometers
        this.commuting_kilometers = data.commuting_kilometers

        this.total_kilometers = this.totalKmCalc(data)

        this.visits = data.visits
        this.remarks = data.remarks

        this.time = CalculateHourDifference(this.start_time, this.end_time, this.pause_time)

        if (data.calls) {
            this.calls = data.calls
        }

        if (data.clocks) {
            this.clocks = data.clocks
        }

        this.date = new Date(data.date)

    }
}