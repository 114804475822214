import {BaseModel} from 'sjs-base-model';
import TimeTrackingModel from './TimeTrackingModel'
import MinutesToHours from '../../../utilities/MinutesToHours'

export default class DayModel extends BaseModel {
    day = ''
    timeTracking = false;
    totalKm = 15; // Not in api
    totalPhoneCalls = 3; // Not in api
    totalVisits = 3; // Not in api
    totalTime = 0; // Not in api

    constructor(data, timeTrackings) {
        super();
        this.update(data, timeTrackings);
    }

    totalKmCalc = (timeTrackings) => {
        let km = 0


        timeTrackings.map((timeTracking) => {
            if (timeTracking.total_kilometers) {
                km = km + parseInt(timeTracking.total_kilometers)
            }
        })

        return km
    }

    totalVisitsCalc = (timeTrackings) => {
        let visits = 0
        timeTrackings.map((timeTracking) => {
            if (timeTracking.visits) {
                visits = visits + parseInt(timeTracking.visits.length)
            }
        })

        return visits
    }

    totalCallsCalc = (timeTrackings) => {
        let phoneCalls = 0
        timeTrackings.map((timeTracking) => {
            if (timeTracking.calls) {
                phoneCalls = phoneCalls + parseInt(timeTracking.calls.length)
            }
        })

        return phoneCalls
    }

    totalTimeCalc = (timeTrackings) => {
        let hours = 0;
        let minutes = 0;
        const zeroPad = (num, places) => String(num).padStart(places, '0')

        timeTrackings.map((timeTracking) => {
            const time = timeTracking.time
            const timeSplit = time.split(":")
            hours += parseInt(timeSplit[0])
            minutes += parseInt(timeSplit[1])
        })

        const minutesToHours = MinutesToHours(minutes)
        hours += minutesToHours.hours
        minutes = minutesToHours.minutes

        return zeroPad(hours, 2) + ':' + zeroPad(minutes, 2)
    }

    update(data, timeTrackings) {
        if (timeTrackings) {
            this.timeTracking = timeTrackings
            this.day = data.day.day;
            this.totalKm = this.totalKmCalc(timeTrackings)
            this.totalVisits = this.totalVisitsCalc(timeTrackings)
            this.totalPhoneCalls = this.totalCallsCalc(timeTrackings)
            this.totalTime = this.totalTimeCalc(timeTrackings)
        } else {
            this.day = data.day;

        }


    }
}