const WorkTypeByValue = (value) => {

    switch(value){
        case 'regular':
            return 'Regulier'
        case 'training':
            return 'Scholing'
        case('account'):
            return 'Account'
        case('task'):
            return  'Opdracht'
        default:
            return 'Beschikbaarheidsdienst'
    }


}

export default WorkTypeByValue