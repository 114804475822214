import RouteEnum from '../../constants/RouteEnum'
import cookie from 'react-cookies'

export default class NotificationEffect {

    static async requestNotificationList() {
        return await fetch(RouteEnum.API + '/api/notification/list', {
            method: 'GET',
            headers: {
                'Authorization': 'Bearer ' + cookie.load('token'),
                'Accept': 'application/json',
            },
        }).then((response) => {
            return response.json()
        }).then((response) => {
            if (response.status === 401) {
                cookie.remove('token', {path: '/'})
            }

            return {
                list: response.parameters.notifications,
            }

        })
    }

    static async requestNotificationSetSeen(id) {
        return await fetch(RouteEnum.API + '/api/notification/set-seen?id=' + id, {
            method: 'GET',
            headers: {
                'Authorization': 'Bearer ' + cookie.load('token'),
                'Accept': 'application/json',
            },
        }).then((response) => {
            return response.json()
        }).then((response) => {
            if (response.status === 401) {
                cookie.remove('token', {path: '/'})
            }
        })
    }
}
