import ProductivityAction from './ProductivityAction'

export default class ProductivityReducer {

    static initialState = {
        Productivity: false
    };

    static reducer(state = ProductivityReducer.initialState, action) {

        switch (action.type) {

            case ProductivityAction.REQUEST_INFO:
                return {
                    ...state,
                    Productivity: false
                };

            case ProductivityAction.REQUEST_INFO + '_FINISHED':
                return {
                    ...state,
                    Productivity: action.payload
                }

            default:
                return state;
        }
    }

}
