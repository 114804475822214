import moment from 'moment';
import RouteEnum from '../../constants/RouteEnum'
import cookie from 'react-cookies'

export default class TimeTrackingEffect {

    static async registerDayOff(values) {
        return await fetch(RouteEnum.API + '/api/leave/create', {
            method: 'POST',
            headers: {
                'Authorization': 'Bearer ' + cookie.load('token'),
                'Accept': 'application/json',
            },
            body: JSON.stringify(values)
        }).then((response) => {
                return response.json()
            }
        ).then((response) => {
            if (response.status === 401) {
                cookie.remove('token', {path: '/'})
            }
            return {
                redirectDayOff: true
            }
        }).catch(function (error) {
        })

    }

    static async requestDayOffOverview(values) {
        return await fetch(RouteEnum.API + '/api/leave/list', {
            method: 'GET',
            headers: {
                'Authorization': 'Bearer ' + cookie.load('token'),
                'Accept': 'application/json',
            },
            body: JSON.stringify(values)
        }).then((response) => {
                return response.json()
            }
        ).then((response) => {
            if (response.status === 401) {
                cookie.remove('token', {path: '/'})
            }
            const years = {}
            if (response.status === 200) {

                response.parameters.leaves.map((item) => {
                    const year = moment(item.items[0].date_start).format('YYYY')

                    item.isFuture = false

                    if (moment(item.items[0].date_start) > moment()) item.isFuture = true

                    years[year] = years[year] || [];
                    years[year].push(item)

                })

            }
            return {
                error: false,
                errorMessage: false,
                daysOff: years,
                redirectDayOff: false
            }
        }).catch(function (error) {
            //console.log(error)
        })
    }

    static async requestTotals(values) {
        return await fetch(RouteEnum.API + '/api/employee/available-hours', {
            method: 'GET',
            headers: {
                'Authorization': 'Bearer ' + cookie.load('token'),
                'Accept': 'application/json',
            },
            body: JSON.stringify(values)
        }).then((response) => {
                return response.json()
            }
        ).then((response) => {
            if (response.status === 401) {
                cookie.remove('token', {path: '/'})
            }

            if (response.status === 200) {
                return {
                    error: false,
                    errorMessage: false,
                    totals: response.parameters.totals
                }
            }else{
                return {
                    error: false,
                    errorMessage: false,
                    totals: false
                }
            }

        }).catch(function (error) {
            //console.log(error)
        })

    }

    static async requestCreditData(values) {
        return await fetch(RouteEnum.API + '/api/employee/credit-data', {
            method: 'GET',
            headers: {
                'Authorization': 'Bearer ' + cookie.load('token'),
                'Accept': 'application/json',
            },
            body: JSON.stringify(values)
        }).then((response) => {
            return response.json()
        }).then((response) => {
            if (response.status === 401) {
                cookie.remove('token', {path: '/'})
            }

            const now = new moment()
            const year = parseInt(now.format('YYYY'))
            let currentYear = null

            if (response.status === 200) {
                response.parameters.credits.map((item) => {
                    if (item.year === year) {
                        currentYear = item
                    }
                })
            }

            return {
                error: false,
                errorMessage: false,
                creditData: response.parameters.credits,
                creditDataCurrentYear: currentYear
            }

        })
    }


    static async removeDayOff(id) {
        return await fetch(RouteEnum.API + '/api/leave/cancel', {
            method: 'POST',
            headers: {
                'Authorization': 'Bearer ' + cookie.load('token'),
                'Accept': 'application/json',
            },
            body: JSON.stringify({leave_id: id})
        }).then((response) => {
                return response.json()
            }
        ).then((response) => {
            if (response.status === 401) {
                cookie.remove('token', {path: '/'})
            }
            return {
                redirectDayOff: true
            }
        }).catch(function (error) {
            console.log(error)
        })

    }

}
