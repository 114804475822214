import ClientModel from './models/ClientModel'
import cookie from 'react-cookies'
import RouteEnum from '../../constants/RouteEnum'

export default class ClientEffect {

    static async requestClient(token) {
        const clientData = await fetch(RouteEnum.API + '/api/customer/list', {
            method: 'GET',
            headers: {
                'Authorization': 'Bearer ' + cookie.load('token'),
                'Accept': 'application/json',
            },
        }).then((response) => {
                return response.json()
            }
        ).then((response) => {
            if (response.status === 401) {
                cookie.remove('token', {path: '/'})
            }

            return response;

        }).catch((error) => {
            cookie.remove('token', {path: '/'})
        })


        return clientData.parameters.customers.map((client) => {
            return new ClientModel(client)
        })
    }
}
