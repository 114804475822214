const MinutesToHours = (n) => {

    const num = n;
    const hours = (num / 60);
    const rhours = Math.floor(hours);
    const minutes = (hours - rhours) * 60;
    const rminutes = Math.round(minutes);
    const data = {
        hours: rhours,
        minutes: rminutes
    }

    return data

}

export default MinutesToHours