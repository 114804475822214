import React from 'react'
import {Link} from "react-router-dom";
import RouteEnum from "../../../constants/RouteEnum";

class ClientRuleSingle extends React.Component {

    constructor(props) {
        super(props)
    }

    render() {

        return (

            <Link to={RouteEnum.ClientRulesDetail + '/' + this.props.id} className={'c-client-rules__single'}>
                <header>
                    <div>
                        {!!this.props.title &&
                        <h2>{this.props.title}</h2>
                        }
                        {this.props.is_new &&
                        <div className={'c-client-rules__single__new'}>Nieuw</div>
                        }
                    </div>
                    {!!this.props.end_date &&
                    <small>Tot {this.props.end_date}</small>
                    }
                </header>
                {!!this.props.projects &&
                <div className={'c-client-rules__single__projects'}>
                    {Object.values(this.props.projects).map((project) => (
                        <div>{project.title}</div>
                    ))}
                </div>
                }
            </Link>

        );
    }
}

export default ClientRuleSingle;
