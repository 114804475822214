import React from 'react'
import './SwitchMonth.scss'
import Moment from 'react-moment';
import 'moment/locale/nl';
import CheckNextMonth from '../../../../../utilities/ShowNextMonth'
import { Online } from "react-detect-offline";

class SwitchMonth extends React.Component {

    render() {
        const showNextMonth = CheckNextMonth(this.props.date)

        return (
            <div className={'c-switch-month'}>
                <Online>
                    <button className={'c-switch-month-arrow c-switch-month-arrow--back'}
                            onClick={this.props.clickChangeMonth.bind(this, -1)}>
                        <i className={'icon-arrow-long icon-arrow-long--back'}/>
                    </button>
                </Online>
                <div className="c-switch-month-current">
                                <span>
                                    <Moment format="MMMM YYYY">{this.props.date}</Moment></span>
                </div>
                {showNextMonth &&
                <Online>
                    <button className={'c-switch-month-arrow c-switch-month-arrow--next'}
                            onClick={this.props.clickChangeMonth.bind(this, 1)}>
                        <i className={'icon-arrow-long'}/>
                    </button>
                </Online>
                }
            </div>
        )

    }
}

export default SwitchMonth