import React from 'react';
import Moment from 'react-moment';
import 'moment/locale/nl';
import {
    useParams
} from "react-router-dom";

const TimeTrackingDate = (props) => {
    let {date} = useParams()
    if (!props.useParams) {
        date = props.data.date
    }

    return (
        <Moment locale="nl" format="dddd D MMMM YYYY">{new Date(date)}</Moment>
    )
}


export default TimeTrackingDate
