import ActionUtility from '../../utilities/ActionUtility'
import ProductivityEffect from './ProductivityEffect'

export default class ProductivityAction {
    static REQUEST_INFO = 'ProductivityAction.REQUEST_INFO'

    static requestProductivityInfo() {

        return async (dispatch) => {
            await ActionUtility.createThunkEffect(dispatch, ProductivityAction.REQUEST_INFO, ProductivityEffect.requestProductivityInfo);
        };

    }

}
