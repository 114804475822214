import ActionUtility from '../../utilities/ActionUtility'
import TimeTrackingEffect from './TimetrackingEffect'
import GetWeekDates from './utilities/GetWeekDates'

export default class TimeTrackingAction {
    static REQUEST_WEEKLY_TIME_TRACKING_OVERVIEW = 'TimeTrackingAction.REQUEST_WEEKLY_TIME_TRACKING_OVERVIEW';

    static REQUEST_TIME_TRACKING = 'TimeTrackingAction.REQUEST_TIME_TRACKING';

    static SET_ACTIVE_TIME_TRACKING_OPTIONS = 'TimeTrackingAction.SET_ACTIVE_TIME_TRACKING_OPTIONS';

    static CHANGE_MONTH = 'TimeTrackingAction.CHANGE_MONTH';
    static RESET_MONTH = 'TimeTrackingAction.RESET_MONTH';

    static REGISTER_TIME_TRACKING = 'TimeTrackingAction.REGISTER_TIME_TRACKING'
    static EDIT_TIME_TRACKING = 'TimeTrackingAction.EDIT_TIME_TRACKING'

    static REQUEST_TIME_TRACKING_DAY = 'TimeTrackingAction.REQUEST_TIME_TRACKING_DAY'

    static REMOVE_TIME_TRACKING = 'TimeTrackingAction.REMOVE_TIME_TRACKING'

    static DISPLAY_TOTALS = 'TimeTrackingAction.DISPLAY_TOTALS'

    static UNSET_TIME_TRACKING = 'TimeTrackingAction.UNSET_TIME_TRACKING'

    static setActiveTimeTrackingOptions(sjsId) {
        return ActionUtility.createAction(TimeTrackingAction.SET_ACTIVE_TIME_TRACKING_OPTIONS, {
            sjsId: sjsId
        });
    }

    static changeMonth(direction){
        return ActionUtility.createAction(TimeTrackingAction.CHANGE_MONTH, {
            direction: direction
        });
    }

    static resetMonth(){
        return ActionUtility.createAction(TimeTrackingAction.RESET_MONTH);
    }

    static requestTimeTracking(timeTrackingId) {
        return async (dispatch) => {
            await ActionUtility.createThunkEffect(dispatch, TimeTrackingAction.REQUEST_TIME_TRACKING, TimeTrackingEffect.requestTimeTracking, timeTrackingId);
        };
    }

    static registerTimeTracking(values) {
        return async (dispatch) => {
            await ActionUtility.createThunkEffect(dispatch, TimeTrackingAction.REGISTER_TIME_TRACKING, TimeTrackingEffect.registerTimeTracking, values);
        };
    }

    static editTimeTracking(values) {
        return async (dispatch) => {
            await ActionUtility.createThunkEffect(dispatch, TimeTrackingAction.EDIT_TIME_TRACKING, TimeTrackingEffect.editTimeTracking, values);
        };
    }

    static requestWeeklyTimeTrackingOverview() {
        return async (dispatch, getState) => {

            const weeks = GetWeekDates.getMonthDaysByWeek(getState().timeTracking.date.getMonth(), getState().timeTracking.date.getFullYear())

            await ActionUtility.createThunkEffect(dispatch, TimeTrackingAction.REQUEST_WEEKLY_TIME_TRACKING_OVERVIEW, TimeTrackingEffect.requestWeeklyTimeTrackingOverview, weeks);
        };
    }

    static requestTimeTrackingDay(data) {
        return async (dispatch, getState) => {
            await ActionUtility.createThunkEffect(dispatch, TimeTrackingAction.REQUEST_TIME_TRACKING_DAY, TimeTrackingEffect.requestTimeTrackingDay, data);
        };
    }

    static removeTimeTracking(id) {
        return async (dispatch, getState) => {
            await ActionUtility.createThunkEffect(dispatch, TimeTrackingAction.REMOVE_TIME_TRACKING, TimeTrackingEffect.removeTimeTracking, id);
        };
    }

    static unsetTimeTracking(){
        return ActionUtility.createAction(TimeTrackingAction.UNSET_TIME_TRACKING);
    }

    static displayTotals(bool){
        return ActionUtility.createAction(TimeTrackingAction.DISPLAY_TOTALS);
    }
}