import {combineReducers} from 'redux';
import RequestingReducer from './requesting/RequestingReducer'
import TimeTrackingReducer from './timetracking/TimetrackingReducer';
import DayOffReducer from './dayOff/DayOffReducer';
import ClientReducer from './client/ClientReducer';
import AuthenticationReducer from './authentication/AuthenticationReducer'
import ServiceWorkerReducer from './serviceWorker/ServiceWorkerReducer'
import {connectRouter} from 'connected-react-router';
import ClientRulesReducer from "./clientRules/ClientRulesReducer";
import HappyCheckReducer from "./happyCheck/HappyCheckReducer";
import NotificationReducer from "./notification/NotificationReducer";
import ProductivityReducer from "./productivity/ProductivityReducer";



export default (history) => {
    const reducerMap = {
        serviceWorker: ServiceWorkerReducer.reducer,
        authentication: AuthenticationReducer.reducer,
        requesting: RequestingReducer.reducer,
        timeTracking: TimeTrackingReducer.reducer,
        dayOff: DayOffReducer.reducer,
        client: ClientReducer.reducer,
        clientRules: ClientRulesReducer.reducer,
        happyCheck: HappyCheckReducer.reducer,
        notification: NotificationReducer.reducer,
        productivity: ProductivityReducer.reducer,
        router: connectRouter(history)
    };

    return combineReducers(reducerMap);
};
