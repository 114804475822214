import React from 'react'
import Open from "../../../static/svg/Open";
import WorkType from "../../_components/WorkTypes/WorkType";
import {Formik, Field, FieldArray} from 'formik'
import {connect} from "react-redux";
import TimeTrackingDate from '../../_components/TimeTrackingDate'
import CalculateHourDifference from '../../../utilities/CalculateHourDifference'
import CalculateMinuteDifference from '../../../utilities/CalculateMinuteDifference'
import MapCallTimes from '../../../utilities/MapCallTimes'
import TimeTrackingAction from "../../../stores/timetracking/TimetrackingAction";
import RouteEnum from '../../../constants/RouteEnum'
import {withRouter} from "react-router";
import moment from 'moment'
import Loader from 'react-loader-spinner'

const mapStateToProps = (state, ownProps) => ({
    ...state.timeTracking,
    ...state.client
});

const validate = (values, props /* only available when using withFormik */) => {
    const errors = {};

    if (moment.duration(values.start_time) > moment.duration(values.end_time)) {
        errors.start_time = '*De start tijd kan niet later zijn dan de eind tijd';
    }

    if (values.calls_number_1 < 0) {
        errors.calls_number_1 = '*De waarde kan niet kleiner zijn dan 0'
    }

    if (values.calls_number_2 < 0) {
        errors.calls_number_2 = '*De waarde kan niet kleiner zijn dan 0'
    }


    if (values.calls_number_3 < 0) {
        errors.calls_number_3 = '*De waarde kan niet kleiner zijn dan 0'
    }

    if (values.calls_number_4 < 0) {
        errors.calls_number_4 = '*De waarde kan niet kleiner zijn dan 0'
    }

    if (values.visit_number < 0) {
        errors.visit_number = '*De waarde kan niet kleiner zijn dan 0'
    }

    if (values.business_kilometers < 0) {
        errors.business_kilometers = '*De waarde kan niet kleiner zijn dan 0'
    }

    if (values.commuting_kilometers < 0) {
        errors.commuting_kilometers = '*De waarde kan niet kleiner zijn dan 0'
    }

    const errorsArray = {}

    if (values.visits) {
        errorsArray.visits = {}
        values.visits.map((visit, key) => {
            if (values.visits[key]) {
                if (visit.start_time && visit.end_time && moment.duration(visit.start_time) > moment.duration(visit.end_time)) {
                    errorsArray.visits = {
                        ...errorsArray.visits,
                        [key]: {
                            ...errorsArray.visits[key],
                            start_time: '*De start tijd mag niet later zijn dan de eind tijd'
                        }
                    }
                }
                if (visit.business_kilometers < 0) {
                    errorsArray.visits = {
                        ...errorsArray.visits,
                        [key]: {
                            ...errorsArray.visits[key],
                            business_kilometers: '*De waarde mag niet kleiner zijn dan 0'
                        }
                    }
                }
                if (visit.commuting_kilometers < 0) {
                    errorsArray.visits = {
                        ...errorsArray.visits,
                        [key]: {
                            ...errorsArray.visits[key],
                            commuting_kilometers: '*De waarde mag niet kleiner zijn dan 0'
                        }
                    }
                }
            }
        })

        // Only add error if there are any errors inside visits
        if (errorsArray.visits && Object.keys(errorsArray.visits).length > 0) {
            errors.visits = errorsArray.visits
        }
    }

    return errors;
};

class TimeTrackingAdd extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            rerender: false,
            rerendered: false
        }
    }

    componentDidMount() {
        if (this.props.edit && this.props.currentTimeTracking === false) {
            const id = this.props.match.params.id;
            this.props.dispatch(TimeTrackingAction.requestTimeTracking(id));
        }

        let {redirect} = this.props;

        if (redirect === true) {
            this.props.history.push(RouteEnum.TimeTrackingOverview);
        }
    }

    _internalHourTypeChange(value, setFieldValue) {
        setFieldValue('type', value)
    }

    _onChangeCustomer(value, setFieldValue) {
        setFieldValue('customer_id', value)
        this._resetType(setFieldValue)
    }

    _resetType(setFieldValue) {
        setFieldValue('type', false)
    }

    _mapInternalTypes(key) {

        const types = {
            training: 'Scholing',
            account: 'Account',
            task: 'Opdracht'
        }

        return types[key]
    }

    render() {
        const internalIds = [29, 90];

        const internalHourTypes = {
            1: {
                value: 'training',
                title: 'Scholing'
            },
            2: {
                value: 'account',
                title: 'Account'
            },
            3: {
                value: 'task',
                title: 'Opdracht'
            }
        }

        let initialValues = {
            start_time: "08:00:00",
            end_time: "17:00:00",
            pause_time: "00:00",
            business_kilometers: false,
            commuting_kilometers: false,
            calls: [],
            visits: [],
            calls_number: false,
            calls_number_1: false,
            calls_number_2: false,
            calls_number_3: false,
            calls_number_4: false,
            visit_number: false,
            customer_id: "",
            remarks: null,
            type: false,
            workTypes: {
                1: {
                    value: 'regular',
                    title: 'Reguliere uren'
                },
                2: {
                    value: 'availability',
                    title: 'Bereikbaarheidsdienst'
                }
            }
        }
        // Manipulating data for edit
        if (this.props.edit && !this.state.rerendered) {

            let visitCount = false

            if (this.props.currentTimeTracking.visits) visitCount = this.props.currentTimeTracking.visits.length

            let callsCount = false


            initialValues = {
                ...initialValues,
                ...this.props.currentTimeTracking,
                date: moment(this.props.currentTimeTracking.date).format('YYYY-MM-DD'),
                visit_number: visitCount,
                calls_number: callsCount,
            }

            if (this.props.currentTimeTracking.calls) {
                const callTimesMapped = MapCallTimes(this.props.currentTimeTracking.calls)
                initialValues.calls_number_1 = callTimesMapped.calls_number_1
                initialValues.calls_number_2 = callTimesMapped.calls_number_2
                initialValues.calls_number_3 = callTimesMapped.calls_number_3
                initialValues.calls_number_4 = callTimesMapped.calls_number_4
            }

            if (initialValues.calls) {


                initialValues.calls.map((item, key) => {

                    // Replacement needed because not correlating data with the backend.. EG. data needs to be posted 22:00 and returned 22:00:00 .....

                    let startTime = item.start_time
                    let endTime = item.end_time
                    startTime = startTime.replace('00:00', '00')
                    endTime = startTime.replace('00:00', '00')
                    initialValues.calls[key].start_time = startTime

                    const minutes = CalculateMinuteDifference(item.start_time, item.end_time);
                    initialValues.calls[key].quarterAmount = parseInt(minutes) / 15;


                })

            }


        } else {

            // Manipulating data for new
            const date = this.props.match.params.date;
            initialValues = {
                ...initialValues,
                date: date
            }
        }

        // If it is the edit type, get the date from the existing time tracking
        let useParams = true
        if (this.props.edit) {
            useParams = false
        }

        const callTimes = [
            {value: '00:00', name: '00:00 - 06:00', id: 'time-1'},
            {value: '06:00', name: '06:00 - 18:00', id: 'time-2'},
            {value: '18:00', name: '18:00 - 22:00', id: 'time-3'},
            {value: '22:00', name: '22:00 - 00:00', id: 'time-4'}
        ]


        if (!this.props.formSubmitting && !this.props.timeTrackFetching) {

            return (
                <main className={'c-time'}>

                    <header className={'c-time-header'}>
                        <h1><TimeTrackingDate useParams={useParams} data={this.props.currentTimeTracking}/></h1>
                    </header>


                    <Formik
                        validate={validate}
                        initialValues={initialValues}
                        onSubmit={(values) => {

                            if (values.visits) {
                                let visits = []


                                values.visits.slice(0, parseInt(values.visit_number)).map((visit) => {
                                    let visitAdd = {
                                        ...visit,
                                        type: 'visit'
                                    }
                                    visits.push(visitAdd)
                                })

                                values.visits.slice(parseInt(values.visit_number), values.visits.length).map((visit) => {
                                    let visitAdd = {
                                        ...visit,
                                        type: 'visit',
                                        status: 'deleted'
                                    }
                                    visits.push(visitAdd)
                                })

                                values.visits = visits

                                if (values.type === 'regular') {
                                    values.visits = []
                                } else {
                                    values.pause_time = '00:00:00'

                                    //values.kilometers = false
                                }


                            }

                            if (values.type === 'availability') {

                                values.calls = []

                                // Setting the calls
                                if (values.calls_number_1) {
                                    [...Array(values.calls_number_1)].map((e, i) => {
                                            const call = {};
                                            call.type = 'call'
                                            call.start_time = '00:00'
                                            call.end_time = '00:15'
                                            values.calls.push(call)
                                        }
                                    )
                                }

                                if (values.calls_number_2) {
                                    [...Array(values.calls_number_2)].map((e, i) => {
                                            const call = {};
                                            call.type = 'call'
                                            call.start_time = '06:00'
                                            call.end_time = '06:15'
                                            values.calls.push(call)
                                        }
                                    )
                                }

                                if (values.calls_number_3) {
                                    [...Array(values.calls_number_3)].map((e, i) => {
                                            const call = {};
                                            call.type = 'call'
                                            call.start_time = '18:00'
                                            call.end_time = '18:15'
                                            values.calls.push(call)
                                        }
                                    )
                                }

                                if (values.calls_number_4) {
                                    [...Array(values.calls_number_4)].map((e, i) => {
                                            const call = {};
                                            call.type = 'call'
                                            call.start_time = '22:00'
                                            call.end_time = '22:15'
                                            values.calls.push(call)
                                        }
                                    )
                                }


                            }


                            if (this.props.edit) {
                                this.props.dispatch(TimeTrackingAction.editTimeTracking(values));
                            } else {
                                this.props.dispatch(TimeTrackingAction.registerTimeTracking(values));
                            }

                        }}
                    >
                        {({values, setFieldValue, handleSubmit, errors}) => (
                            <form onSubmit={handleSubmit}>
                                <div className={'o-container o-container--secondary'}>
                                    <div className={'c-time-form'}>
                                        <div className={'c-time-form-row'}>
                                            <div className={'c-time-form-group'}>
                                                <label htmlFor="">Selecteer klant</label>
                                                <div className="c-time-select">
                                                    <Field as="select" name={'customer_id'} required
                                                           onChange={e => this._onChangeCustomer(e.target.value, setFieldValue)}>
                                                        <option value="" defaultValue={true} disabled>-</option>
                                                        {this.props.clients && this.props.clients.map((client) => (
                                                            <option value={client.id}>{client.name}</option>
                                                        ))}
                                                    </Field>
                                                    <Open/>
                                                </div>
                                            </div>
                                        </div>

                                        {
                                            // CHECK IF IS NOT ZBVO ID
                                            !internalIds.includes(parseInt(values.customer_id)) && values.customer_id &&

                                            <div
                                                className={'c-time-form-row c-time-form-row--half c-time-form-row--work'}>
                                                <label htmlFor="">Type werkzaamheden</label>
                                                <div className={'c-work-select-wrap'}>
                                                    {Object.keys(values.workTypes).map((key) => {

                                                        const workTypeData = values.workTypes[key]
                                                        const active = values.type === workTypeData.value
                                                        return (
                                                            <WorkType
                                                                active={active}
                                                                id={key}
                                                                data={workTypeData}
                                                                onClick={setFieldValue}
                                                            />
                                                        )
                                                    })}
                                                </div>
                                            </div>

                                        }

                                        {
                                            internalIds.includes(parseInt(values.customer_id)) &&

                                            <>
                                                {!values.type &&
                                                <div
                                                    className="c-time-form-row c-time-form-row--half c-time-form-row--work">
                                                    <label htmlFor="">Type interne uren</label>
                                                    <div className="c-work-select-wrap c-work-select-wrap--whole">
                                                        {Object.keys(internalHourTypes).map((key) => {
                                                            const item = internalHourTypes[key]
                                                            const value = item.value
                                                            const title = item.title
                                                            return (
                                                                <div className="c-time-form-group"
                                                                     onClick={this._internalHourTypeChange.bind(this, value, setFieldValue)}>
                                                                    <div className="c-work-select"><span>{title}</span>
                                                                    </div>
                                                                </div>
                                                            )
                                                        })}
                                                    </div>
                                                </div>
                                                }

                                                {values.type &&

                                                <>
                                                    <div
                                                        className="c-time-form-row c-time-form-row--half c-time-form-row--work">
                                                        <label htmlFor="">Type interne uren</label>
                                                        <div
                                                            className="c-work-select-wrap c-work-select-wrap--selected">
                                                            <div className="c-time-form-group">
                                                                <div className="c-work-select is-active">
                                                                    <span>{this._mapInternalTypes(values.type)}</span>
                                                                </div>
                                                                <div className="c-time-form__select"
                                                                     onClick={this._resetType.bind(this, setFieldValue)}>
                                                                    <span>Wijzigen</span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </>

                                                }

                                            </>

                                        }


                                        {values.type && values.type !== 'availability' &&
                                        <div>
                                            <div className={'c-time-form-row c-time-form-row--third'}>
                                                <div className={'c-time-form-group'}>
                                                    <label htmlFor="">Start werkdag</label>
                                                    <Field required={true} name={'start_time'} type="time" min="00:00"
                                                           max="24:00"
                                                           placeholder="09:00"/>
                                                    {errors.start_time &&
                                                    <div className="form-error">
                                                        {errors.start_time}
                                                    </div>
                                                    }
                                                </div>
                                                <div className={'c-time-form-group'}>
                                                    <label htmlFor="">Einde werkdag</label>
                                                    <Field required={true} name={'end_time'} type="time" min="00:00"
                                                           max="24:00"
                                                           placeholder="18:00"/>
                                                </div>
                                                <div className={'c-time-form-group'}>
                                                    <label htmlFor="">Pauze</label>
                                                    <Field name={'pause_time'} type="time"
                                                           placeholder="0"/>
                                                </div>
                                            </div>
                                            <div className={'c-time-form-row c-time-form-row--half'}>
                                                <div className={'c-time-form-group c-time-form-group--unit'}>
                                                    <label htmlFor="">Woon/werkverkeer km's</label>
                                                    <Field name={'commuting_kilometers'} type="number"/>
                                                    <span>km</span>
                                                    {errors.commuting_kilometers &&
                                                    <div className="form-error">
                                                        {errors.commuting_kilometers}
                                                    </div>
                                                    }
                                                </div>
                                                <div className={'c-time-form-group c-time-form-group--unit'}>
                                                    <label htmlFor="">Dienst km's</label>
                                                    <Field name={'business_kilometers'} type="number"/>
                                                    <span>km</span>
                                                    {errors.business_kilometers &&
                                                    <div className="form-error">
                                                        {errors.business_kilometers}
                                                    </div>
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                        }


                                        {values.type === 'availability' &&
                                        <>
                                            <div className={'c-time-form-row c-time-form-row--half'}>
                                                <div className={'c-time-form-group'}>
                                                    <label htmlFor="">Start bereikbaarheid</label>
                                                    <Field required={true} name={'start_time'} type="time" min="00:00"
                                                           max="24:00"
                                                           placeholder="09:00"/>
                                                    {errors.start_time &&
                                                    <div className="form-error">
                                                        {errors.start_time}
                                                    </div>
                                                    }
                                                </div>
                                                <div className={'c-time-form-group'}>
                                                    <label htmlFor="">Einde bereikbaarheid</label>
                                                    <Field required={true} name={'end_time'} type="time" min="00:00"
                                                           max="24:00"
                                                           placeholder="18:00"/>
                                                </div>
                                            </div>
                                            <div className={'c-time-form-row c-time-form-row--half'}>
                                                <div className={'c-time-form-group'}>
                                                    <label htmlFor="">Aantal keer bezoek</label>
                                                    <Field name={'visit_number'} max={50} min={0} type="number"/>
                                                    {errors.visit_number &&
                                                    <div className="form-error">
                                                        {errors.visit_number}
                                                    </div>
                                                    }
                                                </div>

                                            </div>


                                            <FieldArray
                                                name="visits"
                                                render={arrayHelpers => (
                                                    <>
                                                        {values.visit_number > 0 && [...Array(values.visit_number)].map((x, i) =>
                                                            <div className={'c-time-form-visit'}>
                                                                <h5>Bezoek {i + 1}</h5>
                                                                <div
                                                                    className={'c-time-form-row c-time-form-row--half'}>
                                                                    <div className={'c-time-form-group'}>
                                                                        <label htmlFor="">Starttijd</label>
                                                                        <Field name={`visits.${i}.start_time`}
                                                                               type="time"
                                                                               required={true}
                                                                               min="00:00" max="24:00"
                                                                               placeholder="09:00"/>
                                                                        {errors.visits && errors.visits[i] && errors.visits[i].start_time &&
                                                                        <div className="form-error">
                                                                            {errors.visits[i].start_time}
                                                                        </div>
                                                                        }
                                                                    </div>
                                                                    <div className={'c-time-form-group'}>
                                                                        <label htmlFor="">Eindtijd</label>
                                                                        <Field name={`visits.${i}.end_time`} type="time"
                                                                               required={true}
                                                                               min="00:00" max="24:00"
                                                                               placeholder="18:00"/>
                                                                    </div>
                                                                </div>
                                                                <div
                                                                    className={'c-time-form-row c-time-form-row--half'}>
                                                                    <div
                                                                        className={'c-time-form-group c-time-form-group--unit'}>
                                                                        <label htmlFor="">Woon/werkverkeer km's</label>
                                                                        <Field name={`visits.${i}.commuting_kilometers`}
                                                                               required={true}
                                                                               type="number"/>
                                                                        <span>km</span>
                                                                        {errors.visits && errors.visits[i] && errors.visits[i].commuting_kilometers &&
                                                                        <div className="form-error">
                                                                            {errors.visits[i].commuting_kilometers}
                                                                        </div>
                                                                        }
                                                                    </div>
                                                                    <div
                                                                        className={'c-time-form-group c-time-form-group--unit'}>
                                                                        <label htmlFor="">Dienst km's</label>
                                                                        <Field name={`visits.${i}.business_kilometers`}
                                                                               required={true}
                                                                               type="number"/>
                                                                        <span>km</span>
                                                                        {errors.visits && errors.visits[i] && errors.visits[i].business_kilometers &&
                                                                        <div className="form-error">
                                                                            {errors.visits[i].business_kilometers}
                                                                        </div>
                                                                        }
                                                                    </div>
                                                                </div>

                                                            </div>
                                                        )}
                                                    </>
                                                )}
                                            />


                                            <div className="call-container">

                                                <div
                                                    className={'c-time-form-row c-time-form-row--half c-time-row--calls'}>
                                                    <div className={'c-time-form-group'}>
                                                        <label htmlFor="">Aantal keer gebeld<br/>
                                                            <span>tussen 00:00 - 06:00</span></label>
                                                        <Field name={'calls_number_1'} max={99} min={0} type="number"/>
                                                        {errors.calls_number_1 &&
                                                        <div className="form-error">
                                                            {errors.calls_number_1}
                                                        </div>
                                                        }
                                                    </div>

                                                    <div className={'c-time-form-group'}>
                                                        <label htmlFor="">Aantal keer gebeld<br/>
                                                            <span>tussen 06:00 - 18:00</span></label>
                                                        <Field name={'calls_number_2'} max={99} min={0} type="number"/>
                                                        {errors.calls_number_2 &&
                                                        <div className="form-error">
                                                            {errors.calls_number_2}
                                                        </div>
                                                        }
                                                    </div>

                                                </div>

                                                <div
                                                    className={'c-time-form-row c-time-form-row--half c-time-row--calls'}>
                                                    <div className={'c-time-form-group'}>
                                                        <label htmlFor="">Aantal keer gebeld<br/>
                                                            <span>tussen 18:00 - 22:00</span></label>
                                                        <Field name={'calls_number_3'} max={99} min={0} type="number"/>
                                                        {errors.calls_number_3 &&
                                                        <div className="form-error">
                                                            {errors.calls_number_3}
                                                        </div>
                                                        }
                                                    </div>

                                                    <div className={'c-time-form-group'}>
                                                        <label htmlFor="">Aantal keer gebeld<br/>
                                                            <span>tussen 22:00 - 00:00</span></label>
                                                        <Field name={'calls_number_4'} max={99} min={0} type="number"/>
                                                        {errors.calls_number_4 &&
                                                        <div className="form-error">
                                                            {errors.calls_number_4}
                                                        </div>
                                                        }
                                                    </div>

                                                </div>
                                            </div>

                                        </>
                                        }

                                    </div>
                                </div>

                                {values.type && (
                                    parseInt(values.customer_id) === 4510
                                    || parseInt(values.customer_id) === 4575
                                    || parseInt(values.customer_id) === 4594
                                    || parseInt(values.customer_id) === 4596
                                    || parseInt(values.customer_id) === 4595
                                    || parseInt(values.customer_id) === 4597
                                ) &&
                                <div className="o-container o-container--secondary">

                                    <div
                                        className={'c-time-form-row c-time-form-row--full c-time-row--calls'}>

                                        <div className={'c-time-form-group'}>
                                            <label htmlFor="">Opmerking</label>
                                            <Field name={'remarks'} component="textarea" required/>
                                        </div>

                                    </div>
                                </div>
                                }


                                {values.type &&
                                <footer className={'c-time-totals'}>
                                    <div className={'o-container o-container--secondary'}>
                                        <div className="c-time-totals-row">
                                            <span>Totalen</span>
                                            <span><TimeTrackingDate useParams={useParams}
                                                                    data={this.props.currentTimeTracking}/></span>
                                        </div>
                                        <hr/>

                                        {values.visits && values.visit_number > 0 && [...Array(values.visit_number)].map((x, i) => {
                                            const item = values.visits[i]
                                            if (item) {
                                                return (
                                                    <div className="c-time-totals-row">
                                                        <span>Bezoek {i + 1}</span>
                                                        <div>
                                                            {item.start_time && item.end_time &&
                                                            <span>{CalculateHourDifference(item.start_time, item.end_time)}</span>
                                                            }
                                                            {item.commuting_kilometers &&
                                                            <span>{item.commuting_kilometers}km woonwerk</span>
                                                            }
                                                            {item.business_kilometers &&
                                                            <span>{item.business_kilometers}km dienst </span>
                                                            }

                                                        </div>
                                                    </div>
                                                )
                                            }
                                        })}


                                        {values.type && values.type === 'availability' &&
                                        <div className={'call-summary'}>

                                            {values.calls_number_1 &&
                                            <div className="c-time-totals-row">
                                                <span>Gebeld <span
                                                    className={'calltime'}>tussen 00:00 - 06:00</span></span>
                                                <div>
                                                    <span>{values.calls_number_1}x</span>
                                                </div>
                                            </div>
                                            }

                                            {values.calls_number_2 &&
                                            <div className="c-time-totals-row">
                                                <span>Gebeld <span
                                                    className={'calltime'}>tussen 06:00 - 18:00</span></span>
                                                <div>
                                                    <span>{values.calls_number_2}x</span>
                                                </div>
                                            </div>
                                            }

                                            {values.calls_number_3 &&
                                            <div className="c-time-totals-row">
                                                <span>Gebeld <span
                                                    className={'calltime'}>tussen 18:00 - 22:00</span></span>
                                                <div>
                                                    <span>{values.calls_number_3}x</span>
                                                </div>
                                            </div>
                                            }

                                            {values.calls_number_4 &&
                                            <div className="c-time-totals-row">
                                                <span>Gebeld <span
                                                    className={'calltime'}>tussen 22:00 - 00:00</span></span>
                                                <div>
                                                    <span>{values.calls_number_4}x</span>
                                                </div>
                                            </div>
                                            }

                                        </div>
                                        }


                                        {!!values.type === 'regular' &&
                                        <>
                                            {values.commuting_kilometers &&
                                            <div className="c-time-totals-row">
                                                <span>Woon/werkverkeer km's gereden</span>
                                                <div>
                                                    <span>{values.commuting_kilometers}</span>
                                                </div>
                                            </div>
                                            }
                                            {values.business_kilometers &&
                                            <div className="c-time-totals-row">
                                                <span>Dienst km's gereden</span>
                                                <div>
                                                    <span>{values.business_kilometers}</span>
                                                </div>
                                            </div>
                                            }
                                        </>
                                        }

                                        {!!(values.start_time !== "" && values.end_time !== "") &&
                                        <div className="c-time-totals-row">
                                            <span>Totaal gewerkt</span>

                                            <div>
                                                <span>{CalculateHourDifference(values.start_time, values.end_time, values.pause_time, values.type)}</span>
                                            </div>
                                        </div>
                                        }

                                    </div>
                                </footer>
                                }

                                {Object.keys(errors).length !== 0 &&
                                <div class="error-fix">
                                    <p>Los eerste alle *errors op voordat je verder kan gaan</p>
                                </div>
                                }

                                {values.type && Object.keys(errors).length === 0 &&
                                <button type={'submit'} className={'btn btn--green'}>
                                    <span>Tijdsregistratie opslaan</span>
                                </button>
                                }
                            </form>
                        )}
                    </Formik>

                </main>
            )

        } else {
            if (this.props.formSubmitting) {
                return (
                    <div className={'c-timetracking-loader'}>
                        <Loader
                            type="Bars"
                            color="#51B0AA"
                            height={50}
                            width={50}
                            timeout={10000}
                        />
                    </div>
                )
            } else {
                return (
                    <div className={'c-timetracking-loader'}>
                        <Loader
                            type="Watch"
                            color="#51B0AA"
                            height={100}
                            width={100}
                            timeout={10000} //3 secs
                        />
                    </div>
                )
            }
        }
    }
}

export default withRouter(connect(mapStateToProps)(TimeTrackingAdd));
