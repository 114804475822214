import ClientRulesAction from './ClientRulesAction'

export default class ClientRulesReducer {

    static initialState = {
        clientRules: {
            list: false,
            detail: false,
            info: false,
        },
        setSeen: false
    };

    static reducer(state = ClientRulesReducer.initialState, action) {

        switch (action.type) {

            case ClientRulesAction.REQUEST_LIST:
                return {
                    ...state,
                    clientRules: {
                        detail: state.clientRules.detail,
                        list: false,
                        info: state.clientRules.info
                    }
                };

            case ClientRulesAction.REQUEST_LIST + '_FINISHED':
                return {
                    ...state,
                    clientRules: {
                        detail: state.clientRules.detail,
                        list: action.payload,
                        info: state.clientRules.info
                    }
                }

            case ClientRulesAction.REQUEST_DETAIL:
                return {
                    ...state,
                    clientRules: {
                        list: state.clientRules.list,
                        detail: false,
                        info: state.clientRules.info
                    }
                };

            case ClientRulesAction.REQUEST_DETAIL + '_FINISHED':
                return {
                    ...state,
                    clientRules: {
                        list: state.clientRules.list,
                        detail: action.payload,
                        info: state.clientRules.info
                    }
                }

            case ClientRulesAction.REQUEST_SET_SEEN:
                return {
                    ...state,
                    setSeen: false
                };

            case ClientRulesAction.REQUEST_SET_SEEN + '_FINISHED':
                return {
                    ...state,
                    setSeen: true
                }

            case ClientRulesAction.REQUEST_INFO:
                return {
                    ...state,
                    clientRules: {
                        list: state.clientRules.list,
                        detail: state.clientRules.detail,
                        info: false
                    }
                };

            case ClientRulesAction.REQUEST_INFO + '_FINISHED':
                return {
                    ...state,
                    clientRules: {
                        list: state.clientRules.list,
                        detail: state.clientRules.detail,
                        info: action.payload
                    }
                }

            default:
                return state;
        }
    }

}
