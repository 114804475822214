import React from 'react'
import InlineSVG from 'svg-inline-react';

const PlusData = `
<?xml version="1.0" encoding="UTF-8"?>
<svg class='svg-plus' width="13px" height="13px" viewBox="0 0 13 13" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
    <!-- Generator: Sketch 62 (91390) - https://sketch.com -->
    <title>+</title>
    <desc>Created with Sketch.</desc>
    <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
        <g id="ZBVO---tijdregistratie-[1]" transform="translate(-325.000000, -377.000000)">
            <rect id="Rectangle" fill="" x="13" y="350" width="350" height="70"></rect>
            <polygon id="+" fill="#51B0AA" fill-rule="nonzero" points="333.03 389.74 333.03 385.06 337.68 385.06 337.68 382.18 333.03 382.18 333.03 377.56 329.94 377.56 329.94 382.18 325.32 382.18 325.32 385.06 329.94 385.06 329.94 389.74"></polygon>
        </g>
    </g>
</svg>
`

const Plus = (props) => {

    return (
        <InlineSVG src={PlusData}/>
    )

}

export default Plus