import React from 'react'
import InlineSVG from 'svg-inline-react';

const OpenData = `
<?xml version="1.0" encoding="UTF-8"?>
<svg class="svg-open" width="15px" height="9px" viewBox="0 0 15 9" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
    <!-- Generator: Sketch 62 (91390) - https://sketch.com -->
    <title>Path</title>
    <desc>Created with Sketch.</desc>
    <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
        <g id="ZBVO---tijdregistratie-[1]" transform="translate(-322.000000, -458.000000)">
            <rect id="Rectangle" fill="#FFFFFF" x="13" y="425" width="350" height="70"></rect>
            <g id="back" transform="translate(329.500000, 462.000000) rotate(-90.000000) translate(-329.500000, -462.000000) translate(325.000000, 455.000000)" fill="#3C576D" fill-rule="nonzero">
                <path d="M3.30000007,7.15000015 L4.55721961,5.58672573 L8.02812213,2.10817687 C8.19808554,1.9383476 8.29131725,1.70828662 8.29131725,1.4668232 C8.29131725,1.22535978 8.19808554,0.996908558 8.02812213,0.826676847 L7.48804894,0.286335372 C7.31821967,0.1165061 7.09191479,0.022603659 6.85058551,0.022603659 C6.60912209,0.022603659 6.38268307,0.115835368 6.21285379,0.28566464 L0.263060981,6.23492087 C0.0925609776,6.40542087 -0.000670731728,6.63253063 -7.9617794e-05,6.8741282 C-0.000670731728,7.11706723 0.0925609776,7.34431113 0.263060981,7.51454285 L6.21285379,13.4643357 C6.38268307,13.6340308 6.60898795,13.7273966 6.85058551,13.7273966 C7.09191479,13.7273966 7.31821967,13.6338966 7.48804894,13.4643357 L8.02812213,12.9239942 C8.19808554,12.7544332 8.29131725,12.5279942 8.29131725,12.2865308 C8.29131725,12.0452015 8.19808554,11.8307015 8.02812213,11.6610064 L4.51804888,8.16300627 C4.07268302,7.85433554 3.66666675,7.51666683 3.30000007,7.15000015 Z" id="Path"></path>
            </g>
        </g>
    </g>
</svg>
`

const Open = (props) => {

    return (
        <InlineSVG src={OpenData}/>
    )

}

export default Open