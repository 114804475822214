import React from 'react'
import Logo from "../../../static/svg/Logo";
import "./Header.scss";
import {withRouter} from "react-router";
import {connect} from "react-redux";
import TimeTrackingAction from "../../../stores/timetracking/TimetrackingAction";
import AuthenticationAction from "../../../stores/authentication/AuthenticationAction";
import {Link} from "react-router-dom";
import RouteEnum from "../../../constants/RouteEnum";
import ClientRulesAction from "../../../stores/clientRules/ClientRulesAction";

const mapStateToProps = (state, ownProps) => ({
    ...state.authentication,
    ...state.clientRules,
});

class Header extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            menuOpen: false,
            clientRuleNew: false,
        }
    }

    componentDidMount() {
        this.props.dispatch(ClientRulesAction.requestClientRulesList()).then(() => {
            Object.values(this.props.clientRules.list).map((rule) => {
                if (rule.is_new){
                    this.setState({
                        clientRuleNew: true,
                    })
                }
            })
        })
    }

    _clickLogout(props) {
        props.dispatch(AuthenticationAction.logout());
    }

    _clickMenuButton() {
        this.setState({
            menuOpen: !this.state.menuOpen
        })
    }

    render() {

        let toggleClass = 'c-header__menu-trigger'

        if (this.state.menuOpen) {
            toggleClass += ' js-menuActive'
        }

        return (
            <div>
                <header className={'c-header'}>
                    <Link to={RouteEnum.Home} className="c-header-logo">
                        <Logo/>
                    </Link>
                    <div className="c-header-nav-primary">

                    </div>
                    <div className="c-header-nav-secondary">
                        <a className={toggleClass} onClick={this._clickMenuButton.bind(this)}>
                            <div>
                                <small></small>
                                <small></small>
                                <small></small>
                            </div>
                            <span>menu</span>
                        </a>
                    </div>
                </header>
                {this.state.menuOpen &&
                <div className="c-menu">
                    <nav className="c-menu__nav">
                        <ul className="u-reset-ul">
                            <li><Link to={RouteEnum.Home} onClick={this._clickMenuButton.bind(this)}>Dashboard</Link></li>
                            <li><Link to={RouteEnum.TimeTrackingOverview} onClick={this._clickMenuButton.bind(this)}>Tijdsregistraties</Link></li>
                            <li><Link to={RouteEnum.DaysOffOverview} onClick={this._clickMenuButton.bind(this)}>Verlofdagen</Link></li>
                            <li><Link to={RouteEnum.ClientRulesOverview} onClick={this._clickMenuButton.bind(this)}>Klantafspraken {!!this.state.clientRuleNew && <div className="c-label">Nieuw</div>}</Link></li>
                        </ul>
                    </nav>
                    <div className="c-menu__logout" onClick={this._clickLogout.bind(this, this.props)} s>
                        <a>
                            <span>Uitloggen</span>
                        </a>
                    </div>
                </div>
                }
            </div>
        )
    }


}


export default connect(mapStateToProps)(Header);
