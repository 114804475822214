import ActionUtility from '../../utilities/ActionUtility'
import NotificationEffect from './NotificationEffect'

export default class NotificationAction {
    static REQUEST_LIST = 'NotificationAction.REQUEST_LIST'
    static REQUEST_SET_SEEN = 'NotificationAction.REQUEST_SET_SEEN'

    static requestNotificationList() {
        return async (dispatch) => {
            await ActionUtility.createThunkEffect(dispatch, NotificationAction.REQUEST_LIST, NotificationEffect.requestNotificationList);
        };
    }

    static requestNotificationSetSeen(id) {
        return async (dispatch) => {
            await ActionUtility.createThunkEffect(dispatch, NotificationAction.REQUEST_SET_SEEN, NotificationEffect.requestNotificationSetSeen, id);
        };
    }

}
