import React from 'react'
import './HappyCheck.scss'
import {connect} from "react-redux";
import Header from "./_components/Header";
import RouteEnum from "../../constants/RouteEnum";
import {Link} from "react-router-dom";

const mapStateToProps = (state, ownProps) => ({
    ...state
});


class HappyCheckThanks extends React.Component {

    constructor(props) {
        super(props)
    }

    render() {
        return(
            <div className={'c-time c-timetracking-overview c-time--dark'}>
                <Header/>
                <div className={'o-container o-container--primary'}>
                    <div className={'c-time-form c-happy-check-content'}>
                        <div className={'c-happy-check-content__intro'}>
                            <p>
                                Bedankt voor het invullen van de happycheck. We zullen er vertrouwelijk mee omgaan.
                            </p>
                            <Link to={RouteEnum.Home} className={'btn btn--green'}>Terug naar dashboard</Link>
                        </div>
                    </div>
                </div>
            </div>

        );
    }
}

export default connect(mapStateToProps)(HappyCheckThanks);
