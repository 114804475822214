import React from 'react'
import './TotalsPopup.scss'
import Moment from 'react-moment';
import {connect} from "react-redux";
import {Page, Text, View, Document, StyleSheet, PDFDownloadLink} from '@react-pdf/renderer';
import TotalTimeTrackingData from '../../../utilities/TotalTimeTrackingData'

const mapStateToProps = (state, ownProps) => ({
    ...state.timeTracking,
});

class TotalsPopup extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            show: false
        }
    }

    render() {
        const data = TotalTimeTrackingData(this.props.timeTrackings)

        return (

            <div className={'c-totals-popup show-' + this.state.show}>
                <div className={'c-totals-popup-header'} onClick={this._clickShowTotals.bind(this)}>
                    <h5>Totalen <Moment format="MMMM YYYY">{this.props.date}</Moment></h5>
                    <i className={'icon-arrow-long'}></i>
                </div>
                <div className={'c-totals-popup-body'}>
                    <div className="c-totals-popup-row">
                        <span>Reguliere uren</span>
                        <span>{data.regularHours}</span>
                    </div>
                    <div className="c-totals-popup-row">
                        <span>Bereikbaarheidsuren</span>
                        <span>{data.availabilityHours}</span>
                    </div>
                    <hr/>
                    <div className="c-totals-popup-row c-totals-popup-row--strong">
                        <span>Totale uren</span>
                        <span>{data.totalHours}</span>
                    </div>
                    <div className="c-totals-popup-row c-totals-popup-row--strong">
                        <span>Totaal aantal km's gereden</span>
                        <span>{data.kms}km</span>
                    </div>
                    {this.props.pdfLink}
                </div>
            </div>
        )

    }

    _clickShowTotals = () => {
        this.setState(
            {
                show: !this.state.show
            }
        )
    }
}

export default connect(mapStateToProps)(TotalsPopup);