const RouteEnum = {
    Home: process.env.PUBLIC_URL + '/',
    ForgotPassword: process.env.PUBLIC_URL + '/forgot-password',
    Register: process.env.PUBLIC_URL + '/register',
    TimeTrackingOverview: process.env.PUBLIC_URL + '/time-tracks',
    TimeTrackingAdd: process.env.PUBLIC_URL + '/time-tracks/create',
    TimeTrackingEdit: process.env.PUBLIC_URL + '/time-track/edit',
    TimeTrackingView: process.env.PUBLIC_URL + '/time-tracks/view',
    TimeTrackingRemove: process.env.PUBLIC_URL + '/time-tracks/remove',
    DaysOffOverview: process.env.PUBLIC_URL + '/days-off',
    DaysOffAdd: process.env.PUBLIC_URL + '/days-off/create',
    DaysOffEdit: process.env.PUBLIC_URL + '/days-off/edit',
    DaysOffView: process.env.PUBLIC_URL + '/days-off/view',
    DaysOffRemove: process.env.PUBLIC_URL + '/days-off/remove',
    ClientRulesOverview: process.env.PUBLIC_URL + '/client-rules',
    ClientRulesDetail: process.env.PUBLIC_URL + '/client-rules/detail',
    ClientRulesDetailOld: process.env.PUBLIC_URL + '/old-version',
    HappyCheck: process.env.PUBLIC_URL + '/happycheck',
    API: 'https://backoffice.zbvo.idot.cloud'
}

export default RouteEnum;
