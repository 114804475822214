import ActionUtility from '../../utilities/ActionUtility'
import ClientEffect from "./ClientEffect";

export default class ClientAction {
    static REQUEST_CLIENTS = 'ClientAction.REQUEST_CLIENTS';

    static requestClients() {
        return async (dispatch, getState) => {
            await ActionUtility.createThunkEffect(dispatch, ClientAction.REQUEST_CLIENTS, ClientEffect.requestClient, getState.token);
        };
    }
}