import React from 'react'
import InlineSVG from 'svg-inline-react';

const LogoData = `
<?xml version="1.0" encoding="UTF-8"?>
<svg width="327px" height="88px" viewBox="0 0 327 88" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
    <!-- Generator: Sketch 61.2 (89653) - https://sketch.com -->
    <title>Group 8</title>
    <desc>Created with Sketch.</desc>
    <defs>
        <polygon id="path-1" points="0.06 0.028 80.627 0.028 80.627 80.655 0.06 80.655"></polygon>
    </defs>
    <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
        <g id="Group-8" transform="translate(0.000000, 0.483000)">
            <g id="Group-3" transform="translate(0.000000, 6.517000)">
                <mask id="mask-2" fill="white">
                    <use xlink:href="#path-1"></use>
                </mask>
                <g id="Clip-2"></g>
                <path d="M80.627,40.342 C80.627,62.606 62.578,80.655 40.313,80.655 C18.049,80.655 0,62.606 0,40.342 C0,18.077 18.049,0.028 40.313,0.028 C62.578,0.028 80.627,18.077 80.627,40.342" id="Fill-1" fill="#51B1AC" mask="url(#mask-2)"></path>
            </g>
            <path d="M60.47,46.859 C60.47,57.991 51.445,67.015 40.313,67.015 C29.181,67.015 20.156,57.991 20.156,46.859 C20.156,35.726 29.181,26.702 40.313,26.702 C51.445,26.702 60.47,35.726 60.47,46.859" id="Fill-4" fill="#F1AF9C"></path>
            <polyline id="Fill-5" fill="#3B556B" points="146.801 21.32 146.801 27.324 123.306 59.925 147.007 59.925 147.007 72.345 104.055 72.345 104.055 67.068 128.481 32.808 105.918 32.808 105.918 21.32 146.801 21.32"></polyline>
            <path d="M168.017,46.781 C168.017,55.992 174.847,61.789 182.816,61.789 C190.89,61.789 197.306,55.682 197.306,46.781 C197.306,37.57 190.89,31.877 182.816,31.877 C174.847,31.877 168.017,37.88 168.017,46.781 Z M167.188,-7.10542736e-15 L167.188,28.254 C170.189,22.976 178.573,20.079 183.956,20.079 C198.859,20.079 209.933,29.186 209.933,46.781 C209.933,63.548 198.653,73.484 183.645,73.484 C177.434,73.484 171.121,71.414 167.188,65.307 L166.36,72.345 L154.561,72.345 L154.561,-7.10542736e-15 L167.188,-7.10542736e-15 L167.188,-7.10542736e-15 Z" id="Fill-6" fill="#3B556B"></path>
            <polyline id="Fill-7" fill="#3B556B" points="270.068 21.217 248.333 72.552 234.671 72.552 212.833 21.217 226.597 21.217 233.636 38.088 241.501 59.615 249.264 38.294 256.301 21.217 270.068 21.217"></polyline>
            <path d="M286.421,46.885 C286.421,54.648 291.079,61.893 300.29,61.893 C309.502,61.893 314.159,54.648 314.159,46.885 C314.159,39.226 308.777,31.774 300.29,31.774 C291.181,31.774 286.421,39.226 286.421,46.885 Z M326.889,46.885 C326.889,61.582 316.849,73.484 300.29,73.484 C283.729,73.484 273.794,61.582 273.794,46.885 C273.794,32.291 283.936,20.285 300.187,20.285 C316.436,20.285 326.889,32.291 326.889,46.885 L326.889,46.885 Z" id="Fill-8" fill="#3B556B"></path>
        </g>
    </g>
</svg>
`

const Logo = (props) => {

    return (
        <InlineSVG src={LogoData}/>
    )

}

export default Logo