import React from 'react'
import './HappyCheck.scss'
import {connect} from "react-redux";
import Header from "./_components/Header";
import HappyCheckAction from "../../stores/happyCheck/HappyCheckAction";
import Loader from "react-loader-spinner";
import Subject from "./_components/Subject";
import HappyCheckThanks from "./HappyCheckThanks";
import MonthNumberMapper from "../../utilities/MonthNumberMapper";

const mapStateToProps = (state, ownProps) => ({
    ...state.client,
    ...state.happyCheck
});


class HappyCheck extends React.Component {

    constructor(props) {
        super(props)

        this.state = {
            submitted: false,
            happyCheck: false,
            subjects: false,
            score: false,
            remarks: []
        }

        this.handleChange = this.handleChange.bind(this)
        this.handleRemarks = this.handleRemarks.bind(this)
        this.handleSubmit = this.handleSubmit.bind(this)
    }

    componentDidMount() {
        this.setState({
            ...this.state,
            score: 50,
            submitted: false
        })
        this.props.dispatch(HappyCheckAction.requestHappyCheckOpen()).then(() => {
            this.setState({
                happyCheck: Object.values(this.props.happyChecksOpen.happyChecks).filter((happyCheck) => happyCheck.id === parseInt(this.props.match.params.id))[0],
                subjects: Object.values(this.props.happyChecksOpen.subjects)
            })
        })
    }

    handleChange(event) {
        this.setState({
            ...this.state,
            [event.target.name]: event.target.value
        });
    }

    handleRemarks = (data) => {
        let remarkArray = this.state.remarks

        const indexItem = remarkArray.findIndex(remark => remark.subject_id === data.id)

        if (data.active){
            if (!remarkArray.some(remark => remark.subject_id === data.id)){
                remarkArray.push({
                    subject_id: data.id,
                    message: data.message
                })
            }else{
                remarkArray[indexItem] = {
                    subject_id: data.id,
                    message: data.message
                }
            }
        }else{
            if(indexItem !== -1){
                remarkArray.splice(indexItem, 1)
            }
        }

        if (this.state.remarks !== remarkArray){
            this.setState({
                remarks: remarkArray
            })
        }
    }

    handleSubmit(){
        console.log(this.state)
        const registerObject = {
            happy_check_id: this.state.happyCheck.id,
            score: parseInt(this.state.score),
            remarks: this.state.remarks
        }
        this.props.dispatch(HappyCheckAction.requestHappyCheckRegister(registerObject)).then(() => {
            this.setState({
                submitted: true
            })
        })
    }

    render() {
        let loading = false
        if (!this.state.happyCheck) loading = true

        if (!this.state.submitted){

            return (
                <div className={'c-time c-timetracking-overview c-time--dark'}>
                    {!!this.state.happyCheck &&
                    <Header month={MonthNumberMapper(this.state.happyCheck.month)} year={this.state.happyCheck.year}/>
                    }
                    <div className={'o-container o-container--primary'}>

                        {!!this.state.happyCheck &&

                        <div className={'c-time-form c-happy-check-content'}>
                            <div className={'c-happy-check-content__rating'}>
                                <div className={'c-happy-check-content__rating__faces'}>
                                    <img src={'/images/smile-unhappy.svg'}/>
                                    <p>Score: {this.state.score}</p>
                                    <img src={'/images/smile-happy.svg'}/>
                                </div>
                                <input min="1" max="100" name="score" type="range" onChange={this.handleChange} value={this.state.score}/>
                            </div>
                            <hr/>

                            {!!this.state.subjects &&

                            <div className={'c-happy-check-content__rating__note'}>
                                <h2>Wil je iets opmerken? <small>(optioneel)</small></h2>

                                {this.state.subjects.map((subject) => (

                                    <Subject {...subject} handleRemarks={this.handleRemarks} />

                                ))}

                            </div>

                            }

                            <button className={'btn btn--green'} onClick={this.handleSubmit}>Opslaan</button>
                        </div>
                        }

                        {loading &&
                        <div className={'c-timetracking-loader'}>
                            <Loader
                                type="Watch"
                                color="#51B0AA"
                                height={100}
                                width={100}
                                timeout={10000} //3 secs
                            />
                        </div>
                        }

                    </div>

                </div>

            );

        }else{

            return (
                <HappyCheckThanks/>
            )

        }
    }
}

export default connect(mapStateToProps)(HappyCheck);
