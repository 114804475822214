import React from 'react'
import {Page, Text, View, Document, StyleSheet, PDFDownloadLink} from '@react-pdf/renderer';
import moment from "moment";
import 'moment/locale/nl';
import WorkTypeByValue from "./WorkTypeByValue";
import TotalTimeTrackingData from './TotalTimeTrackingData'
import {connect} from "react-redux";
import CalculateHourDifference from "./CalculateHourDifference";

moment.locale('nl');

const styles = StyleSheet.create({
    body: {
        padding: 10
    },
    table: {
        display: "table",
        width: "auto",
        borderStyle: "solid",
        borderColor: '#bfbfbf',
        borderWidth: 1,
        borderRightWidth: 0,
        borderBottomWidth: 0
    },
    tableRow: {
        margin: "auto",
        flexDirection: "row"
    },
    tableColHeader: {
        width: "8.33%",
        borderStyle: "solid",
        borderColor: '#bfbfbf',
        borderBottomColor: '#000',
        borderWidth: 1,
        borderLeftWidth: 0,
        borderTopWidth: 0
    },
    tableColHeaderAlt1: {
        width: "24.99%",
        borderStyle: "solid",
        borderColor: '#bfbfbf',
        borderBottomColor: '#000',
        borderWidth: 1,
        borderLeftWidth: 0,
        borderTopWidth: 0,
        textAlign: 'center',
        fontWeight: 'bold'
    },
    tableColHeaderAlt2: {
        width: "33.32%",
        borderStyle: "solid",
        borderColor: '#bfbfbf',
        borderBottomColor: '#000',
        borderWidth: 1,
        borderLeftWidth: 0,
        borderTopWidth: 0,
        textAlign: 'center',
        fontWeight: 'bold'
    },
    tableCol: {
        width: "8.33%",
        borderStyle: "solid",
        borderColor: '#bfbfbf',
        borderWidth: 1,
        borderLeftWidth: 0,
        borderTopWidth: 0
    },
    tableCellHeader: {
        margin: "auto",
        margin: 5,
        fontSize: 6,
        fontWeight: 500
    },
    tableCell: {
        margin: "auto",
        margin: 5,
        fontSize: 5
    },
    totalTimes: {
        fontSize: 8,
        marginTop: 30
    },
    totalTime: {
        fontSize: 8,
        marginBottom: 3
    },
    totalTimeBold: {
        fontSize: 8,
        fontWeight: 900,
        marginBottom: 3
    }
});

const mapStateToProps = (state, ownProps) => ({
    ...state.timeTracking,
    ...state.client
});


// Create Document Component
const MyDocument = (props) => {

    console.log(props.timeTracking)

    return (
        <Document>
            <Page style={styles.body}>
                <View style={styles.table}>
                    <View style={styles.tableRow}>
                        <View style={styles.tableColHeaderAlt1}>
                            <Text style={styles.tableCellHeader}>Gewerkte uren:</Text>
                        </View>
                        <View style={styles.tableColHeaderAlt2}>
                            <Text style={styles.tableCellHeader}>Gewerkte tijd:</Text>
                        </View>
                        <View style={styles.tableColHeaderAlt2}>
                            <Text style={styles.tableCellHeader}>ORT uren:</Text>
                        </View>
                        <View style={styles.tableColHeader}>
                            <Text style={styles.tableCellHeader}/>
                        </View>
                    </View>
                    <View style={styles.tableRow}>
                        <View style={styles.tableColHeader}>
                            <Text style={styles.tableCellHeader}>Dag</Text>
                        </View>
                        <View style={styles.tableColHeader}>
                            <Text style={styles.tableCellHeader}>Datum</Text>
                        </View>
                        <View style={styles.tableColHeader}>
                            <Text style={styles.tableCellHeader}>Type</Text>
                        </View>
                        <View style={styles.tableColHeader}>
                            <Text style={styles.tableCellHeader}>Van</Text>
                        </View>
                        <View style={styles.tableColHeader}>
                            <Text style={styles.tableCellHeader}>Tot</Text>
                        </View>
                        <View style={styles.tableColHeader}>
                            <Text style={styles.tableCellHeader}>Pauze tijd</Text>
                        </View>
                        <View style={styles.tableColHeader}>
                            <Text style={styles.tableCellHeader}>Uren</Text>
                        </View>
                        <View style={styles.tableColHeader}>
                            <Text style={styles.tableCellHeader}>25%</Text>
                        </View>
                        <View style={styles.tableColHeader}>
                            <Text style={styles.tableCellHeader}>50%</Text>
                        </View>
                        <View style={styles.tableColHeader}>
                            <Text style={styles.tableCellHeader}>75%</Text>
                        </View>
                        <View style={styles.tableColHeader}>
                            <Text style={styles.tableCellHeader}>100%</Text>
                        </View>
                        <View style={styles.tableColHeader}>
                            <Text style={styles.tableCellHeader}>Kilometers</Text>
                        </View>
                    </View>

                    {Object.keys(props.timeTrackings).map((i) => {
                        const timeTrack = props.timeTrackings[i]

                        let time0 = false
                        let time25 = false
                        let time50 = false
                        let time75 = false
                        let time100 = false
                        if (timeTrack.clocks) {
                            if (timeTrack.clocks['25_percentage']) {
                                time25 = timeTrack.clocks['25_percentage']['time']
                            }
                            if (timeTrack.clocks['50_percentage']) {
                                time50 = timeTrack.clocks['50_percentage']['time']
                            }
                            if (timeTrack.clocks['75_percentage']) {
                                time50 = timeTrack.clocks['75_percentage']['time']
                            }
                            if (timeTrack.clocks['100_percentage']) {
                                time50 = timeTrack.clocks['100_percentage']['time']
                            }
                        }

                        return (
                            <View style={styles.tableRow}>
                                <View style={styles.tableCol}>
                                    <Text style={styles.tableCell}>{moment(timeTrack.date).format('dddd')}</Text>
                                </View>
                                <View style={styles.tableCol}>
                                    <Text style={styles.tableCell}>{moment(timeTrack.date).format('DD-MM-YYYY')}</Text>
                                </View>
                                <View style={styles.tableCol}>
                                    <Text style={styles.tableCell}>{WorkTypeByValue(timeTrack.type)}</Text>
                                </View>
                                <View style={styles.tableCol}>
                                    <Text
                                        style={styles.tableCell}>{moment.duration(timeTrack.start_time).format('HH:ss')}</Text>
                                </View>
                                <View style={styles.tableCol}>
                                    <Text
                                        style={styles.tableCell}>{moment.duration(timeTrack.end_time).format('HH:ss')}</Text>
                                </View>
                                <View style={styles.tableCol}>
                                    {timeTrack.pause_time !== "00:00:00" &&
                                    <Text
                                        style={styles.tableCell}>{moment.duration(timeTrack.pause_time).format('HH:ss')}</Text>
                                    }
                                </View>
                                <View style={styles.tableCol}>
                                    <Text
                                        style={styles.tableCell}>{CalculateHourDifference(timeTrack.start_time, timeTrack.end_time, timeTrack.pause_time)}</Text>
                                </View>
                                <View style={styles.tableCol}>
                                    {time25 && time25 !== '00:00:00' &&
                                    <Text style={styles.tableCell}>{moment.duration(time25).format('HH:ss')}</Text>
                                    }
                                </View>
                                <View style={styles.tableCol}>
                                    {time50 && time50 !== '00:00:00' &&
                                    <Text style={styles.tableCell}>{moment.duration(time50).format('HH:ss')}</Text>
                                    }
                                </View>
                                <View style={styles.tableCol}>
                                    {time75 && time75 !== '00:00:00' &&
                                    <Text style={styles.tableCell}>{moment.duration(time75).format('HH:ss')}</Text>
                                    }
                                </View>
                                <View style={styles.tableCol}>
                                    {time100 && time100 !== '00:00:00' &&
                                    <Text style={styles.tableCell}>{moment.duration(time100).format('HH:ss')}</Text>
                                    }
                                </View>
                                <View style={styles.tableCol}>
                                    <Text style={styles.tableCell}>{timeTrack.total_kilometers}</Text>
                                </View>
                            </View>
                        )
                    })}

                </View>
                <View style={styles.totalTimes}>
                    <Text style={styles.totalTime}>TOTAAL aantal km's gereden: {props.totaldata.kms}</Text>
                    <Text style={styles.totalTime}>TOTAAL uren regulier: {props.totaldata.regularHours}</Text>
                    <Text style={styles.totalTime}>TOTAAL uren
                        bereikbaarheid: {props.totaldata.availabilityHours}</Text>
                    <Text style={styles.totalTimeBold}>TOTAAL ALLE UREN: {props.totaldata.totalHours}</Text>
                </View>
            </Page>
        </Document>
    )
};

class PdfLink extends React.Component {

    render() {
        const timeTrackingsSorted =
            this.props.timeTrackings.sort((a, b) => {
                return new Date(a.date).getTime() -
                    new Date(b.date).getTime()
            }).reverse()


        const totaldata = TotalTimeTrackingData(this.props.timeTrackings)
        const fileName = 'ZBVO-timetracking-' + moment(this.props.date).format('DD-MM-YYYY')

        console.log('generating pdf')
        return (
            <PDFDownloadLink document={
                <MyDocument
                    totaldata={totaldata}
                    timeTrackings={timeTrackingsSorted}
                />}
                             fileName={fileName}
                             className="c-totals-popup-export-btn">
                <i className={'icon-pdf'}/>
                <span>Totaal exporteren</span>
            </PDFDownloadLink>
        )
    }
}

export default connect(mapStateToProps)(PdfLink);