import ClientAction from './ClientAction'

export default class ClientReducer {

    static initialState = {
        clients: false

    };

    static reducer(state = ClientReducer.initialState, action) {

        switch (action.type) {

            case ClientAction.REQUEST_CLIENTS:
                return {
                    ...state,
                    clients: false,
                };
            case ClientAction.REQUEST_CLIENTS + '_FINISHED':
                return {
                    ...state,
                    clients: action.payload,
                };
            default:
                return state;
        }
    }
}