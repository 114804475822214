import moment from 'moment';

const CalculateHourDifference = (startTime, endTime, difference = 0, workTypeValue = 'regular') => {

    if(workTypeValue !== 'regular'){
        difference = 0
    }

    return moment.utc(moment("04/09/2013 " + endTime, "DD/MM/YYYY HH:mm").subtract(moment.duration(difference).asMinutes(), "minutes").diff(moment("04/09/2013 " + startTime, "DD/MM/YYYY HH:mm"))).format("HH:mm")
}

export default CalculateHourDifference