import ServiceWorkerAction from './ServiceWorkerAction'

export default class ServiceWorkerReducer {

    static initialState = {
        serviceWorkerInitialized: false,
        serviceWorkerUpdated: false,
        serviceWorkerRegistration: null,
    };

    static reducer(state = ServiceWorkerReducer.initialState, action) {


        switch (action.type) {
            case ServiceWorkerAction.SW_INIT:
                return {
                    ...state,
                    serviceWorkerInitialized: true
                };
            case ServiceWorkerAction.SW_UPDATE:
                return {
                    ...state,
                    serviceWorkerUpdated: true,
                    serviceWorkerRegistration: action.payload
                };
            default:
                return state;
        }
    }
}