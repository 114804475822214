import React from 'react'
import Open from "../../../../static/svg/Open";
import TimeTrackingDaySingle from "../TimeTrackingItem/TimeTracking";
import Plus from "../../../../static/svg/Plus";
import {Link} from "react-router-dom";
import RouteEnum from "../../../../constants/RouteEnum";
import Moment from 'react-moment';
import moment from 'moment';
import 'moment/locale/nl';

class DayFilled extends React.Component {

    constructor(props) {
        super(props)
        this.state = {
            collapsed: false
        }
    }

    render() {

        const {day, timeTracking, totalVisits, totalKm, totalPhoneCalls, totalTime} = this.props.data;

        return (
            <div className={'c-timetracking-day-wrap collapsed-' + this.state.collapsed}>

                <div className={'c-timetracking-day c-timetracking-day--open'} onClick={this._clickCollapse}>
                    <div className={'c-timetracking-day-info'}>

                        <div className={'c-timetracking-day-info-icon'}>
                            <div className={'c-timetracking-day-info-icon-circle'}/>
                        </div>

                        <div className={'c-timetracking-day-info-text'}>
                            <h3>
                                <Moment
                                    locale="nl"
                                    format="dddd D MMMM">{day}</Moment>
                            </h3>
                            <div className={'c-timetracking-day-info-totals'}>
                                <div>
                                    <i className={'icon-driven'}/>
                                    <span>{totalKm}km</span>
                                </div>
                                <div>
                                    <img src={'./images/clock.svg'} className={'clock-image'}/>
                                    <span>{totalTime}</span>
                                </div>
                                <div>
                                    <i className={'icon-house'}/>
                                    <span>{totalVisits}x</span>
                                </div>
                                <div>
                                    <i className={'icon-phone'}/>
                                    <span>{totalPhoneCalls}x</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className={'c-timetracking-day-action'}>
                        <Open/>
                    </div>
                </div>



                {this.state.collapsed &&
                <div className={'c-timetracking-collapse'}>
                    {timeTracking &&
                    timeTracking.map((timeTracking) => (
                        <TimeTrackingDaySingle
                            key={timeTracking.sjsId}
                            data={timeTracking}
                        />
                    ))
                    }

                    <Link to={RouteEnum.TimeTrackingAdd + '/' + moment(day).format('YYYY-MM-DD')}
                          className={'c-timetracking-day-add-btn'}>
                        <Plus/>
                        <span>tijd toevoegen</span>
                    </Link>
                </div>
                }

            </div>
        )
    }

    _clickCollapse = () => {
        this.setState({
            collapsed: !this.state.collapsed
        })
    }

    _clickTimeTrackingOptions = () => {
        this.setState({
            showOptions: !this.state.showOptions
        })
    }

}


export default DayFilled