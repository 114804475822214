import NotificationAction from './NotificationAction'

export default class NotificationReducer {

    static initialState = {
        notifications: false,
        notificationSetSeen: false
    };

    static reducer(state = NotificationReducer.initialState, action) {

        switch (action.type) {

            case NotificationAction.REQUEST_LIST:

                return {
                    ...state,
                    notifications: false
                };

            case NotificationAction.REQUEST_LIST + '_FINISHED':
                return {
                    ...state,
                    notifications: action.payload,
                }

            case NotificationAction.REQUEST_SET_SEEN:

                return {
                    ...state,
                    notificationSetSeen: false
                };

            case NotificationAction.REQUEST_SET_SEEN + '_FINISHED':
                return {
                    ...state,
                    notificationSetSeen: action.payload,
                }

            default:
                return state;
        }
    }

}
