import React from 'react'
import InlineSVG from 'svg-inline-react';

const InfoIconData = `
<svg width="50px" height="50px" viewBox="0 0 50 50" version="1.1" xmlns="http://www.w3.org/2000/svg">
    <g id="Icon-collection" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
        <g id="information-copy" fill="#51B0AA" fill-rule="nonzero">
            <path d="M25.0976562,0 C11.3662109,0 0,11.1708984 0,24.9023438 C0,38.6337891 11.3662109,50 25.0976562,50 C38.8291016,50 50,38.6337891 50,24.9023438 C50,11.1708984 38.8291016,0 25.0976562,0 Z M28.0273438,38.28125 C28.0273438,39.8964844 26.7119141,41.2109375 25.0976562,41.2109375 C23.4824219,41.2109375 22.1679688,39.8964844 22.1679688,38.28125 L22.1679688,23.4375 C22.1679688,21.8222656 23.4824219,20.5078125 25.0976562,20.5078125 C26.7119141,20.5078125 28.0273438,21.8222656 28.0273438,23.4375 L28.0273438,38.28125 Z M25.0976562,14.6484375 C23.4824219,14.6484375 22.1679688,13.3339844 22.1679688,11.71875 C22.1679688,10.1035156 23.4824219,8.7890625 25.0976562,8.7890625 C26.7119141,8.7890625 28.0273438,10.1035156 28.0273438,11.71875 C28.0273438,13.3339844 26.7119141,14.6484375 25.0976562,14.6484375 Z" id="Shape"></path>
        </g>
    </g>
</svg>
`

const InfoIcon = (props) => {

    return (
        <InlineSVG src={InfoIconData}/>
    )

}

export default InfoIcon