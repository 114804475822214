import CalculateHourDifference from "./CalculateHourDifference";
import moment from "moment";

const TotalTimeTrackingData = (timeTrackings) => {
    let regularHours = 0
    let availabilityHours = 0
    let kms = 0

    if (timeTrackings) {
        Object.keys(timeTrackings).map((i) => {
            const timeTracking = timeTrackings[i]
            if (typeof timeTracking === 'object') {
                const time = CalculateHourDifference(timeTracking.start_time, timeTracking.end_time, timeTracking.pause_time);
                if (timeTracking.type === 'regular') {
                    regularHours = moment.duration(regularHours) + moment.duration(time)
                } else {
                    availabilityHours = moment.duration(availabilityHours) + moment.duration(time)
                }
                if (timeTracking.total_kilometers) kms += parseInt(timeTracking.total_kilometers)
            }

            return null
        })
    }

    const totalHours = moment.duration(availabilityHours) + moment.duration(regularHours)

    return {
        regularHours: moment.duration(regularHours).format('HH:mm'),
        availabilityHours: moment.duration(availabilityHours).format('HH:mm'),
        totalHours: moment.duration(totalHours).format('HH:mm'),
        kms: kms
    }

}

export default TotalTimeTrackingData