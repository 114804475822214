import ActionUtility from '../../utilities/ActionUtility'
import ClientRulesEffect from './ClientRulesEffect'

export default class ClientRulesAction {
    static REQUEST_LIST = 'ClientRulesAction.REQUEST_LIST'
    static REQUEST_DETAIL = 'ClientRulesAction.REQUEST_DETAIL'
    static REQUEST_SET_SEEN = 'ClientRulesAction.REQUEST_SET_SEEN'
    static REQUEST_INFO = 'ClientRulesAction.REQUEST_INFO'

    static requestClientRulesList() {

        return async (dispatch) => {
            await ActionUtility.createThunkEffect(dispatch, ClientRulesAction.REQUEST_LIST, ClientRulesEffect.requestClientRulesList);
        };

    }

    static requestClientRulesDetail(id) {

        return async (dispatch) => {
            await ActionUtility.createThunkEffect(dispatch, ClientRulesAction.REQUEST_DETAIL, ClientRulesEffect.requestClientRulesDetail, id);
        };

    }

    static requestClientRulesSetSeen(id) {

        return async (dispatch) => {
            await ActionUtility.createThunkEffect(dispatch, ClientRulesAction.REQUEST_SET_SEEN, ClientRulesEffect.requestClientRulesSetSeen, id);
        };

    }

    static requestClientRulesInfo() {

        return async (dispatch) => {
            await ActionUtility.createThunkEffect(dispatch, ClientRulesAction.REQUEST_INFO, ClientRulesEffect.requestClientRulesInfo);
        };

    }

}
