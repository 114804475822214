import React from 'react';

class WeekDates extends React.Component {

    constructor(props) {
        super(props)
    }

    static getWeekDays = (current) => {
        let week = [];

        // Starting Monday not Sunday
        current.setDate((current.getDate() - current.getDay() + 1));
        for (var i = 0; i < 7; i++) {
            week.push(
                new Date(current)
            );
            current.setDate(current.getDate() + 1);
        }
        return week;
    }

    static getWeekNumber(d) {
        // Copy date so don't modify original
        d = new Date(Date.UTC(d.getFullYear(), d.getMonth(), d.getDate()));
        // Set to nearest Thursday: current date + 4 - current day number
        // Make Sunday's day number 7
        d.setUTCDate(d.getUTCDate() + 4 - (d.getUTCDay() || 7));
        // Get first day of year
        const yearStart = new Date(Date.UTC(d.getUTCFullYear(), 0, 1));
        // Calculate full weeks to nearest Thursday
        const weekNo = Math.ceil((((d - yearStart) / 86400000) + 1) / 7);
        // Return array of year and week number
        return weekNo;
    }

    static getMonthDaysByWeek(month, year) {
        const now = new Date();
        const date = new Date(year, month, 1);
        let weekNumber = 0;

        const weeks = {};


        while (date.getMonth() === month) {


            if (date < now) {

                // Start a new week, set the weeknumber
                if (date.getDay() === 1 || date.getDate() === 1) {
                    weekNumber = WeekDates.getWeekNumber(date)
                    weeks[year + '-' + weekNumber] = []
                }

                weeks[year + '-' + weekNumber].push(new Date(date));
            }
            date.setDate(date.getDate() + 1);
        }

        return weeks;
    }
}

export default WeekDates