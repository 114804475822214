import React from 'react'
import InlineSVG from 'svg-inline-react';

const ApprovedData = `
    <svg width="102px" height="102px" viewBox="0 0 102 102" version="1.1">
        <g id="Page-1" stroke="none" stroke-width="1" fill="none"
           fill-rule="evenodd">
            <g id="Group-29">
                <circle id="Oval" fill="#51B0AA" cx="51" cy="51"
                        r="51"></circle>
                <path
                    d="M76.056999,29.3537638 L42.35273,62.8396428 L26.943001,47.5299316 C25.1257563,45.7248516 22.1798991,45.7248516 20.3626544,47.5299316 C18.5457819,49.3353814 18.5457819,52.2621183 20.3626544,54.0675681 L39.0625567,72.6460976 C39.9711791,73.5484526 41.1619546,74 42.35273,74 C43.5435055,74 44.734281,73.5484526 45.6429034,72.6460976 L82.6373456,35.8917701 C84.4542181,34.0863203 84.4542181,31.1595834 82.6373456,29.3541336 C80.8201009,27.5486838 77.8738715,27.5486838 76.056999,29.3537638 Z"
                    id="Path" fill="#FFFFFF"></path>
            </g>
        </g>
    </svg>
`

const Approved = (props) => {

    return (
        <InlineSVG src={ApprovedData}/>
    )

}

export default Approved