import React from 'react';

import InfoIcon from '../../static/svg/InfoIcon';
import DayOffDay from '../_components/DayOffDay/DayOffDay'
import Statistics from './_components/Statistics/Statistics'
import './Dashboard.scss';
import {Link} from "react-router-dom";
import RouteEnum from "../../constants/RouteEnum";
import TimeTrackingAction from "../../stores/timetracking/TimetrackingAction";
import {connect} from "react-redux";
import Day from "../_components/TimeTrackingDay/TimeTrackingDay";
import DayOffAction from "../../stores/dayOff/DayOffAction";
import moment from 'moment'
import Loader from 'react-loader-spinner'
import cookie from "react-cookies";
import AuthenticationAction from "../../stores/authentication/AuthenticationAction";
import ClientRulesAction from "../../stores/clientRules/ClientRulesAction";
import HappyCheckAction from "../../stores/happyCheck/HappyCheckAction";
import MonthNumberMapper from "../../utilities/MonthNumberMapper";
import NotificationAction from "../../stores/notification/NotificationAction";
import HappyCheckModal from "../HappyCheck/_components/Modal";
import Productivity from "./_components/Productivity/Productivity";
import ProductivityAction from "../../stores/productivity/ProductivityAction";

const mapStateToProps = (state, ownProps) => ({
    ...state.timeTracking,
    ...state.clientRules,
    ...state.client,
    ...state.happyCheck,
    ...state.dayOff,
    ...state.notification,
    ...state.productivity
});

class Dashboard extends React.Component {


    constructor(props) {
        super(props)
        this.toggleModal = this.toggleModal.bind(this)
    }

    toggleModal = (id) => {
        this.props.dispatch(NotificationAction.requestNotificationSetSeen(id)).then(() => {
            this.props.dispatch(NotificationAction.requestNotificationList());
        });
    }

    componentDidMount() {
        if (!cookie.load('token')) {
            this.props.dispatch(AuthenticationAction.logout());
        }

        this.props.dispatch(ClientRulesAction.requestClientRulesList());

        this.props.dispatch(TimeTrackingAction.unsetTimeTracking());
        //this.props.dispatch(TimeTrackingAction.resetMonth());

        this.props.dispatch(NotificationAction.requestNotificationList());
        this.props.dispatch(DayOffAction.requestDayOffOverview());
        this.props.dispatch(DayOffAction.requestTotals());

        this.props.dispatch(TimeTrackingAction.requestWeeklyTimeTrackingOverview());

        this.props.dispatch(ProductivityAction.requestProductivityInfo());
    }

    render() {

        const rules = this.props.clientRules.list
        const notifications = this.props.notifications
        const happyChecks = this.props.happyChecksOpen.happyChecks
        const weeklyTimeTracking = this.props.weeklyTimeTracking
        const currentWeek = Object.keys(weeklyTimeTracking).length - 1;
        const timeTrackingWeekKey = currentWeek
        const timeTrackingWeek = weeklyTimeTracking[timeTrackingWeekKey]
        const timeTrackLoading = this.props.fetchDayCount > 0

        let loading = false
        if (this.props.fetchDayCount > 0) loading = true

        return (
            <div className="c-time c-time--dark">
                <header className="c-time-header c-time-header--split">
                    <h1>Dashboard</h1>
                    <span>
                        <time>{new moment().format('dddd D MMMM YYYY')}</time>
                    </span>
                </header>
                <div className="u-float-padding c-dashboard">
                    <div className="o-container o-container--primary">

                        <div className={'load-timetracking-' + loading}>
                            {!!happyChecks && happyChecks.length > 0 &&
                            <div className="c-dashboard__single">
                                <div className="c-dashboard__header c-dashboard__header--dark">
                                    <h2>Notificaties</h2>
                                </div>
                                <div className="c-statistics">

                                    {Object.values(happyChecks).map((happyCheck) => {

                                        return (
                                            <Link to={RouteEnum.HappyCheck + '/' + happyCheck.id}
                                                  className="c-statistics__single">
                                                <small>Er staat een happy check voor u klaar</small>
                                                <h3>{MonthNumberMapper(happyCheck.month)} {happyCheck.year}</h3>
                                            </Link>
                                        )
                                    })}
                                </div>
                            </div>
                            }

                            {!!rules &&
                            <div className="c-message__wrap">
                                {Object.values(rules).filter((rule) => rule.is_new === true).map((rule) => {
                                    let clientRuleText = 'Er is een nieuwe klantafspraak toegevoegd: '
                                    if(rule.sheets && rule.sheets.length > 1){
                                        clientRuleText = 'Er is een klantafspraak geupdate: '
                                    }
                                    return (
                                        <div className="c-message c-message--green">
                                            <header>
                                                <InfoIcon/>
                                                <span>Nieuwe klantafspraak</span>
                                            </header>
                                            <p>
                                                {clientRuleText}<Link
                                                to={RouteEnum.ClientRulesDetail + '/' + rule.id}>{rule.title}</Link>.
                                            </p>
                                            <Link to={RouteEnum.ClientRulesDetail + '/' + rule.id}
                                                  className={'button'}>Bekijken</Link>
                                        </div>
                                    )
                                })}
                            </div>
                            }

                            {this.props.weeklyTimeTracking && timeTrackingWeek &&
                            <div className="c-dashboard__single c-timetracking-week">


                                <div
                                    className="c-dashboard__header c-dashboard__header--dark c-dashboard__header--split">
                                    <h2>Tijdsregistraties deze week</h2>
                                    <Link to={RouteEnum.TimeTrackingOverview}>Bekijk tijdsregistraties</Link>
                                </div>

                                <div>
                                    {Object.keys(timeTrackingWeek.weekData).reverse().map((dayKey) => {
                                        const timeTrackingDay = timeTrackingWeek.weekData[dayKey]

                                        return (
                                            <Day data={timeTrackingDay} weekKey={timeTrackingWeekKey}
                                                 key={999 + dayKey} dayKey={parseInt(dayKey)}/>
                                        )
                                    })}
                                </div>


                            </div>
                            }
                            <div className="c-dashboard__single">
                                <div
                                    className="c-dashboard__header c-dashboard__header--dark c-dashboard__header--split">
                                    <h2>Aankomend verlof</h2>
                                    <Link to={RouteEnum.DaysOffOverview}>Verlofdagen overzicht</Link>
                                </div>

                                {!this.props.dayOffsLoading &&
                                <div className="c-days">
                                    {this.props.daysOff && Object.keys(this.props.daysOff).map((year) => {
                                            let items = this.props.daysOff[year]
                                            items = items.filter(item => item.isFuture)

                                            if (items.length) {
                                                return (
                                                    items && items.map((item) => {
                                                        return (
                                                            <DayOffDay data={item}/>
                                                        )
                                                    })
                                                )
                                            }

                                        }
                                    )}

                                    {!this.props.daysOff &&
                                    <p className={'c-dayOff-notice'}>Er zijn geen verlofdagen aangevraagd</p>
                                    }

                                </div>
                                }


                            </div>
                        </div>

                        {!loading &&
                            <>
                                <Statistics/>
                                {/*<Productivity/>*/}
                            </>
                        }

                        {loading &&
                        <div className={'c-timetracking-loader'}>
                            <Loader
                                type="Watch"
                                color="#51B0AA"
                                height={100}
                                width={100}
                                timeout={10000} //3 secs
                            />
                        </div>
                        }

                    </div>
                </div>

                {!!notifications &&
                <>
                    {Object.values(notifications.list).map((notification) => {
                        return (
                            <HappyCheckModal modalHandler={this.toggleModal} {...notification}/>
                        )
                    })}
                </>
                }

            </div>
        )
    }
}

export default connect(mapStateToProps)(Dashboard);
