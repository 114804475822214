import React from 'react'
import LogoTagline from "../../static/svg/LogoTagline";
import {
    Link
} from "react-router-dom";
import {Formik, Field} from 'formik'
import {connect} from "react-redux";
import AuthenticationAction from "../../stores/authentication/AuthenticationAction";
import Loader from 'react-loader-spinner'

const mapStateToProps = (state, ownProps) => ({
    ...state.authentication
});

class Login extends React.Component {

    render() {

        return (
            <div className={'c-auth'}>
                <LogoTagline/>

                <Formik
                    initialValues={
                        {
                            username: '',
                            password: '',
                            otp: ''
                        }
                    }
                    onSubmit={(values, actions) => {
                        if (!this.props.g_auth) {
                            this.props.dispatch(AuthenticationAction.requestLogin(values));
                        } else {
                            this.props.dispatch(AuthenticationAction.requestOTP(values));
                        }
                    }}
                >
                    {({values, setFieldValue, handleSubmit, touched}) => {

                        const usernameFilled = (values.username !== '');
                        const passwordFilled = values.password !== '';
                        const otpFilled = values.otp !== '';

                        return (

                            <form className={'c-auth-form'} onSubmit={handleSubmit}>

                                {!this.props.g_auth &&
                                <>
                                    <div className={'c-auth-form-row'}>
                                        <div className={'c-auth-form-group filled-' + usernameFilled}>
                                            <Field name={'username'} required={true}
                                                   type="text"
                                                   id={'authEmail'}/>
                                            <label htmlFor={'authEmail'}>Gebruikersnaam</label>
                                        </div>
                                    </div>


                                    <div className={'c-auth-form-row'}>
                                        <div className={'c-auth-form-group filled-' + passwordFilled}>
                                            <Field name={'password'} required={true}
                                                   type="password" id={'authPassword'}/>
                                            <label htmlFor={'authPassword'}>Uw wachtwoord</label>
                                        </div>
                                    </div>
                                </>
                                }
                                {this.props.g_auth &&
                                <div className={'c-auth-form-row'}>
                                    <div className={'c-auth-form-group filled-' + otpFilled}>
                                        <Field name={'otp'} required={true}
                                               type="password" id={'authPassword'}/>
                                        <label htmlFor={'authPassword'}>Eenmalig wachtwoord (2-staps
                                            verificatie)</label>
                                    </div>
                                </div>
                                }

                                {this.props.error &&
                                <small className={'error'}>
                                    {this.props.error}
                                </small>
                                }

                                {this.props.authApiPending &&
                                <div className={'load-spinner'}>
                                    <Loader
                                        type="Bars"
                                        color="#50b0aa"
                                        height={30}
                                        width={30}
                                        timeout={10000}

                                    />
                                </div>
                                }
                                {!this.props.authApiPending &&
                                <div className={'c-auth-form-row c-auth-form-row--btn'}>
                                    <div className={'c-auth-form-group'}>
                                        <button type={'submit'} className={'c-auth-btn'}>
                                            <span>Inloggen</span>
                                        </button>
                                    </div>
                                </div>
                                }
                            </form>

                        )
                    }}
                </Formik>

                <Link className={'c-auth-text-link'} to="/forgot-password">
                    Ik ben mijn wachtwoord vergeten
                </Link>

            </div>
        )
    }
}

export default connect(mapStateToProps)(Login);