import moment from 'moment';

const CalculateMinuteDifference = (startTime, endTime, difference = 0, workTypeValue = 'regular') => {

    if(workTypeValue !== 'regular'){
        difference = 0
    }

    return moment("04/09/2013 " + endTime, "DD/MM/YYYY HH:mm").subtract(moment.duration(difference).asMinutes(), "minutes").diff(moment("04/09/2013 " + startTime, "DD/MM/YYYY HH:mm")) / 60000
}

export default CalculateMinuteDifference