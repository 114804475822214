import React from 'react'
import {connect} from "react-redux";
import './Productivity.scss'

const mapStateToProps = (state, ownProps) => ({
    ...state.productivity
});

class Productivity extends React.Component {

    constructor(props) {
        super(props);
    }

    render() {

        const productivityKeys = Object.keys(this.props.Productivity)
        var totalNorm = 0
        var totalWorked = 0
        var total = 0
        var totalColor = 'white'

        if (productivityKeys) {
            return (
                <div className="c-time c-timetracking-overview">
                    <header className="c-time-header">
                        <h1>
                            <time>Declarabiliteit</time>
                        </h1>
                    </header>

                    <>

                        <form>

                            <div className="o-container o-container--primary">
                                <div className="c-time-form">

                                        <div className="c-credits">
                                            <div className="panel-body">
                                                <table className="table table-striped productivity-table">
                                                    <thead>
                                                    <tr>
                                                        <th>Maand</th>
                                                        <th className={'text-center'}>Te werken</th>
                                                        <th className={'text-center'}>Gewerkt</th>
                                                        <th className={'text-center'}>Saldo</th>
                                                    </tr>
                                                    </thead>
                                                    <tbody>
                                                    {productivityKeys.map((key) => {

                                                        const item = this.props.Productivity[key]
                                                        totalNorm += item.norm
                                                        totalWorked += item.worked
                                                        total += item.total

                                                        if (total !== 0) {
                                                            totalColor = total < 0 ? 'red' : 'green'
                                                        }

                                                        var color = 'white'
                                                        if (item.total !== 0) {
                                                            color = item.total < 0 ? 'red' : 'green'
                                                        }

                                                        return (
                                                            <tr key={key}>
                                                                <td>{key}</td>
                                                                <td className={'text-center'}>{item.norm}</td>
                                                                <td className={'text-center'}>{item.worked}</td>
                                                                <td className={'text-center ' + color}>{item.total}</td>
                                                            </tr>
                                                        )
                                                    })}
                                                    <tr>
                                                        <td></td>
                                                        <td className={'text-center border-top'}>{totalNorm}</td>
                                                        <td className={'text-center border-top'}>{totalWorked}</td>
                                                        <td className={'text-center border-top ' + totalColor}>{total}</td>
                                                    </tr>
                                                    </tbody>

                                                </table>

                                            </div>

                                        </div>

                                </div>
                            </div>
                        </form>
                    </>
                </div>
            )
        } else {
            return null;
        }
    }

}

export default connect(mapStateToProps)(Productivity);