import DayModel from './models/DayModel'
import TimeTrackingModel from './models/TimeTrackingModel'
import moment from 'moment';
import RouteEnum from '../../constants/RouteEnum'
import cookie from 'react-cookies'

export default class TimeTrackingEffect {

    static async requestTimeTracking(timeTrackingId) {
            return await fetch(RouteEnum.API + '/api/time-track/show/?id=' + timeTrackingId, {
                method: 'GET',
                headers: {
                    'Authorization': 'Bearer ' + cookie.load('token'),
                    'Accept': 'application/json',
                    //'Content-Type': 'application/json',
                },
            }).then((response) => {
                    return response.json()
                }
            ).then((response) => {
                if (response.status === 401) {
                    cookie.remove('token', {path: '/'})
                }
                return {
                    currentTimeTracking: new TimeTrackingModel(response.parameters.timeTrack),
                    error: false,
                    errorMessage: false
                };
            }).catch(function (error) {
                return {
                    currentTimeTracking: false,
                    error: true,
                    errorMessage: 'Er is iets misgegaan, probeer het later opnieuw'
                }
            })
    }

    static async removeTimeTracking(timeTrackingId) {
            return await fetch(RouteEnum.API + '/api/time-track/' + timeTrackingId + '/delete', {
                method: 'GET',
                headers: {
                    'Authorization': 'Bearer ' + cookie.load('token'),
                    'Accept': 'application/json',
                },
            }).then((response) => {
                    return response.json()
                }
            ).then((response) => {
                if (response.status === 401) {
                    cookie.remove('token', {path: '/'})
                }
                return {
                    error: false,
                    errorMessage: false,
                }
            }).catch(function (error) {
                return {
                    error: true,
                    errorMessage: 'Er is iets misgegaan, probeer het later opnieuw'
                }
            })
    }


    static async registerTimeTracking(values, token) {
        if (cookie.load('token')) {
            return await fetch(RouteEnum.API + '/api/time-track/create', {
                method: 'POST',
                headers: {
                    'Authorization': 'Bearer ' + cookie.load('token'),
                    'Accept': 'application/json',
                },
                body: JSON.stringify(values)
            }).then((response) => {
                    return response.json()
                }
            ).then((response) => {
                if (response.status === 401) {
                    cookie.remove('token', {path: '/'})
                }
                return {
                    error: false,
                    errorMessage: false
                }
            }).catch(function (error) {
                return {
                    error: true,
                    errorMessage: 'Er is iets misgegaan, probeer het later opnieuw'
                }
            })
        }
    }

    static async editTimeTracking(values) {
        if (cookie.load('token')) {
            return await fetch(RouteEnum.API + '/api/time-track/' + values.id + '/edit', {
                method: 'POST',
                headers: {
                    'Authorization': 'Bearer ' + cookie.load('token'),
                    'Accept': 'application/json',
                },
                body: JSON.stringify(values)
            }).then((response) => {
                if (response.status === 401) {
                    cookie.remove('token', {path: '/'})
                }
                return {
                    error: false,
                    errorMessage: false
                }
            }).catch((error) => {
                return {
                    error: true,
                    errorMessage: 'Er is iets misgegaan, probeer het later opnieuw'
                }
            })
        }
    }

    static async requestWeeklyTimeTrackingOverview(weeks) {

        return Object.keys(weeks).map((weekNumber) => {
            const weekData = weeks[weekNumber];

            return {
                weekNumber: weekNumber,
                firstDate: weekData[0].getDate(),
                lastDate: weekData.slice(-1)[0].getDate(),
                weekData: weekData.map((day) => {
                    const data = {
                        day: day
                    }
                    return new DayModel(data)
                })
            }
        });
    }

    static async requestTimeTrackingDay(data) {
            return await fetch(RouteEnum.API + '/api/time-track/overview/?startDate=' + moment(data.day.day).format('YYYY-MM-DD'), {
                method: 'GET',
                headers: {
                    'Authorization': 'Bearer ' + cookie.load('token'),
                    'Accept': 'application/json',
                },
            }).then((response) => {
                    return response.json()
                }
            ).then((response) => {
                let timeTrackingArray = []
                if (response.status === 401) {
                    cookie.remove('token', {path: '/'})
                }

                if (response.status === 200){
                    response.parameters.timeTracks.map((timeTracking) => {
                        const timeTrackingModelled = new TimeTrackingModel(timeTracking)
                        timeTrackingArray.push(timeTrackingModelled)
                    })

                    return {
                        day: new DayModel(data, timeTrackingArray),
                        timeTrackings: timeTrackingArray,
                        weekKey: data.weekKey,
                        dayKey: data.dayKey,
                        errorDay: false,
                        errorDayMessage: false
                    }
                }else {
                    return {
                        day: false,
                        timeTrackings: false,
                        weekKey: false,
                        dayKey: false,
                        errorDay: false,
                        errorDayMessage: false
                    }
                }

            }).catch((error) => {
                return {
                    errorDay: true,
                    errorDayMessage: 'Er is iets misgegaan, probeer het later opnieuw'
                }
            })
        }

}
