const MapCallTimes = (calls) => {

    const returnObject = {
        calls_number_1: 0,
        calls_number_2: 0,
        calls_number_3: 0,
        calls_number_4: 0
    }

    calls.map((call) => {

        switch (call.start_time) {
            case('00:00:00'):
                returnObject['calls_number_1'] = returnObject['calls_number_1'] + 1
                break;

            case('18:00:00'):
                returnObject['calls_number_3'] = returnObject['calls_number_3'] + 1
                break;

            case('22:00:00'):
                returnObject['calls_number_4'] = returnObject['calls_number_4'] + 1
                break;

            default:
                returnObject['calls_number_2'] = returnObject['calls_number_2'] + 1
                break;
        }

    })

    return returnObject
}

export default MapCallTimes