import DayOffAction from './DayOffAction'

export default class DayOffReducer {

    static initialState = {
        dayOffFetching: false,
        redirectDayOff: false,
        currentTimeTracking: false,
        formSubmitting: false,
        totals: false,
        creditData: false,
        creditDataCurrentYear: false,
        totalsLoading: true,
        daysOff: false,
        dayOffsLoading: true,
    };

    static reducer(state = DayOffReducer.initialState, action) {

        switch (action.type) {

            case DayOffAction.REQUEST_TOTALS:

                return {
                    ...state,
                    date: new Date(),
                    totalsLoading: true
                };

            case DayOffAction.REQUEST_TOTALS + '_FINISHED':
                return {
                    ...state,
                    totalsLoading: false,
                    ...action.payload
                }

            case DayOffAction.REQUEST_CREDIT_DATA:

                return {
                    ...state,
                };

            case DayOffAction.REQUEST_CREDIT_DATA + '_FINISHED':
                return {
                    ...state,
                    ...action.payload
                }

            case DayOffAction.REQUEST_DAY_OFF_OVERVIEW:
                return {
                    ...state,
                    daysOff: false,
                    redirectDayOff: false,
                    dayOffsLoading: true
                };

            case DayOffAction.REQUEST_DAY_OFF_OVERVIEW + '_FINISHED':
                return {
                    ...state,
                    dayOffsLoading: false,
                    ...action.payload
                }


            case DayOffAction.REGISTER_DAY_OFF:

                return {
                    ...state,
                    dayOffsLoading: false,
                    formSubmitting: true,
                    ...action.payload
                }

            case DayOffAction.REGISTER_DAY_OFF + '_FINISHED':

                return {
                    ...state,
                    dayOffsLoading: false,
                    formSubmitting: false,
                    redirectDayOff: true,
                    ...action.payload
                }

            case DayOffAction.REMOVE_DAY_OFF:

                return {
                    ...state,
                    formSubmitting: true,
                    ...action.payload
                }

            case DayOffAction.REMOVE_DAY_OFF + '_FINISHED':

                return {
                    ...state,
                    formSubmitting: false,
                    redirectDayOff: true,
                    ...action.payload
                }


            default:
                return state;
        }
    }

}
