import React from 'react'
import '../HappyCheck.scss'
import {connect} from "react-redux";

const mapStateToProps = (state, ownProps) => ({
    ...state.notification
});


class HappyCheckModal extends React.Component {

    constructor(props) {
        super(props)
    }

    render() {
        return (
            <div className="c-modal c-modal--happycheck">
                <button className="btn btn--pink c-modal__close" onClick={() => this.props.modalHandler(this.props.id)}>Sluiten</button>
                <div className="c-modal__bg"/>
                <div className="c-modal__body">
                    {!!this.props.title &&
                    <h4>{this.props.title}</h4>
                    }
                    {!!this.props.description &&
                    <p>{this.props.description}</p>
                    }
                </div>
            </div>
        );
    }
}

export default connect(mapStateToProps)(HappyCheckModal);
